import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import MainTabs from "./mycom/MainTabs";

function MainLayout({ pMaind }) {
  return (
    <>
    <Container>
      <MainTabs pMaind={pMaind}></MainTabs>
      
      <div className="mx-auto" style={{ maxWidth: "40rem" }}>
      <Outlet></Outlet>
      </div>
    </Container>
    </>
  );
}

export default MainLayout;