import { useRef } from "react";
import { Button, Form, Modal } from "react-bootstrap";

function ComWriteUserReportModal({
  bShow,
  OnHide,
  OnSend,
}) {

  let pRefReportTitle = useRef();
  let pRefReportContent = useRef();

  function OnClickSend() {
    let szTitle = pRefReportTitle.current.value;
    let szContent = pRefReportContent.current.value;

    OnSend(szTitle, szContent);
  }

  return (
    <Modal
      show={bShow}
      animation={false}
      onHide={OnHide}
    >
      <Modal.Header closeButton className="px-2 py-2">
        <Modal.Title>문의사항 작성</Modal.Title>
      </Modal.Header>

      <Modal.Body className="px-2 py-2">
        <Form>
          <Form.Group className="mb-3">
            <Form.Label style={{ fontSize: "0.9rem" }}>제목</Form.Label>
            <Form.Control className="mb-1" type="text" rows={3} ref={pRefReportTitle} />
            <Form.Label style={{ fontSize: "0.9rem" }}>내용</Form.Label>
            <Form.Control as="textarea" rows={3} ref={pRefReportContent} />
          </Form.Group>
        </Form>

        <Button variant="primary" style={{ width: "100%", fontSize: "0.9rem" }} onClick={OnClickSend}>전송</Button>

      </Modal.Body>
    </Modal>
  );
}

export { ComWriteUserReportModal }