import { Children, useEffect, useRef, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { CONST } from "../mod/CardClientSheetData";
import GameUtil from "../mod/GameUtil";
import Util from "../mod/Util";
import { CCardDetailModalButtonParam, ComCardDetailModal } from "../mycom/ComCardDetailModal";
import ComGMNoticeView from "../mycom/ComGMNoticeView";

function PageHome({ pMaind }) {

  return (
    <>
      <div className="mb-3"></div>

      <UIFMTitle>

      </UIFMTitle>
      <div className="mb-2"></div>

      <UIRandomCardList
        pMaind={pMaind}
      >
      </UIRandomCardList>
      <div className="mb-2"></div>

      <UIFMLink>

      </UIFMLink>
      <div className="mb-2"></div>

      <ComGMNoticeView
        pMaind={pMaind}
        bIsLimitView={true}
      >
      </ComGMNoticeView>
      <div className="mb-2"></div>

    </>
  );
}

function UIFMTitle({

}) {
  return (
    <Card>
      <Card.Body className="px-1 py-1">
        <div className="mx-auto" style={{ maxWidth: "349px" }}>
          <Card.Img
            loading="lazy"    //이미지가 뷰포트의 일정 거리 안으로 들어와야 불러옵니다.
            decoding="async"  //다른 콘텐츠의 표시 지연을 피하기 위해 이미지를 비동기적으로 디코딩 합니다.
            src={GameUtil.GetPublicPath("img/fm_logo.webp")} variant="top" style={{ maxWidth: "349px", maxHeight: "100px" }} />
          <Card.Text style={{ textAlign: "center", fontWeight: "bold" }}>게임 "판타지 마스터즈"<br />아카이브 사이트</Card.Text>

        </div>

      </Card.Body>
    </Card>
  );
}

function UIFMLink({

}) {

  let [pDicData, funcSetData] = useState([
    { Title : "FM", Link : "https://fanma.kr/", Desc : "판마 룰 기반 웹게임 (FanMade Masters)" },
    { Title : "데스티니 메이든", Link : "https://play.google.com/store/apps/details?id=com.junglemong.destinymaiden&hl=ko", Desc : "판마 룰 기반 모바일 게임 (안드로이드)" },
    { Title : "판타지 마스터즈 임시대피소", Link : "https://cafe.naver.com/fmbield", Desc : "네이버 카페" },
    { Title : "판타지 마스터즈 마이너 갤러리", Link : "https://gall.dcinside.com/mgallery/board/lists?id=fanma", Desc : "디씨인사이드 마이너 갤러리" },
  ]);

  function RefreshUI_Link() {
    let arr = [];

    pDicData.forEach((pData) => {
      arr.push(
      <a href={pData.Link} target="_blank" rel="noopener noreferrer"  style={{ textDecoration:"none", color:"inherit"}}>
        <Card className="mb-1">
          <Card.Body className="px-2 py-2">
            <Card.Text className="mb-0" style={{ fontSize: "1.2rem", fontWeight:"bold" }}>{`${pData.Title}`}</Card.Text>
            <Card.Text style={{ fontSize: "0.9rem" }}>{`${pData.Desc}`}</Card.Text>
          </Card.Body>
        </Card>
      </a>
      );
    });

    return (Children.toArray(arr));
  }

  // 전체를 감싸는 div 정의.
  let pDivSetting = {};
  pDivSetting.className = "";
  pDivSetting.style = {};
  // 제한된 뷰 + 스크롤 표시
  pDivSetting.className = "overflow-scroll";
  pDivSetting.style = { maxHeight: "20rem" };

  return (
    <Card>
      <Card.Header>{"관련 사이트 (클릭 시 이동)"}</Card.Header>
      <Card.Body>
        <div className={pDivSetting.className} style={pDivSetting.style}>
          {RefreshUI_Link()}
        </div>
      </Card.Body>
    </Card>
  );
}

/**
  * Card class, style
  * 
  * small
  * card width : 100px
  * card height : 142px
  */
let pCardUISetting = {
  // 
  cImg: "",
  stImg: { width: "100px", height: "142px", background: `url("${GameUtil.GetImageDefaultPathSmall()}")` },
};

function UIRandomCardList({
  pMaind
}) {

  let ONEPAGE_DATA_LIMIT = 10;

  // 
  const pNavigate = useNavigate();

  let [pDBResult, funcDBResult] = useState({
    TotalCount: 0,
    Datas: [],

    Clear: function () {
      this.TotalCount = 0;
      this.Datas = [];
    },
  });

  // 카드 상세정보 모달창 용 
  let [pDetailData, funcSetDetailData] = useState({
    show: false,
    pData: null,
    arrButtonParams: [
      new CCardDetailModalButtonParam("관련 유저덱 검색", "outline-primary", OnClickSearchUserDeck),
      new CCardDetailModalButtonParam("닫기", "danger", OnClickModalClose),
    ]
  });

  let [bIsRerollActive, funcSetRerollActive] = useState(true);

  function OnClickSearchUserDeck(pCardData) {
    let pSearchParams = new URLSearchParams();
    pSearchParams.append("card", pCardData.NumID);
    pNavigate(`/userdeck/board?${pSearchParams.toString()}`);
  }

  function OnClickModalClose(pCardData) {
    // pCardData 실제로 쓰진 않는다.

    // 닫기
    pDetailData.show = false;
    funcSetDetailData({ ...pDetailData });
  }

  // DB 요청 처리 번호표
  let rDBRequestControl = useRef({
    pMap: new Map(),
    IDCount: 1,

    Register: function () {
      let nID = this.IDCount;
      this.IDCount++;

      if (!this.pMap.has(nID)) {
        this.pMap.set(nID, nID);
      }
      return nID;
    },
    UnRegister: function (nID) {
      if (this.pMap.has(nID)) {
        this.pMap.delete(nID);
      }
    },
    Clear: function () {
      this.pMap.clear();
    }
  });

  useEffect(() => {
    (async function () {
      // 기존 DBResult 초기화
      pDBResult.Clear();

      // 검색 다시 실행
      await ProcDBSearch();
    })();

    return () => {
      // Unmount 시 처리
      // 처리 번호표 전부 삭제
      rDBRequestControl.current.Clear();
    };
  }, []);


  useEffect(() => {
    let nTimerID = -1;
    if (!bIsRerollActive) {
      nTimerID = setTimeout(()=> funcSetRerollActive(true), 2000);
    }

    return () => {
      if (nTimerID >= 0) {
        clearTimeout(nTimerID);
      }
    };
  }, [bIsRerollActive]);

  async function ProcDBSearch() {
    // arrSearchCondition 을 기반으로 검색을 진행하고, 결과를 "추가"한다
    // 이 전단계에서 arrSearchCondition 의 세팅이 끝났어야 한다.

    let arrQueries = [];

    // 명분상 넣었다. 
    let szColumnNameOrder = "NumID";
    // arrQueries.push(pMaind.pFbCtrl.CreateSearchQuery(szColumnNameOrder, CONST._DB_PROCTYPE.ORDER_BY, "desc"));

    // 랜덤 
    let nTotalCount = pMaind.CardTotalCount;
    if (nTotalCount < ONEPAGE_DATA_LIMIT) {
      nTotalCount = ONEPAGE_DATA_LIMIT;
    }
    let arrRandom = Util.GetRandomIntInRange(1, nTotalCount, ONEPAGE_DATA_LIMIT);
    arrQueries.push(pMaind.pFbCtrl.CreateSearchQuery("SortedID", CONST._DB_PROCTYPE.IN, arrRandom));

    // DB 요청 전에 미리 결과 처리 번호표 등록
    let nReqID = rDBRequestControl.current.Register();

    // DB 요청 (비동기)
    let pSearchResult = await pMaind.pFbCtrl.GetSearchedDocs(GameUtil.GetDBCardListCollection(),
      arrQueries,
      ONEPAGE_DATA_LIMIT,
      pDBResult.TotalCount,
      szColumnNameOrder,
      null);

    // DB 요청 결과 이후에도 요청 관리에 ID가 있으면 실행
    if (!rDBRequestControl.current.pMap.has(nReqID)) {
      return;
    }

    // 처리 번호표 삭제
    rDBRequestControl.current.UnRegister(nReqID);

    // 결과 처리
    pDBResult.Datas.push(...pSearchResult.arrResult);
    pDBResult.TotalCount = pSearchResult.TotalCount;
    funcDBResult({ ...pDBResult });
  }

  function OnClickCard(pCardData) {
    if (!pCardData) {
      return;
    }

    pDetailData.show = true;
    pDetailData.pData = pCardData;
    funcSetDetailData({ ...pDetailData });
  }

  async function OnClickReroll() {
    if (!bIsRerollActive) {
      return;
    }

    funcSetRerollActive(false);

    // 기존 DBResult 초기화
    pDBResult.Clear();

    // 검색 다시 실행
    await ProcDBSearch();
  }

  function RefreshUI_Cards() {
    let arr = [];

    if (pDBResult.Datas.length > 0) {
      pDBResult.Datas.forEach((pData) => {
        let szImagePath = GameUtil.GetImagePathSmall(pData.Category, pData.KIND);
        if (szImagePath) {
          szImagePath = pMaind.pFbCtrl.GetStorageURL(szImagePath);
        }
        else {
          szImagePath = GameUtil.GetImageDefaultPathSmall();
        }
  
        arr.push(
          <Card.Img
            loading="lazy"    //이미지가 뷰포트의 일정 거리 안으로 들어와야 불러옵니다.
            decoding="async"  //다른 콘텐츠의 표시 지연을 피하기 위해 이미지를 비동기적으로 디코딩 합니다.
            src={szImagePath} variant="top" className={pCardUISetting.cImg} style={pCardUISetting.stImg} onClick={() => { OnClickCard(pData) }} />
        );
      });
    }
    else {
      for (let i = 0; i < ONEPAGE_DATA_LIMIT; ++i) {
        arr.push(
          <Card.Img
            src={GameUtil.GetImageDefaultPathSmall()}
            variant="top" className={pCardUISetting.cImg} style={pCardUISetting.stImg}/>
        );
      }
    }

    return (Children.toArray(arr));
  }

  function RefreshUI_FooterButtons() {
    let arr = [];

    let szVariant = "outline-primary";
    if (!bIsRerollActive) {
      szVariant = "secondary";
    }

    arr.push(
      <Button variant={szVariant} className="me-1 px-2 py-1" disabled={!bIsRerollActive} onClick={OnClickReroll}>🎲</Button>
    );


    return (Children.toArray(arr));
  }

  return (
    <>
      <Card>
        <Card.Header>랜덤 카드</Card.Header>
        <Card.Body className="px-1 py-1">
          {RefreshUI_Cards()}
        </Card.Body>
        <Card.Footer className="d-flex justify-content-end">
          {RefreshUI_FooterButtons()}
        </Card.Footer>
      </Card>

      {/* modal */}
      <ComCardDetailModal
        pMaind={pMaind}
        pDetailData={pDetailData}
        funcSetDetailData={funcSetDetailData}
        arrButtonParams={pDetailData.arrButtonParams}
        funcOnHide={OnClickModalClose}
      >
      </ComCardDetailModal>
    </>
  );
}

export default PageHome;