// 
import { useState, Children, useEffect, createRef, useRef } from "react";

// 
import { pSheetEnum, pSheetColumn, CONST } from '../mod/CardClientSheetData';
import GameUtil from '../mod/GameUtil';


function PageTestHome({ pMaind }) {

  let [nCategory, funcCategory] = useState(2);
  let [arrTargetNames, funcTargetNames] = useState([
    "헤레스",
    "블러드서커",
  ]);
  
  let [pDBResult, funcDBResult] = useState({
    TotalCount: 0,
    MapDatas: new Map(),

    Clear: function () {
      this.TotalCount = 0;
      this.MapDatas = new Map();
    },
  });

  useEffect(() => {

    // 검색 조건이 바뀌었다!
    (async function () {
      // 기존 DBResult 초기화
      pDBResult.Clear();

      // 검색 다시 실행
      await ProcDBSearch();
    })();

    return () => {
      // Unmount 시 처리
    };

  }, []);

  async function ProcDBSearch() {

    for (let i = 0; i < arrTargetNames.length; ++i){
      let szName = arrTargetNames[i];

      let arrQueries = [];

      // 반드시 NumID 정렬을 맨앞에 둘것. 순서 꼬이면 색인 다시해야 할수도 있다.
      let szColumnNameOrder = "NumID";
      arrQueries.push(pMaind.pFbCtrl.CreateSearchQuery(szColumnNameOrder, CONST._DB_PROCTYPE.ORDER_BY, "desc"));
      arrQueries.push(pMaind.pFbCtrl.CreateSearchQuery("Category", CONST._DB_PROCTYPE.EQUAL, nCategory));
      arrQueries.push(pMaind.pFbCtrl.CreateSearchQuery("Name", CONST._DB_PROCTYPE.EQUAL, szName));

      // DB 요청 (비동기)
      let pSearchResult = await pMaind.pFbCtrl.GetSearchedDocs(GameUtil.GetDBCardListCollection(),
        arrQueries,
        20, // ONEPAGE_DATA_LIMIT
        pDBResult.TotalCount,
        szColumnNameOrder,
        null);

      // 결과 처리
      pDBResult.TotalCount = pSearchResult.TotalCount;
      pSearchResult.arrResult.forEach((pDoc, idx) => {
        if (!pDBResult.MapDatas.has(pDoc.id)) {
          pDBResult.MapDatas.set(pDoc.id, pDoc);
        }
      });

      console.log(`processing : ${pDBResult.MapDatas.size}/${arrTargetNames.length}`);
    }

    //  갱신
    funcDBResult({ ...pDBResult });
  }

  function RefreshUI_Result() {
    let arr = [];

    pDBResult.MapDatas.forEach((pDoc, key)=> {
      arr.push(pDoc);
    });

    arr.sort((a, b)=> a.id - b.id);
    
    return (
      <>
        <p>{`타겟 총 갯수 : ${arrTargetNames.length}`}</p>
        <p>{`결과 총 갯수 : ${pDBResult.MapDatas.size}`}</p>
        <p>{JSON.stringify(arr)}</p>
      </>
    );
  }

  return (
    <>
      <p>PageTestHome</p> 
      {RefreshUI_Result()}
    </>
  );
}

export default PageTestHome;