// VERSION = 2023-04-01.17-09

let pSheetEnum = {};
pSheetEnum.NameCount = {};
class TempObj1 { constructor([KIND, TYPE, UIName, UIPriority, ]) { this.KIND = KIND;this.TYPE = TYPE;this.UIName = UIName;this.UIPriority = UIPriority; } }
pSheetEnum.NameCount.arr = [];
pSheetEnum.NameCount.mapKIND = new Map();
pSheetEnum.NameCount.mapTYPE = new Map();
pSheetEnum.NameCount.Add = function (arr) { let p = new TempObj1(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p); };
pSheetEnum.Episode = {};
class TempObj2 { constructor([KIND, TYPE, UIName, UIPriority, ]) { this.KIND = KIND;this.TYPE = TYPE;this.UIName = UIName;this.UIPriority = UIPriority; } }
pSheetEnum.Episode.arr = [];
pSheetEnum.Episode.mapKIND = new Map();
pSheetEnum.Episode.mapTYPE = new Map();
pSheetEnum.Episode.Add = function (arr) { let p = new TempObj2(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p); };
pSheetEnum.DeckLimit = {};
class TempObj3 { constructor([KIND, TYPE, UIName, UIPriority, ]) { this.KIND = KIND;this.TYPE = TYPE;this.UIName = UIName;this.UIPriority = UIPriority; } }
pSheetEnum.DeckLimit.arr = [];
pSheetEnum.DeckLimit.mapKIND = new Map();
pSheetEnum.DeckLimit.mapTYPE = new Map();
pSheetEnum.DeckLimit.Add = function (arr) { let p = new TempObj3(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p); };
pSheetEnum.Category = {};
class TempObj4 { constructor([KIND, TYPE, UIName, UIPriority, ImageSmall, ImageLarge, ImagePrefix, ]) { this.KIND = KIND;this.TYPE = TYPE;this.UIName = UIName;this.UIPriority = UIPriority;this.ImageSmall = ImageSmall;this.ImageLarge = ImageLarge;this.ImagePrefix = ImagePrefix; } }
pSheetEnum.Category.arr = [];
pSheetEnum.Category.mapKIND = new Map();
pSheetEnum.Category.mapTYPE = new Map();
pSheetEnum.Category.Add = function (arr) { let p = new TempObj4(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p); };
pSheetEnum.RareType = {};
class TempObj5 { constructor([KIND, TYPE, UIName, UIPriority, ]) { this.KIND = KIND;this.TYPE = TYPE;this.UIName = UIName;this.UIPriority = UIPriority; } }
pSheetEnum.RareType.arr = [];
pSheetEnum.RareType.mapKIND = new Map();
pSheetEnum.RareType.mapTYPE = new Map();
pSheetEnum.RareType.Add = function (arr) { let p = new TempObj5(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p); };
pSheetEnum.RareUI = {};
class TempObj6 { constructor([KIND, TYPE, UISpriteName, ]) { this.KIND = KIND;this.TYPE = TYPE;this.UISpriteName = UISpriteName; } }
pSheetEnum.RareUI.arr = [];
pSheetEnum.RareUI.mapKIND = new Map();
pSheetEnum.RareUI.mapTYPE = new Map();
pSheetEnum.RareUI.Add = function (arr) { let p = new TempObj6(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p); };
pSheetEnum.RaceType = {};
class TempObj7 { constructor([KIND, TYPE, UIName, UIPriority, ]) { this.KIND = KIND;this.TYPE = TYPE;this.UIName = UIName;this.UIPriority = UIPriority; } }
pSheetEnum.RaceType.arr = [];
pSheetEnum.RaceType.mapKIND = new Map();
pSheetEnum.RaceType.mapTYPE = new Map();
pSheetEnum.RaceType.Add = function (arr) { let p = new TempObj7(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p); };
pSheetEnum.CompanyType = {};
class TempObj8 { constructor([KIND, TYPE, UIName, UIPriority, ]) { this.KIND = KIND;this.TYPE = TYPE;this.UIName = UIName;this.UIPriority = UIPriority; } }
pSheetEnum.CompanyType.arr = [];
pSheetEnum.CompanyType.mapKIND = new Map();
pSheetEnum.CompanyType.mapTYPE = new Map();
pSheetEnum.CompanyType.Add = function (arr) { let p = new TempObj8(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p); };
pSheetEnum.EtcType = {};
class TempObj9 { constructor([KIND, TYPE, UIName, UIPriority, ]) { this.KIND = KIND;this.TYPE = TYPE;this.UIName = UIName;this.UIPriority = UIPriority; } }
pSheetEnum.EtcType.arr = [];
pSheetEnum.EtcType.mapKIND = new Map();
pSheetEnum.EtcType.mapTYPE = new Map();
pSheetEnum.EtcType.Add = function (arr) { let p = new TempObj9(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p); };
pSheetEnum.BuyGrade = {};
class TempObj10 { constructor([KIND, TYPE, UIName, ]) { this.KIND = KIND;this.TYPE = TYPE;this.UIName = UIName; } }
pSheetEnum.BuyGrade.arr = [];
pSheetEnum.BuyGrade.mapKIND = new Map();
pSheetEnum.BuyGrade.mapTYPE = new Map();
pSheetEnum.BuyGrade.Add = function (arr) { let p = new TempObj10(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p); };
pSheetEnum.SoulType = {};
class TempObj11 { constructor([KIND, TYPE, UIName, ]) { this.KIND = KIND;this.TYPE = TYPE;this.UIName = UIName; } }
pSheetEnum.SoulType.arr = [];
pSheetEnum.SoulType.mapKIND = new Map();
pSheetEnum.SoulType.mapTYPE = new Map();
pSheetEnum.SoulType.Add = function (arr) { let p = new TempObj11(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p); };
pSheetEnum.CardUISoul = {};
class TempObj12 { constructor([KIND, TYPE, ]) { this.KIND = KIND;this.TYPE = TYPE; } }
pSheetEnum.CardUISoul.arr = [];
pSheetEnum.CardUISoul.mapKIND = new Map();
pSheetEnum.CardUISoul.mapTYPE = new Map();
pSheetEnum.CardUISoul.Add = function (arr) { let p = new TempObj12(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p); };
pSheetEnum.RequireSoul = {};
class TempObj13 { constructor([KIND, TYPE, UISpriteName, UIShow, ]) { this.KIND = KIND;this.TYPE = TYPE;this.UISpriteName = UISpriteName;this.UIShow = UIShow; } }
pSheetEnum.RequireSoul.arr = [];
pSheetEnum.RequireSoul.mapKIND = new Map();
pSheetEnum.RequireSoul.mapTYPE = new Map();
pSheetEnum.RequireSoul.Add = function (arr) { let p = new TempObj13(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p); };
pSheetEnum.EarnSoul = {};
class TempObj14 { constructor([KIND, TYPE, UISpriteName, UIShow, ]) { this.KIND = KIND;this.TYPE = TYPE;this.UISpriteName = UISpriteName;this.UIShow = UIShow; } }
pSheetEnum.EarnSoul.arr = [];
pSheetEnum.EarnSoul.mapKIND = new Map();
pSheetEnum.EarnSoul.mapTYPE = new Map();
pSheetEnum.EarnSoul.Add = function (arr) { let p = new TempObj14(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p); };
pSheetEnum.LV = {};
class TempObj15 { constructor([KIND, TYPE, UIName, UIPriority, ]) { this.KIND = KIND;this.TYPE = TYPE;this.UIName = UIName;this.UIPriority = UIPriority; } }
pSheetEnum.LV.arr = [];
pSheetEnum.LV.mapKIND = new Map();
pSheetEnum.LV.mapTYPE = new Map();
pSheetEnum.LV.Add = function (arr) { let p = new TempObj15(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p); };
pSheetEnum.StatValue = {};
class TempObj16 { constructor([KIND, TYPE, UIName, UIPriority, ]) { this.KIND = KIND;this.TYPE = TYPE;this.UIName = UIName;this.UIPriority = UIPriority; } }
pSheetEnum.StatValue.arr = [];
pSheetEnum.StatValue.mapKIND = new Map();
pSheetEnum.StatValue.mapTYPE = new Map();
pSheetEnum.StatValue.Add = function (arr) { let p = new TempObj16(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p); };
pSheetEnum.TurnType = {};
class TempObj17 { constructor([KIND, TYPE, UIName, UIPriority, ]) { this.KIND = KIND;this.TYPE = TYPE;this.UIName = UIName;this.UIPriority = UIPriority; } }
pSheetEnum.TurnType.arr = [];
pSheetEnum.TurnType.mapKIND = new Map();
pSheetEnum.TurnType.mapTYPE = new Map();
pSheetEnum.TurnType.Add = function (arr) { let p = new TempObj17(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p); };
pSheetEnum.DurationType = {};
class TempObj18 { constructor([KIND, TYPE, ]) { this.KIND = KIND;this.TYPE = TYPE; } }
pSheetEnum.DurationType.arr = [];
pSheetEnum.DurationType.mapKIND = new Map();
pSheetEnum.DurationType.mapTYPE = new Map();
pSheetEnum.DurationType.Add = function (arr) { let p = new TempObj18(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p); };
pSheetEnum.EnchantSlot = {};
class TempObj19 { constructor([KIND, TYPE, UIName, ]) { this.KIND = KIND;this.TYPE = TYPE;this.UIName = UIName; } }
pSheetEnum.EnchantSlot.arr = [];
pSheetEnum.EnchantSlot.mapKIND = new Map();
pSheetEnum.EnchantSlot.mapTYPE = new Map();
pSheetEnum.EnchantSlot.Add = function (arr) { let p = new TempObj19(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p); };
pSheetEnum.UnitType = {};
class TempObj20 { constructor([KIND, TYPE, UIName, UIPriority, ]) { this.KIND = KIND;this.TYPE = TYPE;this.UIName = UIName;this.UIPriority = UIPriority; } }
pSheetEnum.UnitType.arr = [];
pSheetEnum.UnitType.mapKIND = new Map();
pSheetEnum.UnitType.mapTYPE = new Map();
pSheetEnum.UnitType.Add = function (arr) { let p = new TempObj20(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p); };
pSheetEnum.StatType = {};
class TempObj21 { constructor([KIND, TYPE, UIName, UIPriority, ]) { this.KIND = KIND;this.TYPE = TYPE;this.UIName = UIName;this.UIPriority = UIPriority; } }
pSheetEnum.StatType.arr = [];
pSheetEnum.StatType.mapKIND = new Map();
pSheetEnum.StatType.mapTYPE = new Map();
pSheetEnum.StatType.Add = function (arr) { let p = new TempObj21(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p); };
pSheetEnum.Skill1 = {};
class TempObj22 { constructor([KIND, TYPE, UIName, UIPriority, ]) { this.KIND = KIND;this.TYPE = TYPE;this.UIName = UIName;this.UIPriority = UIPriority; } }
pSheetEnum.Skill1.arr = [];
pSheetEnum.Skill1.mapKIND = new Map();
pSheetEnum.Skill1.mapTYPE = new Map();
pSheetEnum.Skill1.Add = function (arr) { let p = new TempObj22(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p); };
pSheetEnum.Skill2 = {};
class TempObj23 { constructor([KIND, TYPE, UIName, UIPriority, ]) { this.KIND = KIND;this.TYPE = TYPE;this.UIName = UIName;this.UIPriority = UIPriority; } }
pSheetEnum.Skill2.arr = [];
pSheetEnum.Skill2.mapKIND = new Map();
pSheetEnum.Skill2.mapTYPE = new Map();
pSheetEnum.Skill2.Add = function (arr) { let p = new TempObj23(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p); };
pSheetEnum.CoinType = {};
class TempObj24 { constructor([KIND, TYPE, UIName, UIPriority, ]) { this.KIND = KIND;this.TYPE = TYPE;this.UIName = UIName;this.UIPriority = UIPriority; } }
pSheetEnum.CoinType.arr = [];
pSheetEnum.CoinType.mapKIND = new Map();
pSheetEnum.CoinType.mapTYPE = new Map();
pSheetEnum.CoinType.Add = function (arr) { let p = new TempObj24(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p); };
pSheetEnum.CoinCount = {};
class TempObj25 { constructor([KIND, TYPE, UIName, UIPriority, ]) { this.KIND = KIND;this.TYPE = TYPE;this.UIName = UIName;this.UIPriority = UIPriority; } }
pSheetEnum.CoinCount.arr = [];
pSheetEnum.CoinCount.mapKIND = new Map();
pSheetEnum.CoinCount.mapTYPE = new Map();
pSheetEnum.CoinCount.Add = function (arr) { let p = new TempObj25(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p); };
pSheetEnum.CoinOption = {};
class TempObj26 { constructor([KIND, TYPE, UIName, UIPriority, ]) { this.KIND = KIND;this.TYPE = TYPE;this.UIName = UIName;this.UIPriority = UIPriority; } }
pSheetEnum.CoinOption.arr = [];
pSheetEnum.CoinOption.mapKIND = new Map();
pSheetEnum.CoinOption.mapTYPE = new Map();
pSheetEnum.CoinOption.Add = function (arr) { let p = new TempObj26(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p); };
pSheetEnum.ProbOption = {};
class TempObj27 { constructor([KIND, TYPE, UIName, UIPriority, ]) { this.KIND = KIND;this.TYPE = TYPE;this.UIName = UIName;this.UIPriority = UIPriority; } }
pSheetEnum.ProbOption.arr = [];
pSheetEnum.ProbOption.mapKIND = new Map();
pSheetEnum.ProbOption.mapTYPE = new Map();
pSheetEnum.ProbOption.Add = function (arr) { let p = new TempObj27(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p); };
pSheetEnum.RankType = {};
class TempObj28 { constructor([KIND, TYPE, UIName, UIPriority, ]) { this.KIND = KIND;this.TYPE = TYPE;this.UIName = UIName;this.UIPriority = UIPriority; } }
pSheetEnum.RankType.arr = [];
pSheetEnum.RankType.mapKIND = new Map();
pSheetEnum.RankType.mapTYPE = new Map();
pSheetEnum.RankType.Add = function (arr) { let p = new TempObj28(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p); };
pSheetEnum.MuSoul = {};
class TempObj29 { constructor([KIND, TYPE, UIName, UIPriority, ]) { this.KIND = KIND;this.TYPE = TYPE;this.UIName = UIName;this.UIPriority = UIPriority; } }
pSheetEnum.MuSoul.arr = [];
pSheetEnum.MuSoul.mapKIND = new Map();
pSheetEnum.MuSoul.mapTYPE = new Map();
pSheetEnum.MuSoul.Add = function (arr) { let p = new TempObj29(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p); };
pSheetEnum.RequireLP = {};
class TempObj30 { constructor([KIND, TYPE, UIName, UIPriority, ]) { this.KIND = KIND;this.TYPE = TYPE;this.UIName = UIName;this.UIPriority = UIPriority; } }
pSheetEnum.RequireLP.arr = [];
pSheetEnum.RequireLP.mapKIND = new Map();
pSheetEnum.RequireLP.mapTYPE = new Map();
pSheetEnum.RequireLP.Add = function (arr) { let p = new TempObj30(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p); };
pSheetEnum.UserDeckSoulType = {};
class TempObj31 { constructor([KIND, TYPE, UIName, ]) { this.KIND = KIND;this.TYPE = TYPE;this.UIName = UIName; } }
pSheetEnum.UserDeckSoulType.arr = [];
pSheetEnum.UserDeckSoulType.mapKIND = new Map();
pSheetEnum.UserDeckSoulType.mapTYPE = new Map();
pSheetEnum.UserDeckSoulType.Add = function (arr) { let p = new TempObj31(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p); };
pSheetEnum.LibraryCategory = {};
class TempObj32 { constructor([KIND, TYPE, UIName, UIPriority, ]) { this.KIND = KIND;this.TYPE = TYPE;this.UIName = UIName;this.UIPriority = UIPriority; } }
pSheetEnum.LibraryCategory.arr = [];
pSheetEnum.LibraryCategory.mapKIND = new Map();
pSheetEnum.LibraryCategory.mapTYPE = new Map();
pSheetEnum.LibraryCategory.Add = function (arr) { let p = new TempObj32(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p); };
pSheetEnum.LoadFromDB = function(pDoc) { for (const key in pDoc) { if (key === "_info") { continue; } const [szNameSpace, szName] = key.split("+"); if (szNameSpace !== "Enum") { continue; } let mapColumnDatas = pDoc[key]; if (!mapColumnDatas) { continue; } for (const i in mapColumnDatas) { this[szName].Add(mapColumnDatas[i]); }}};
let pSheetColumn = {};
pSheetColumn.DefaultSearch = {};
class TempObj33 { constructor([KIND, TYPE, UIPriority, DBColumnKIND, ]) { this.KIND = KIND;this.TYPE = TYPE;this.UIPriority = UIPriority;this.DBColumnKIND = DBColumnKIND; } }
pSheetColumn.DefaultSearch.arr = [];
pSheetColumn.DefaultSearch.mapKIND = new Map();
pSheetColumn.DefaultSearch.mapTYPE = new Map();
pSheetColumn.DefaultSearch.Add = function (arr) { let p = new TempObj33(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p); };
pSheetColumn.ExtraSearchGroup = {};
class TempObj34 { constructor([KIND, UIName, UIPriority, ]) { this.KIND = KIND;this.UIName = UIName;this.UIPriority = UIPriority; } }
pSheetColumn.ExtraSearchGroup.arr = [];
pSheetColumn.ExtraSearchGroup.mapKIND = new Map();
pSheetColumn.ExtraSearchGroup.Add = function (arr) { let p = new TempObj34(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p); };
pSheetColumn.ExtraSearch = {};
class TempObj35 { constructor([KIND, TYPE, UIPriority, DBColumnKIND, Group, ]) { this.KIND = KIND;this.TYPE = TYPE;this.UIPriority = UIPriority;this.DBColumnKIND = DBColumnKIND;this.Group = Group; } }
pSheetColumn.ExtraSearch.arr = [];
pSheetColumn.ExtraSearch.mapKIND = new Map();
pSheetColumn.ExtraSearch.mapTYPE = new Map();
pSheetColumn.ExtraSearch.mapDBColumnKIND = new Map();
pSheetColumn.ExtraSearch.Add = function (arr) { let p = new TempObj35(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapTYPE.set(p.TYPE, p);this.mapDBColumnKIND.set(p.DBColumnKIND, p); };
pSheetColumn.DBColumn = {};
class TempObj36 { constructor([KIND, Query, DBColumnName, DBProcType, DBSearchPriority, DataType, UIName, ]) { this.KIND = KIND;this.Query = Query;this.DBColumnName = DBColumnName;this.DBProcType = DBProcType;this.DBSearchPriority = DBSearchPriority;this.DataType = DataType;this.UIName = UIName; } }
pSheetColumn.DBColumn.arr = [];
pSheetColumn.DBColumn.mapKIND = new Map();
pSheetColumn.DBColumn.mapQuery = new Map();
pSheetColumn.DBColumn.Add = function (arr) { let p = new TempObj36(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapQuery.set(p.Query, p); };
pSheetColumn.DBColumnUserDeck = {};
class TempObj37 { constructor([KIND, Query, DBColumnName, DBProcType, DBSearchPriority, DataType, UIName, UIShowValue, ]) { this.KIND = KIND;this.Query = Query;this.DBColumnName = DBColumnName;this.DBProcType = DBProcType;this.DBSearchPriority = DBSearchPriority;this.DataType = DataType;this.UIName = UIName;this.UIShowValue = UIShowValue; } }
pSheetColumn.DBColumnUserDeck.arr = [];
pSheetColumn.DBColumnUserDeck.mapKIND = new Map();
pSheetColumn.DBColumnUserDeck.mapQuery = new Map();
pSheetColumn.DBColumnUserDeck.Add = function (arr) { let p = new TempObj37(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapQuery.set(p.Query, p); };
pSheetColumn.DBColumnLibrary = {};
class TempObj38 { constructor([KIND, Query, DBColumnName, DBProcType, DBSearchPriority, DataType, UIName, UIShowValue, ]) { this.KIND = KIND;this.Query = Query;this.DBColumnName = DBColumnName;this.DBProcType = DBProcType;this.DBSearchPriority = DBSearchPriority;this.DataType = DataType;this.UIName = UIName;this.UIShowValue = UIShowValue; } }
pSheetColumn.DBColumnLibrary.arr = [];
pSheetColumn.DBColumnLibrary.mapKIND = new Map();
pSheetColumn.DBColumnLibrary.mapQuery = new Map();
pSheetColumn.DBColumnLibrary.Add = function (arr) { let p = new TempObj38(arr); this.arr.push(p); this.mapKIND.set(p.KIND, p);this.mapQuery.set(p.Query, p); };
pSheetColumn.LoadFromDB = function(pDoc) { for (const key in pDoc) { if (key === "_info") { continue; } const [szNameSpace, szName] = key.split("+"); if (szNameSpace !== "Column") { continue; } let mapColumnDatas = pDoc[key]; if (!mapColumnDatas) { continue; } for (const i in mapColumnDatas) { this[szName].Add(mapColumnDatas[i]); }}};

const CONST = {};
CONST._APP_MODE = { DEV_EMU : 1, DEV_LIVE : 2, LIVE : 3,  }; Object.freeze(CONST._APP_MODE);
CONST._DB_PROCTYPE = { EQUAL : "equal", ARRAY_CONTAINS : "array_contains", ARRAY_CONTAINS_ANY : "array_contains_any", ORDER_BY : "orderBy", IN : "in", START_AT : "startAt",  }; Object.freeze(CONST._DB_PROCTYPE);
CONST._CUSTOMDATA = { BuyGrade : "BuyGrade", CardUISoul : "CardUISoul", Category : "Category", CoinCount : "CoinCount", CoinOption : "CoinOption", CoinType : "CoinType", CompanyType : "CompanyType", DeckLimit : "DeckLimit", DurationType : "DurationType", EnchantSlot : "EnchantSlot", Episode : "Episode", EtcType : "EtcType", LV : "LV", MuSoul : "MuSoul", NameCount : "NameCount", ProbOption : "ProbOption", RaceType : "RaceType", RankType : "RankType", RareType : "RareType", RareUI : "RareUI", RequireLP : "RequireLP", RequireSoul : "RequireSoul", Skill1 : "Skill1", Skill2 : "Skill2", SoulType : "SoulType", StatType : "StatType", StatValue : "StatValue", TurnType : "TurnType", UnitType : "UnitType",  }; Object.freeze(CONST._CUSTOMDATA);
CONST._STRING = { TEST : "test",  }; Object.freeze(CONST._STRING);
CONST._INT = { TEST : 1, DEFAULT_SEARCH_NAME_KIND : 1, DBCOLUMN_KIND_NAME_EQUAL : 101, DBCOLUMN_KIND_NAME_CONTAINS : 112, USERDECK_LIMIT_TITLE_LENGTH : 40, USERDECK_LIMIT_CONTENT_LENGTH : 2000, USERDECK_LIMIT_DECK_ID_COUNT : 100, USERDECK_LIMIT_DECK_CARD_COUNT : 100, USERDECK_DBCOLUMN_KIND_UID : 102, REPORT_LIMIT_TITLE_LENGTH : 30, REPORT_LIMIT_CONTENT_LENGTH : 500, ACCOUNT_LIMIT_EMAIL_LENGTH : 60, ACCOUNT_LIMIT_PASSWORD_LENGTH : 50, ACCOUNT_LIMIT_NICK_LENGTH : 15,  }; Object.freeze(CONST._INT);
Object.freeze(CONST);

export { pSheetEnum, pSheetColumn, CONST,  };
