import { Children, useRef, useState } from "react"
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap"
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Tooltip from 'react-bootstrap/Tooltip';

import Util from "../mod/Util";
import GameUtil from "../mod/GameUtil";
import { CONST, pSheetColumn, pSheetEnum } from "../mod/CardClientSheetData";
import { useNavigate } from "react-router-dom";
import { Timestamp } from "firebase/firestore";

function ComLibraryPostView({
  pMaind,
  pPostData,
  bIsLimitView,
  cbOnDelete,
  cbOnList,
}) {

  let pCurUser = pMaind.pFbCtrl.GetAuthCurrentUser();

  const pNavigate = useNavigate();

  const [pUIData, funcSetUIData] = useState({
    pPostData: pPostData,
  });

  const [bShowReportUI, funcShowReportUI] = useState(false);

  if (!pUIData.pPostData) {
    return (
      <>
        <Card>
          <Card.Body>
            <Card.Text>데이터가 유효하지 않습니다.</Card.Text>
          </Card.Body>
        </Card>
      </>
    );
  }

  function OnClickURLCopy() {
    let szURL = `${window.location.origin}/library/post/${pPostData.id}`;
    navigator.clipboard.writeText(szURL);
    alert(`URL 복사 완료했습니다.\n${szURL}`);
  }

  function RefreshUI_Title() {
    let szCategory = "불명";
    if (pSheetEnum[`LibraryCategory`].mapKIND.has(pPostData.Category)) {
      let pEnum = pSheetEnum[`LibraryCategory`].mapKIND.get(pPostData.Category);
      if (pEnum) {
        szCategory = pEnum.UIName;
      }
    }

    let szTitle = pPostData.Title;

    return (
      <Card.Title className="mb-1" style={{ fontSize: "2rem", fontWeight: "bold" }}>
        {`[${szCategory}] ${szTitle}`}
      </Card.Title>
    );
  }

  function RefreshUI_Content() {

    let szContent = pPostData.Content;

    return (
      <>
        <Card.Text style={{ fontSize: "0.9rem", whiteSpace: "pre-wrap" }}>
          {szContent}
        </Card.Text>
      </>
    );
  }

  function RefreshUI_ReferenceLink() {
    if (!pPostData.ReferenceLink) {
      return (<></>);
    }

    return (
      <Card className="mb-1">
        <Card.Header>
          <Card.Text className="mb-0" style={{ fontWeight: "bold" }}>참조 링크</Card.Text>
        </Card.Header>
        <Card.Body className="py-2">
          <a href={pPostData.ReferenceLink}>{pPostData.ReferenceLink}</a>
        </Card.Body>
      </Card>
    );
  }

  function RefreshUI_Default() {
    let pCreateTime = pPostData.CreateTime.toDate();

    return (
      <>
        <Container>
          <Row>
            <Col className="px-0 mb-0 align-self-center" >
              <Card.Text style={{ color: "grey", fontSize: "0.8rem" }}>
                {`${Util.GetTimeDiffString(pCreateTime)}`}
              </Card.Text>
            </Col>
            <Col className="px-0 mb-0" style={{ textAlign: "right" }}>
              {/* OnClickSearchByUID */}
              <Button variant="outline-primary" className="px-2 py-1 me-1" onClick={OnClickURLCopy} style={{ fontSize: "0.8rem" }}>URL 복사</Button>
            </Col>
          </Row>
        </Container>

      </>
    );
  }

  function OnClickReport() {
    if (!pCurUser || !pCurUser.emailVerified) {
      alert("로그인 및 이메일 인증된 회원만 신고할 수 있습니다.");
      return;
    }

    funcShowReportUI(!bShowReportUI);
  }

  function OnClickEdit() {
    let pSearchParams = new URLSearchParams();
    pSearchParams.append("id", pPostData.id);
    pNavigate(`/library/write?${pSearchParams.toString()}`);
  }

  function OnClickDelete() {
    cbOnDelete(pPostData);
  }

  function OnClickList() {
    cbOnList();
  }

  function OnClickImage(szUrl) {
    window.open(szUrl);
  }

  function RefreshUI_Images() {
    let arr = [];

    if (!pPostData.Images || pPostData.Images.length <= 0) {
      return (
        <></>
      );
    }

    pPostData.Images.forEach((pImage) => {
      let szUrl = GameUtil.GetLibraryImagePath(pPostData.id, pImage.FileName);
      szUrl = pMaind.pFbCtrl.GetStorageURL(szUrl);

      arr.push(
        <img
          className="mb-1"
          loading="lazy"    //이미지가 뷰포트의 일정 거리 안으로 들어와야 불러옵니다.
          decoding="async"  //다른 콘텐츠의 표시 지연을 피하기 위해 이미지를 비동기적으로 디코딩 합니다.
          src={szUrl}
          style={{ maxWidth:"100%", objectFit: "scale-down", display:"block", margin:"auto" }}
          onClick={() => OnClickImage(szUrl)}
        >
        </img>
      );
    })

    return (Children.toArray(arr));
  }

  function RefreshUI_Buttons() {
    let fontSize = "0.8rem";

    if (pCurUser && pCurUser.uid === pPostData.UID) {
      return (
        <div className="d-flex justify-content-end">
          <Button variant="outline-danger" className="py-1 me-1" style={{ fontSize: fontSize }} onClick={() => { OnClickReport(pPostData) }}>신고</Button>
          <Button variant="outline-primary" className="py-1 me-1" style={{ fontSize: fontSize }} onClick={OnClickEdit}>수정</Button>
          <Button variant="danger" className="py-1 me-1" style={{ fontSize: fontSize }} onClick={OnClickDelete}>삭제</Button>
          <Button variant="outline-primary" className="py-1 me-1" style={{ fontSize: fontSize }} onClick={OnClickList}>목록</Button>
        </div>
      );
    }

    return (
      <div className="d-flex justify-content-end">
        <Button variant="outline-danger" className="py-1 me-1" style={{ fontSize: fontSize }} onClick={() => { OnClickReport(pPostData) }}>신고</Button>
        <Button variant="outline-primary" className="py-1 me-1" style={{ fontSize: fontSize }} onClick={OnClickList}>목록</Button>
      </div>
    );
  }

  // 전체를 감싸는 div 정의.
  let pDivSetting = {};
  pDivSetting.className = "";
  pDivSetting.style = {};

  let pCardBodySetting = {};
  pCardBodySetting.className = "";
  pCardBodySetting.style = {};

  if (bIsLimitView) {
    // 제한된 뷰 + 스크롤 표시
    pDivSetting.className = "overflow-scroll";
    pDivSetting.style = { maxHeight: "70vh" };

    pCardBodySetting.className = "px-0";
    pCardBodySetting.style = {};
  }

  return (
    <div className={pDivSetting.className} style={pDivSetting.style}>
      <Card>
        <Card.Header>
          {RefreshUI_Title()}
          {RefreshUI_Default()}
        </Card.Header>

        <Card.Body className={pCardBodySetting.className}>
          {RefreshUI_Images()}
          <Card className="mb-1">
            <Card.Header>
              <Card.Text className="mb-0" style={{ fontWeight: "bold" }}>내용</Card.Text>
            </Card.Header>
            <Card.Body>
              {RefreshUI_Content()}
            </Card.Body>
          </Card>

          {RefreshUI_ReferenceLink()}

          {RefreshUI_Buttons()}

          <UISendReport
            pMaind={pMaind}
            pCurUser={pCurUser}
            bShow={bShowReportUI}
            funcShow={funcShowReportUI}
            pPostData={pUIData.pPostData}
          >
          </UISendReport>
        </Card.Body>
      </Card>

    </div>
  )
}

function UISendReport({
  pMaind,
  pCurUser,
  bShow,
  funcShow,
  pPostData,
}) {
  let pRefReportTitle = useRef();
  let pRefReportContent = useRef();
  let fontSize = "0.8rem";

  if (!bShow) {
    return (<></>)
  }

  if (!pCurUser || !pCurUser.emailVerified) {
    return (<p>로그인 및 인증된 유저만 신고를 전송할 수 있습니다.</p>);
  }

  function OnClickClose() {
    funcShow(false);
  }

  async function OnClickSend() {
    let szTitle = pRefReportTitle.current.value;
    let szContent = pRefReportContent.current.value;

    let pReport = {
      ReportType: 4,
      State: 1,
      UID: pCurUser.uid,
      UserName: pCurUser.displayName,
      CreateTime: Timestamp.now(),
      UpdateTime: Timestamp.now(),
      Title: szTitle,
      Content: szContent,

      // 라이브러리 리포트 전용 정보
      LibraryDocID: pPostData.id,
      LibraryUID: pPostData.UID,
      LibrarykUserName: pPostData.UserName,
    };

    if (!pReport.UID) {
      alert(`유저 정보가 유효하지 않습니다. 재로그인 후 다시 시도해주세요.`);
      return;
    }

    if (!pReport.Title || pReport.Title.length > CONST._INT.REPORT_LIMIT_TITLE_LENGTH) {
      alert(`신고 제목은 최소 1자 최대 ${CONST._INT.REPORT_LIMIT_TITLE_LENGTH}자까지 입니다.(현재 ${pReport.Title.length}자)`);
      return;
    }
    if (!GameUtil.IsValidContent(pReport.Title)) {
      alert(`신고 제목에 유효하지 않은 특수문자가 있습니다.(${GameUtil.InvalidCharInContent})`);
      return;
    }

    if (!pReport.Content || pReport.Content.length > CONST._INT.REPORT_LIMIT_CONTENT_LENGTH) {
      alert(`신고 내용은 최소 1자 최대 ${CONST._INT.REPORT_LIMIT_CONTENT_LENGTH}자까지 입니다.(현재 ${pReport.Content.length}자)`);
      return;
    }
    if (!GameUtil.IsValidContent(pReport.Content)) {
      alert(`신고 내용에 유효하지 않은 특수문자가 있습니다.(${GameUtil.InvalidCharInContent})`);
      return;
    }

    if (!pReport.LibraryDocID) {
      alert(`신고 대상 문서 ID가 유효하지 않습니다.(${pReport.LibraryDocID})`);
      return;
    }

    try {
      let szDBName = GameUtil.GetDBReportCollection(pReport.UID);
      let [bResult, szDocID] = await pMaind.pFbCtrl.WriteDocToDB(szDBName, "", pReport);
      if (!bResult) {
        throw new Error("write-to-userdb");
      }

      // GMDB에 저장할때는 TargetDocID까지 저장
      pReport.TargetCollection = szDBName;
      pReport.TargetDocID = szDocID;

      szDBName = GameUtil.GetDBGMReportCollection();
      [bResult, szDocID] = await pMaind.pFbCtrl.WriteDocToDB(szDBName, "", pReport);
      if (!bResult) {
        throw new Error("write-to-gmdb");
      }

      alert("신고 접수 완료되었습니다.");
    }
    catch (error) {
      alert(`신고 접수 실패했습니다. (${error.message})`);
    }
  }

  return (
    <Card className="mb-3">
      <Card.Header>
        <Card.Text className="mb-0" style={{ fontWeight: "bold" }}>라이브러리 신고</Card.Text>
      </Card.Header>
      <Card.Body>
        <Form className="px-1 py-1">
          <Form.Group className="mb-3">
            <Form.Label style={{ fontSize: "0.9rem" }}>제목</Form.Label>
            <Form.Control className="mb-1" type="text" rows={3} ref={pRefReportTitle} />
            <Form.Label style={{ fontSize: "0.9rem" }}>내용</Form.Label>
            <Form.Control as="textarea" rows={3} ref={pRefReportContent} />
          </Form.Group>
        </Form>

        <div className="d-flex justify-content-end">
          <Button variant="outline-primary" className="py-1 me-1" style={{ fontSize: fontSize }} onClick={OnClickSend}>전송</Button>
          <Button variant="outline-danger" className="py-1 me-1" style={{ fontSize: fontSize }} onClick={OnClickClose}>닫기</Button>
        </div>
      </Card.Body>
    </Card>
  )
}

export { ComLibraryPostView }