import { Outlet } from "react-router-dom";

function PageLibraryLayout({
  pMaind
}) {
  return (
    <Outlet></Outlet>
  );
}

export default PageLibraryLayout;