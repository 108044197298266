import { useEffect, useState } from "react";

// bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// 
import { ComUtilFloatButton, EnumUtilFloatButtonType, CUtilFloatButtonParam } from "../mycom/ComUtilFloatButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ComCardErrorCheckList } from "../mycom/ComCardErrorCheckList";

function PageCardErrorCheck({ pMaind }) {
  
  const arrUtilButtonParam = [
    new CUtilFloatButtonParam(EnumUtilFloatButtonType.SCROLL_BOTTOM),
    new CUtilFloatButtonParam(EnumUtilFloatButtonType.SCROLL_TOP),
  ];

  return (
    <>
      <Row className="my-3">
        <Col>
          <h2 className="text-center">{`(개발) 카드 검수 페이지`}</h2>
        </Col>
      </Row>

      {/* main */}
      <ComCardErrorCheckList
        pMaind={pMaind}
        bIsLimitView={false}
      >

      </ComCardErrorCheckList>

      {/*  */}
      <ComUtilFloatButton arrParams={arrUtilButtonParam}></ComUtilFloatButton>
    </>
  );
}

export default PageCardErrorCheck;