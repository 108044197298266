let Util = { };

/**
 * 현재 UTC 시각을 년월일시분초+밀리초 까지 다합쳐서 숫자로 구성된 문자열로 반환
 * 예시) (UTC 기준) 2023년 02월 23일 오전 03시 53분 08초 582
 * return 202302230353080582
 */
Util.GetCurrentUTCTimeString = function() {
  let date = new Date();
  let year = date.getUTCFullYear();
  let month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  let day = date.getUTCDate().toString().padStart(2, '0');
  let hours = date.getUTCHours().toString().padStart(2, '0');
  let minutes = date.getUTCMinutes().toString().padStart(2, '0');
  let seconds = date.getUTCSeconds().toString().padStart(2, '0');
  let milliseconds = date.getUTCMilliseconds().toString().padStart(4, '0');
  return year + '' + month + '' + day + '' + hours + '' + minutes + '' + seconds + '' + milliseconds;
};

Util.GetUTCTimeStringByFormat = function(date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hour = date.getHours().toString().padStart(2, '0');
  const minute = date.getMinutes().toString().padStart(2, '0');
  const second = date.getSeconds().toString().padStart(2, '0');
  return `${year}.${month}.${day} ${hour}:${minute}:${second}`;
};

Util.GetTimeDiffInSeconds = function(targetTime) {
  const currentTime = new Date();
  const timeDifference = currentTime - targetTime;
  return timeDifference / 1000;
};

Util.GetTimeDiffString = function(targetTime) {
  const nDiff = this.GetTimeDiffInSeconds(targetTime);
  if (nDiff <= 1) {
    return "방금 전";
  }
  else if (nDiff > 1 && nDiff < 60) {
    return "방금 전";
  }
  else if (nDiff >= 60 && nDiff < 3600) {
    return `${parseInt(nDiff / 60)}분 전`;
  }
  else if (nDiff >= 3600 && nDiff < 86400) {
    return `${parseInt(nDiff / 3600)}시간 전`;
  }
  // else if (nDiff >= 86400 && nDiff < 2592000) {
  //   return `${parseInt(nDiff / 86400)}일 전`; // 30일 전까지
  // }
  else {
    return this.GetUTCTimeStringByFormat(targetTime);
  }
};

Util.GetDoubleQuotaMarkText = function(str) {
  if (str.startsWith('"') && str.endsWith('"')) {
    return [true, str.slice(1, -1)];
  }
  return [false, str];
}

Util.IsValidEmail = function (email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};


// 숫자
Util.GetRandomIntInRange = function (start, end, count) {
  if (count > (end - start + 1)) {
    throw new Error('The count is larger than the available range');
  }

  const resultSet = new Set();

  while (resultSet.size < count) {
    const randomInt = Math.floor(Math.random() * (end - start + 1)) + start;
    resultSet.add(randomInt);
  }

  return Array.from(resultSet);
}

// Async 용 도움 함수
Util.WaitTimeAsync = function(millisec) {
  return new Promise((resolve, reject)=> {
    setTimeout(()=> {
      resolve();
    }, millisec);
  });
}

Object.freeze(Util);
export default Util;


