import { Children, useState } from "react";
import { Card, Col, Container, ProgressBar, Row } from "react-bootstrap";
import { pSheetEnum } from "../mod/CardClientSheetData";
import GameUtil from "../mod/GameUtil";


function ComUserDeckSummary({
  arrDeck,
  bShowDeckList,
  bShowLV,
  bShowCategory,
  cbOnClickListItem,
}) {

  let [nTotalCount, mapSoulType, mapLV, mapCategory] = GameUtil.CalcUserDeck(arrDeck);

  return (
    <>
      <div className="mb-1">
        <Card.Text className="mb-0" style={{ fontSize: "0.8rem" }}>{`총 ${nTotalCount}장`}</Card.Text>

        <UISummaryCardList
          mapCategory={mapCategory}
          bShowDeckList={bShowDeckList}
          cbOnClickListItem={cbOnClickListItem}
        >
        </UISummaryCardList>

        <UISummaryLV
          mapLV={mapLV}
          bShowLV={bShowLV}
        >
        </UISummaryLV>

        <UISummaryCategory
          mapCategory={mapCategory}
          bShowCategory={bShowCategory}
        >
        </UISummaryCategory>

        <UISummarySoulType
          mapSoulType={mapSoulType}
        >
        </UISummarySoulType>
      </div>
    </>
  );
}

function UISummarySoulType({
  mapSoulType
}) {
  let szText = "";
  let arrMap = [];
  mapSoulType.forEach((pItem, _k) => {
    arrMap.push(pItem);
  });
  arrMap.sort((a, b) => {
    if (a.Count === b.Count) {
      return a.UIPriority - b.UIPriority;
    }
    return -(a.Count - b.Count);
  });
  arrMap.forEach((pItem) => {
    szText += `${pItem.UIName}(${pItem.Count}장) `;
  });
  if (arrMap.length <= 0) {
    szText += "없음"
  }

  return (
    <Card.Text className="mb-0" style={{ fontSize: "0.8rem" }}>{`속성별 : ${szText}`}</Card.Text>
  );
}

function UISummaryCategory({
  mapCategory,
  bShowCategory
}) {

  if (!bShowCategory) {
    return (<></>);
  }

  let szText = "";

  let arrEnumCategory = [];
  pSheetEnum[`Category`].arr.forEach((e) => arrEnumCategory.push(e));
  arrEnumCategory.sort((a, b) => a.UIPriority - b.UIPriority);

  arrEnumCategory.forEach((pEnumCategory) => {
    let pCategory = { TotalCount : 0, Datas : [] };
    if (mapCategory.has(pEnumCategory.KIND)) {
      pCategory = mapCategory.get(pEnumCategory.KIND);
    }

    if (pCategory.TotalCount <= 0) {
      return;
    }

    szText += `${pEnumCategory.UIName}(${pCategory.TotalCount}장) `;
  });

  if (mapCategory.length <= 0) {
    szText += `없음`;
  }

  return (
    <Card.Text className="mb-0" style={{ fontSize: "0.8rem" }}>{`분류별 : ${szText}`}</Card.Text>
  );
}

function UISummaryLV({
  mapLV,
  bShowLV
}) {
  if (!bShowLV) {
    return (<></>);
  }

  let arr = [];

  let nLVTotalCount = 0; // LV 0, 즉 지형은 뺄 것이므로 그 외의 카드 수
  let nLVCountMax = 0;
  let arrLV = [];
  pSheetEnum[`LV`].arr.forEach((pEnum) => {
    if (pEnum.KIND === 0) {
      return;
    }

    let nCount = 0;
    if (mapLV.has(pEnum.KIND)) {
      nCount = mapLV.get(pEnum.KIND).Count;
    }

    nLVTotalCount += nCount;

    if (nLVCountMax <= nCount) {
      nLVCountMax = nCount;
    }
    arrLV.push({ pEnum: pEnum, Count: nCount });
  });

  arrLV.sort((a, b) => a.pEnum.UIPriority - b.pEnum.UIPriority);
  arrLV.forEach((pData) => {

    let nProgressMax = nLVCountMax + parseInt(nLVCountMax * 0.1 + 1);
    let nPercent = 0;
    if (nLVTotalCount > 0) {
      nPercent = (pData.Count / nLVTotalCount * 100).toFixed(1);
    }

    arr.push(
      <Row className="my-1">
        <Col xs={1}>
          <Card.Text style={{ fontSize: "0.8rem" }}>{`${pData.pEnum.UIName}`}</Card.Text>
        </Col>
        <Col xs={7}>
          <ProgressBar variant="primary" min={0} max={nProgressMax} now={pData.Count} />
        </Col>
        <Col xs={4}>
          <Card.Text style={{ fontSize: "0.8rem" }}>{`${nPercent}% (${pData.Count}장)`}</Card.Text>
        </Col>
      </Row>
    );
  });

  return (
    <div className="my-3">
      <Card.Text className="mb-0" style={{ fontSize: "0.8rem" }}>{`레벨별 (지형 제외 ${nLVTotalCount}장)`}</Card.Text>
      <Container>
        {Children.toArray(arr)}
      </Container>
    </div>
  );
}


let pMapSoulSymbol = new Map([
  [1, { Symbol: "●", Sub: "암", Color: "4e1063" }], // 1 DARK
  [2, { Symbol: "●", Sub: "불", Color: "d51f0b" }], // 2 FIRE
  [3, { Symbol: "●", Sub: "물", Color: "0763f1" }], // 3 WATER
  [4, { Symbol: "●", Sub: "숲", Color: "467312" }], // 4 FOREST
  [5, { Symbol: "●", Sub: "금", Color: "b0aea4" }], // 5 IRON
  [6, { Symbol: "●", Sub: "대", Color: "7f4d1b" }], // 6 EARTH
  [7, { Symbol: "●", Sub: "빛", Color: "e7da6b" }], // 7 LIGHT
]);


function UISummaryCardList({
  mapCategory,
  bShowDeckList,
  cbOnClickListItem
}) {
  if (!bShowDeckList) {
    return (<></>);
  }

  let arrEnumCategory = [];
  pSheetEnum[`Category`].arr.forEach((e) => arrEnumCategory.push(e));
  arrEnumCategory.sort((a, b) => a.UIPriority - b.UIPriority);

  let arrTotal = [];
  arrEnumCategory.forEach((pEnumCategory) => {
    let pCategory = { TotalCount : 0, Datas : [] };
    if (mapCategory.has(pEnumCategory.KIND)) {
      pCategory = mapCategory.get(pEnumCategory.KIND);
    }

    // 
    arrTotal.push(<Card.Text className="my-1" style={{ fontSize: "0.9rem", fontWeight: "bold" }}>{`${pEnumCategory.UIName} (${pCategory.TotalCount}장)`}</Card.Text>);

    pCategory.Datas.forEach((pData) => {
      let pSoulSymbol = pMapSoulSymbol.get(pData.SoulType);
      let cbOnClick = function (NumID) { };
      if (cbOnClickListItem) {
        cbOnClick = cbOnClickListItem;
      }
      arrTotal.push(
        <Container>
          <Row style={{ borderBottom: "1px solid rgb(159 159 159 / 46%)" }}>
            <Col className="px-0" xs={1}>
              <Card.Text style={{ fontSize: "0.8rem" }}>
                {`${pData.LV} `}
              </Card.Text>
            </Col>
            <Col className="px-0" xs={8}>
              <Card.Text style={{ fontSize: "0.8rem" }}>
                <span style={{ color: `#${pSoulSymbol.Color}` }}>{`${pSoulSymbol.Symbol} `}</span>
                <span style={{ cursor: "pointer" }} onClick={() => cbOnClick(pData.NumID)}>{`${pData.Name}`}</span>
              </Card.Text>
            </Col>
            <Col className="px-0" xs={2}>
              <Card.Text style={{ fontSize: "0.8rem" }}>
                {`x${pData.Count} `}
              </Card.Text>
            </Col>
            <Col className="px-0" xs={1}>
              <Card.Text style={{ fontSize: "0.8rem" }}>
                {pData.KeyCard === 1 ? `🟡` : ``}
              </Card.Text>
            </Col>
          </Row>
        </Container>
      );
    });
  });

  let arr = [[], []];
  let nHalfIdx = parseInt(arrTotal.length / 2) + 1;
  arrTotal.forEach((e, idx) => {
    let nTargetIdx = parseInt(idx / nHalfIdx); // 0이거나 1이거나
    arr[nTargetIdx].push(e);
  });

  return (
    <div className="my-2">
      <Container>
        <Row xs={1} sm={2} md={2}>
          <Col>
            {Children.toArray(arr[0])}
          </Col>
          <Col>
            {Children.toArray(arr[1])}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ComUserDeckSummary;