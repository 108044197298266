import { useEffect, useState } from "react";

// bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// 
import { ComCardList } from "../mycom/ComCardList";
import { ComCardDetailModal, CCardDetailModalButtonParam } from '../mycom/ComCardDetailModal';
import { ComUtilFloatButton, EnumUtilFloatButtonType, CUtilFloatButtonParam } from "../mycom/ComUtilFloatButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { pSheetColumn } from "../mod/CardClientSheetData";

function PageCardListLayout({ pMaind }) {
  // query param
  const [pURLSearchParams, funcSetURLSearchParams] = useSearchParams();

  // 
  const pNavigate = useNavigate();

  // 카드 상세정보 모달창 용 
  let [pDetailData, funcSetDetailData] = useState({
    show: false,
    pData: null,
    arrButtonParams: [
      new CCardDetailModalButtonParam("관련 유저덱 검색", "outline-primary", OnClickSearchUserDeck),
      new CCardDetailModalButtonParam("닫기", "danger", OnClickModalClose),
    ]
  });

  // query param 대응
  useEffect(() => {
    function _get_condition_from_query_() {
      console.log(`_get_condition_from_query_ : ${pURLSearchParams.toString()}`);

      let arrRetVal = [];

      pURLSearchParams.forEach((v, k) => {
        if (!pSheetColumn.DBColumn.mapQuery.has(k)) {
          return;
        }

        let pDataDBColumn = pSheetColumn.DBColumn.mapQuery.get(k);
        if (!pDataDBColumn) {
          return;
        }

        let pValue = v;
        if (pDataDBColumn.DataType !== "string") {
          pValue = parseInt(pValue);
        }

        arrRetVal.push({ pDataDBColumn: pDataDBColumn, Value: pValue });
      })

      return arrRetVal;
    }

    funcSetSearchCondition(_get_condition_from_query_());
  }, [pURLSearchParams]);

  // 

  let [arrSearchCondition, funcSetSearchCondition] = useState([]);

  // Event Binding
  function OnClickSheetItem(pCardData) {
    if (!pCardData) {
      return;
    }

    pDetailData.show = true;
    pDetailData.pData = pCardData;
    funcSetDetailData({ ...pDetailData });
  }

  function OnClickSheetSearch(arrInputSearch) {
    let pNewParams = new URLSearchParams();
    arrInputSearch.forEach((e) => {
      pNewParams.set(e.pDataDBColumn.Query, String(e.Value));
    });

    funcSetURLSearchParams(pNewParams);
  }

  function OnClickSearchUserDeck(pCardData) {
    let pSearchParams = new URLSearchParams();
    pSearchParams.append("card", pCardData.NumID);
    pNavigate(`/userdeck/board?${pSearchParams.toString()}`);
  }

  function OnClickModalClose(pCardData) {
    // pCardData 실제로 쓰진 않는다.

    // 닫기
    pDetailData.show = false;
    funcSetDetailData({ ...pDetailData });
  }

  const arrUtilButtonParam = [
    new CUtilFloatButtonParam(EnumUtilFloatButtonType.SCROLL_BOTTOM),
    new CUtilFloatButtonParam(EnumUtilFloatButtonType.SCROLL_TOP),
  ];

  return (
    <>
      <Row className="my-3">
        <Col>
          <h2 className="text-center">카드목록</h2>
        </Col>
      </Row>

      {/* main */}
      <ComCardList
        pMaind={pMaind}
        bIsLimitView={false}
        cbOnClickItem={OnClickSheetItem}
        cbOnClickSearch={OnClickSheetSearch}
        arrSearchCondition={arrSearchCondition}
      >

      </ComCardList>

      {/*  */}
      <ComUtilFloatButton arrParams={arrUtilButtonParam}></ComUtilFloatButton>

      {/* modal */}
      <ComCardDetailModal
        pMaind={pMaind}
        pDetailData={pDetailData}
        funcSetDetailData={funcSetDetailData}
        arrButtonParams={pDetailData.arrButtonParams}
        funcOnHide={OnClickModalClose}
      >
      </ComCardDetailModal>
    </>
  );
}

export default PageCardListLayout;