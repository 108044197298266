// firebase
import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator, getIdTokenResult } from "firebase/auth";
import {
  // Firestore
  initializeFirestore,
  connectFirestoreEmulator,
  collection,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  limitToLast,
  endBefore,
  startAfter,
  getCountFromServer,

  // 캐싱 관련 
  enableIndexedDbPersistence,
  CACHE_SIZE_UNLIMITED,
  getDocsFromServer,
  getDocsFromCache,
  clearIndexedDbPersistence,
  doc,
  getDoc,
  setDoc,
  addDoc,
  deleteDoc,
  startAt,

} from "firebase/firestore";

import {
  // Storage
  getStorage,
  connectStorageEmulator,
  getDownloadURL,
  ref,
  uploadBytes,
  deleteObject,
} from "firebase/storage";


// personal
import { CONST } from "./CardClientSheetData";
import GameEnv from "./GameEnv";


class FirebaseManager {
  constructor() {
    this.bDbUseLocalCache = true;

    this.config = null;
    this.app = null;
    this.auth = null;
    this.db = null;
    this.storage = null;

    this.storageURL = "";
  }
  // auth
  GetAuthCurrentUser() {
    if (!this.auth) {
      return null;
    }

    return this.auth.currentUser;
  }

  async GetAuthUserIDToken() {
    let pCurUser = this.GetAuthCurrentUser();
    if (!pCurUser) {
      return null;
    }

    return await getIdTokenResult(pCurUser);
  }

  // db

  CreateSearchQuery(szDBColumnName, szDBProcType, pValue) {
    switch (szDBProcType) {
      case CONST._DB_PROCTYPE.EQUAL: {
        return where(szDBColumnName, "==", pValue);
      }

      case CONST._DB_PROCTYPE.ARRAY_CONTAINS: {
        return where(szDBColumnName, "array-contains", pValue);
      }

      case CONST._DB_PROCTYPE.ARRAY_CONTAINS_ANY: {
        return where(szDBColumnName, "array-contains-any", pValue);
      }

      case CONST._DB_PROCTYPE.ORDER_BY: {
        return orderBy(szDBColumnName, pValue);
      }

      case CONST._DB_PROCTYPE.IN: {
        return where(szDBColumnName, "in", pValue);
      }

      case CONST._DB_PROCTYPE.START_AT: {
        return startAt(pValue);
      }

      default: {
        return null;
      }
    }
  }

  async DeleteDocSimple(szCollection, szID) {
    const docRef = doc(this.db, szCollection, szID);
    await deleteDoc(docRef);
  }

  async GetAllDocs(szCollection) {
    let arr = [];

    const querySnapshot = await getDocs(collection(this.db, szCollection));
    querySnapshot.forEach((doc) => {
      arr.push({ id: doc.id, ...doc.data() });
    });

    return arr;
  }

  async GetDocSimple(szCollection, szID) {
    const docRef = doc(this.db, szCollection, szID);
    const docSnap = await getDoc(docRef);

    let pData = docSnap.data();
    if (!pData) {
      return null;
    }

    return { id: docSnap.id, ...docSnap.data() };
  }

  async GetMyDBResult(pQuery, bForceFromServer) {
    if (!pQuery) {
      return {
        pSnapShot: null,
        cached: false
      };
    }

    let cached = true;
    let p;

    if (bForceFromServer) {
      cached = false;
      p = await getDocsFromServer(pQuery);
    }
    else {
      cached = true;
      p = await getDocsFromCache(pQuery);

      if (p.size <= 0) {
        cached = false;
        p = await getDocsFromServer(pQuery);
      }
    }

    return {
      pSnapShot: p,
      cached: cached
    };
  }

  async GetSearchedDocs(szCollection,
    arrQueryConditions,
    nLimit,
    nTotalCount,
    szColumnNameOrder,
    pOrderValueEnd) {

    if (nLimit <= 0 || nLimit > 50) {
      // TODO : 일단 막아둠
      throw new Error(`GetSearchedDocs : nLimit is equal 0 or over 50`);
    }

    let TotalCount = 0;
    let arr = [];

    let pQuery;
    let pDBResult;
    console.log(`GetSearchedDocs : ${szCollection} ${(Boolean(pOrderValueEnd) ? "Add" : "New")} ${nLimit}`);

    let arrQueryCopy = [];
    arrQueryCopy.push(...arrQueryConditions);

    // TotalCount 관련 처리
    if (pOrderValueEnd) {
      // (추가 검색) : 이전 TotalCount 를 유지
      TotalCount = nTotalCount;
    }
    else {
      // (새 검색) : 조건에 맞는 데이터의 전체 갯수 체크
      pQuery = query(collection(this.db, szCollection), ...arrQueryCopy);
      let pSnapShotCount = await getCountFromServer(pQuery);
      TotalCount = pSnapShotCount.data().count;
    }

    if (pOrderValueEnd) {
      // 정렬된 DB 데이터를 pSnapEnd의 다음부터 시작(start)하도록 자른다 + 앞에서부터 nLimit만큼 가져온다
      arrQueryCopy.push(startAfter(pOrderValueEnd));
    }

    // 조건에 맞는걸 맨앞에서부터 nLimit 만큼 가져온다
    arrQueryCopy.push(limit(nLimit));
    // 검색
    pQuery = query(collection(this.db, szCollection), ...arrQueryCopy);
    pDBResult = await this.GetMyDBResult(pQuery);

    let nCacheDataCount = 0;
    let _pNewOrderValueEnd = null;
    let nSnapshotEndIdx = pDBResult.pSnapShot.docs.length - 1;

    pDBResult.pSnapShot.docs.forEach((pDocSnapshot, idx) => {
      let pDoc = { id: pDocSnapshot.id, ...pDocSnapshot.data() };

      if (idx === nSnapshotEndIdx) {
        _pNewOrderValueEnd = pDoc[szColumnNameOrder];
      }

      if (pDocSnapshot.metadata.fromCache) {
        nCacheDataCount++;
      }

      arr.push(pDoc);
    })

    return {
      TotalCount: TotalCount,
      arrResult: arr,
      pOrderValueEnd: _pNewOrderValueEnd,
    };
  }


  async WriteDocToDB(szCollection, szID, obj) {
    // return : result, DocID
    if (!szCollection) {
      return [false, szDocID];
    }

    let szDocID = szID;

    try {
      if (szDocID) {
        const docRef = doc(this.db, szCollection, szDocID);
        await setDoc(docRef, obj);
      }
      else {
        const docRef = await addDoc(collection(this.db, szCollection), obj);
        szDocID = docRef.id;
      }

      // 최종적으로 결과 true 반환
      return [true, szDocID];
    }
    catch (error) {
      alert(`WriteDocToDB : ${error.message}`);
      return [false, szDocID];
    }
  }

  // storage

  async GetDownloadURL(szPath) {
    try {
      if (!szPath) {
        // 빈 값을 getDownloadURL 에 집어넣으려고 하면 root 폴더 경로로 인식한다.
        // 이걸로는 로드도 안되고 error 난다.
        return szPath;
      }

      const pFileRef = ref(this.storage, szPath);
      let url = await getDownloadURL(pFileRef);
      return url;
    }
    catch (error) {
      console.log(`FirebaseManager : GetDownloadURL failed : ${szPath}`);
      console.log(error);
      return "";
    }
  }

  GetStorageURL(szPath) {
    try {
      if (!szPath) {
        // 빈 값은 root 폴더 경로로 인식한다.
        // 이걸로는 로드도 안되고 error 난다.
        return szPath;
      }

      return `${this.storageURL}/${szPath}`;
    }
    catch (error) {
      console.log(`FirebaseManager : GetStorageURL failed : ${szPath}`);
      console.log(error);
      return "";
    }
  }


  async UploadToStorage(szPath, pFile) {
    try {
      if (!szPath || !pFile) {
        throw new Error(`인자가 유효하지 않음`);
      }

      console.log(`upload start : ${pFile.name} >>> ${szPath}`);
      const pFileRef = ref(this.storage, szPath);
      await uploadBytes(pFileRef, pFile);
      console.log(`upload complete : ${pFile.name} >>> ${szPath}`);

      return [true, ""];
    }
    catch (error) {
      console.log(error);
      console.log(error.message);

      return [false, error.message];
    }
  }

  async DeleteFileInStorage(szPath) {
    try {
      if (!szPath) {
        throw new Error(`인자가 유효하지 않음`);
      }

      console.log(`delete start : ${szPath}`);
      const pFileRef = ref(this.storage, szPath);
      await deleteObject(pFileRef);
      console.log(`delete complete : ${szPath}`);

      return [true, ""];
    }
    catch (error) {
      console.log(error);
      console.log(error.message);

      return [false, error.message];
    }
  }

  // Init 함수
  async Init() {

    console.log(`called InitFirebaseManager`);

    let pManager = this;

    async function __get_my_auth__() {
      switch (GameEnv.GetAppMode()) {

        case CONST._APP_MODE.DEV_LIVE:
        case CONST._APP_MODE.LIVE: {
          pManager.auth = getAuth(pManager.app);
          break;
        }

        case CONST._APP_MODE.DEV_EMU: {
          pManager.auth = getAuth();
          connectAuthEmulator(pManager.auth, "http://localhost:9099");
          break;
        }

        default: {
          break;
        }
      }
    }

    async function __get_my_firestore__() {
      pManager.db = initializeFirestore(pManager.app, {
        cacheSizeBytes: CACHE_SIZE_UNLIMITED
      });

      switch (GameEnv.GetAppMode()) {
        case CONST._APP_MODE.DEV_LIVE:
        case CONST._APP_MODE.LIVE: {
          // 앞서 initializeFirestore 처리 했으면 더 할일 없음
          break;
        }
        case CONST._APP_MODE.DEV_EMU: {
          // 에뮬레이터 설정
          connectFirestoreEmulator(pManager.db, 'localhost', 8080);
          break;
        }

        default: {
          break;
        }
      }

      // 로컬 캐시 관련 - 작업중 일단 비활성화.
      // ProcLocalCache();


      // Subsequent queries will use persistence, if it was enabled successfully
    }

    async function __proc_local_cache__() {

      // 로컬 캐시 설정
      try {
        await clearIndexedDbPersistence(pManager.db);
        console.log("Firestore clearIndexedDbPersistence success");
      }
      catch (err) {
        console.log("!!!Firestore clearIndexedDbPersistence failed!!!");
        console.log(err);
      }

      // 로컬 캐시 사용 설정
      try {
        await enableIndexedDbPersistence(pManager.db);
        console.log("Firestore enableIndexedDbPersistence success");
      }
      catch (err) {
        console.log("!!!Firestore enableIndexedDbPersistence failed!!!");
        console.log(err);

        if (err.code === 'failed-precondition') {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
        } else if (err.code === 'unimplemented') {
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
        }
      }
    }

    async function __get_my_storage__() {
      pManager.storage = null;

      switch (GameEnv.GetAppMode()) {
        case CONST._APP_MODE.DEV_LIVE:
        case CONST._APP_MODE.LIVE: {
          // https://cloud.google.com/storage/docs/access-public-data?hl=ko#api-link
          // https://storage.googleapis.com/[BUCKET_NAME]/[OBJECT_PATH]
          pManager.storageURL = `https://storage.googleapis.com/${GameEnv.GetFirebaseStorageBucketName()}`;

          pManager.storage = getStorage(pManager.app);
          break;
        }

        case CONST._APP_MODE.DEV_EMU: {
          // [로컬 에뮬레이터 localhost:포트]/[BUCKET_NAME]/[OBJECT_PATH]
          pManager.storageURL = `http://localhost:9199/${GameEnv.GetFirebaseStorageBucketName()}`;

          pManager.storage = getStorage();
          connectStorageEmulator(pManager.storage, "localhost", 9199);
          break;
        }

        default: {
          break;
        }
      }
    }

    pManager.firebaseConfig = {
      apiKey: GameEnv.GetFirebaseAPIKey(),
      authDomain: GameEnv.GetFirebaseAuthDomain(),
      databaseURL: GameEnv.GetFirebaseDatabaseURL(),
      projectId: GameEnv.GetFirebaseProjectID(),
      storageBucket: GameEnv.GetFirebaseStorageBucketName(),
      messagingSenderId: GameEnv.GetFirebaseMessagingSenderID(),
      appId: GameEnv.GetFirebaseAppID()
    };

    pManager.app = initializeApp(pManager.firebaseConfig);
    await __get_my_auth__()
    await __get_my_firestore__();
    await __get_my_storage__();
  }
}

export default FirebaseManager;