import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { ButtonGroup, Card, Container, Form, Image, ListGroup, } from 'react-bootstrap';

import { useState, Children, useRef } from "react";

// 
import { pSheetEnum, pSheetColumn, CONST } from '../mod/CardClientSheetData';
import GameUtil from '../mod/GameUtil';
import { useNavigate } from 'react-router-dom';
import { Timestamp } from 'firebase/firestore';


function ComCardDataView({
  pMaind,
  pCurUser,
  pCardData,
  bIsLimitView
}) {

  function OnClickURLCopy() {
    let szURL = `${window.location.origin}/card/${pCardData.id}`;
    navigator.clipboard.writeText(szURL);
    alert(`URL 복사 완료했습니다.\n${szURL}`);
  }

  function RefreshUI_TopButtons() {
    return (
      <div className="mb-1 d-flex justify-content-end">
        <Button variant="outline-primary" className="px-2 py-1 me-1" onClick={OnClickURLCopy} style={{ fontSize: "0.8rem" }}>URL 복사</Button>
      </div>
    );
  }

  // UI Functions
  function RefreshUI_CardLargeImage() {

    let szImageURL = GameUtil.GetImageDefaultPathLarge(); // 기본 이미지 경로

    if (pCardData) {
      szImageURL = GameUtil.GetImagePathLarge(pCardData.Category, pCardData.KIND);

      if (szImageURL) {
        szImageURL = pMaind.pFbCtrl.GetStorageURL(szImageURL);
      }
    }

    return (
      <div className='mx-auto mb-0' style={{ width: "285px" }}>
        <Image src={szImageURL} style={{ width: "285px", height: "405px", background: `url("${GameUtil.GetImageDefaultPathLarge()}")` }}></Image>
      </div>
    );
  }

  let pDivSetting = {
    szClassName: "",
    pStyle: {}
  };
  if (bIsLimitView) {
    pDivSetting.szClassName = "overflow-scroll";
    pDivSetting.pStyle = { maxHeight: "65vh" };
  }

  return (
    <div className={pDivSetting.szClassName} style={pDivSetting.pStyle}>

      {RefreshUI_CardLargeImage()}
      {RefreshUI_TopButtons()}

      <FMCardDetailInfo
        pMaind={pMaind}
        pCurUser={pCurUser}
        pCardData={pCardData}
      >
      </FMCardDetailInfo>
    </div>
  );
}

const EnumTabType = {
  BASE_INFO: 1,
  CATEGORY_INFO: 2,
  TEXT_BASE: 3,
  REPORT_ERROR: 5,
};
Object.freeze(EnumTabType);

function FMCardDetailInfo({
  pMaind,
  pCurUser,
  pCardData,
}) {

  let pGlobalSetting = {
    fontSize: "0.9rem",

    // 이모지
    EmojiATK: "공",
    EmojiDEF: "방",
    EmojiHP: "체",
  };

  const pNavigate = useNavigate();
  const [nSelectedTabType, funcSetTabType] = useState(EnumTabType.CATEGORY_INFO);

  let pRefReportContent = useRef();

  if (!pCardData) {
    return (<></>);
  }

  function _get_listgroup_item_oneline_text_(szColumn, pContent) {
    return (
      <ListGroup.Item className='py-1' style={{ fontSize: pGlobalSetting.fontSize }}>
        <b>{szColumn}</b>{pContent}
      </ListGroup.Item>);
  }

  function _get_listgroup_item_oneline_content_(pContent) {
    return (
      <ListGroup.Item className='py-1' style={{ fontSize: pGlobalSetting.fontSize }}>
        {pContent}
      </ListGroup.Item>);
  }

  function _get_text_stat_(pStatType, pStatValue) {
    let szStatType = "";
    if (pStatType !== 1) {
      // 1을 제외한 나머지는 어떤 식으로든 기호가 있는 것이다.
      szStatType = `${GameUtil.GetEnumUIName("StatType", pStatType)}`;
    }

    return `${szStatType}${String(pStatValue)}`;
  }

  function _get_text_coin_(pCoinType, pCoin) {
    if (pCoinType === 1) {
      return ``; // 1 이면 아예 코인 설정이 없는거다
    }

    let szCoinType = `${GameUtil.GetEnumUIName("CoinType", pCoinType)}`;
    return `${szCoinType}${String(pCoin)}`;
  }

  function _get_button_skill_(pSkill01, pSkill02) {
    let arr = [];

    let isAddSkill = false;
    if (pSkill01 !== 1) {
      isAddSkill = true;
      arr.push(<Button className='py-0 me-1' style={{ fontSize: pGlobalSetting.fontSize }}>{`${GameUtil.GetEnumUIName("Skill1", pSkill01)}`}</Button>);
    }

    if (pSkill02 !== 1) {
      isAddSkill = true;
      arr.push(<Button className='py-0 me-1' style={{ fontSize: pGlobalSetting.fontSize }}>{`${GameUtil.GetEnumUIName("Skill2", pSkill02)}`}</Button>);
    }

    if (!isAddSkill) {
      arr.push(<>없음</>);
    }

    return (
      <ListGroup.Item className='py-1'>
        <p className='my-0' style={{ fontSize: pGlobalSetting.fontSize }}><b>스킬 : </b>{Children.toArray(arr)}</p>
      </ListGroup.Item>
    );
  }

  function _get_text_duration_(pDurationType, pDuration) {
    switch (pDurationType) {
      case 1: {
        // NUM : 없음
        return `${pDuration}턴`;
      }
      case 2: {
        // INFINITE : 무한
        return "무한";
      }
      case 3: {
        // NOTHING : 없음
        return "없음";
      }
    }
  }


  function RefreshUI_BaseInfo() {
    let arr = [];

    arr.push(_get_listgroup_item_oneline_text_(`분류 : `, `${GameUtil.GetEnumUIName("Category", pCardData.Category)}`));
    arr.push(_get_listgroup_item_oneline_text_(`속성 : `, `${GameUtil.GetEnumUIName("SoulType", pCardData.SoulType)}`));
    arr.push(_get_listgroup_item_oneline_text_(`에피소드 : `, `${GameUtil.GetEnumUIName("Episode", pCardData.Episode)}`));
    arr.push(_get_listgroup_item_oneline_text_(`덱 수량제한 : `, `${GameUtil.GetEnumUIName("DeckLimit", pCardData.DeckLimit)}`));
    arr.push(_get_listgroup_item_oneline_text_(`희귀도 : `, `${GameUtil.GetEnumUIName("RareType", pCardData.RareType)}`));
    arr.push(_get_listgroup_item_oneline_text_(`종족타입 : `, `${GameUtil.GetEnumUIName("RaceType", pCardData.RaceType)}`));
    arr.push(_get_listgroup_item_oneline_text_(`소속타입 : `, `${GameUtil.GetEnumUIName("CompanyType", pCardData.CompanyType)}`));
    arr.push(_get_listgroup_item_oneline_text_(`기타타입 : `, `${GameUtil.GetEnumUIName("EtcType", pCardData.EtcType)}`));
    arr.push(_get_listgroup_item_oneline_text_(`구매등급 : `, `${GameUtil.GetEnumUIName("BuyGrade", pCardData.BuyGrade)}`));

    // 발매일
    function addPeriodToDateString(dateString) {
      return `${dateString.slice(0, 4)}년 ${dateString.slice(4, 6)}월 ${dateString.slice(6)}일`;
    }
    arr.push(_get_listgroup_item_oneline_text_(`발매일 : `, `${addPeriodToDateString(String(pCardData.ReleaseDate))}`));

    return (
      <ListGroup variant="flush">
        {Children.toArray(arr)}
      </ListGroup>
    );
  }

  function _refresh_ui_info_LAND_() {
    let arr = [];

    arr.push(_get_listgroup_item_oneline_text_(`생산무소울 : `, `${GameUtil.GetEnumUIName("MuSoul", pCardData.MuSoul)}`));
    arr.push(_get_listgroup_item_oneline_text_(`필요LP : `, `${GameUtil.GetEnumUIName("RequireLP", pCardData.RequireLP)}`));

    return (
      <ListGroup variant="flush">
        {Children.toArray(arr)}
      </ListGroup>
    );
  }
  function _refresh_ui_info_UNIT_() {
    let arr = [];

    // 능력치
    arr.push(_get_listgroup_item_oneline_content_(
      <Container className='mb-0 px-0' style={{ fontSize: "1.2rem" }}>
        <Row>
          <Col className='px-0'>
            <div className='ms-auto' style={{ width: "4.3rem", textAlign: "center" }}>
              <b>{`${pGlobalSetting.EmojiATK} `}</b><br />
              {`${_get_text_stat_(pCardData.ATKStatType, pCardData.ATK)}`}<br />
              <div style={{ fontSize: pGlobalSetting.fontSize }}>{`${_get_text_coin_(pCardData.ATKCoinType, pCardData.ATKCoin)}`}</div>
            </div>
          </Col>
          <Col className='px-0'>
            <div className='mx-auto' style={{ width: "4.3rem", textAlign: "center" }}>
              <b>{`${pGlobalSetting.EmojiDEF} `}</b><br />
              {`${_get_text_stat_(pCardData.DEFStatType, pCardData.DEF)}`}<br />
              <div style={{ fontSize: pGlobalSetting.fontSize }}>{`${_get_text_coin_(pCardData.DEFCoinType, pCardData.DEFCoin)}`}</div>
            </div>
          </Col>
          <Col className='px-0'>
            <div className='me-auto' style={{ width: "4.3rem", textAlign: "center" }}>
              <b>{`${pGlobalSetting.EmojiHP} `}</b><br />
              {`${_get_text_stat_(pCardData.HPStatType, pCardData.HP)}`}<br />
              <div style={{ fontSize: pGlobalSetting.fontSize }}>{`${_get_text_coin_(pCardData.HPCoinType, pCardData.HPCoin)}`}</div>

            </div>
          </Col>
        </Row>
      </Container>
    ));

    arr.push(_get_listgroup_item_oneline_text_(`유닛 능력 : `, `${GameUtil.GetEnumUIName("UnitType", pCardData.UnitType)}`));
    arr.push(_get_listgroup_item_oneline_text_(`랭크 : `, `${GameUtil.GetEnumUIName("RankType", pCardData.RankType)}`));
    // 스킬
    arr.push(_get_button_skill_(pCardData.Skill1, pCardData.Skill2));


    return (
      <ListGroup variant="flush">
        {Children.toArray(arr)}
      </ListGroup>
    );
  }
  function _refresh_ui_info_MAGIC_() {
    let arr = [];

    arr.push(_get_listgroup_item_oneline_text_(`발동시점 : `, `${GameUtil.GetEnumUIName("TurnType", pCardData.TurnType)}`));
    // 지속 턴
    arr.push(_get_listgroup_item_oneline_text_(`지속 턴 : `, `${_get_text_duration_(pCardData.DurationType, pCardData.Duration)}`));

    // 카드 텍스트
    let szTextBase = pCardData.TextBase;
    if (!szTextBase) {
      szTextBase = "없음";
    }
    arr.push(_get_listgroup_item_oneline_text_(`카드 텍스트 : `, `${szTextBase}`));

    return (
      <ListGroup variant="flush">
        {Children.toArray(arr)}
      </ListGroup>
    );
  }

  function _refresh_ui_info_ITEM_() {
    let arr = [];

    // 능력치
    arr.push(_get_listgroup_item_oneline_content_(
      <Container className='mb-0 px-0' style={{ fontSize: "1.2rem" }}>
        <Row>
          <Col className='px-0'>
            <div className='ms-auto' style={{ width: "4.3rem", textAlign: "center" }}>
              <b>{`${pGlobalSetting.EmojiATK} `}</b><br />
              {`${_get_text_stat_(pCardData.ATKStatType, pCardData.ATK)}`}<br />
            </div>
          </Col>
          <Col className='px-0'>
            <div className='mx-auto' style={{ width: "4.3rem", textAlign: "center" }}>
              <b>{`${pGlobalSetting.EmojiDEF} `}</b><br />
              {`${_get_text_stat_(pCardData.DEFStatType, pCardData.DEF)}`}<br />
            </div>
          </Col>
          <Col className='px-0'>
            <div className='me-auto' style={{ width: "4.3rem", textAlign: "center" }}>
              <b>{`${pGlobalSetting.EmojiHP} `}</b><br />
              {`${_get_text_stat_(pCardData.HPStatType, pCardData.HP)}`}<br />

            </div>
          </Col>
        </Row>
      </Container>
    ));

    arr.push(_get_listgroup_item_oneline_text_(`장착 능력 : `, `${GameUtil.GetEnumUIName("UnitType", pCardData.UnitType)}`));

    // 스킬
    arr.push(_get_button_skill_(pCardData.Skill1, pCardData.Skill2));

    // 코인 옵션
    arr.push(_get_listgroup_item_oneline_text_(`코인 증감 : `, `${GameUtil.GetEnumUIName("CoinOption", pCardData.CoinOption)}`));
    arr.push(_get_listgroup_item_oneline_text_(`코인 확률 : `, `${GameUtil.GetEnumUIName("ProbOption", pCardData.ProbOption)}`));

    // 카드 텍스트
    let szTextBase = pCardData.TextBase;
    if (!szTextBase) {
      szTextBase = "없음";
    }
    arr.push(_get_listgroup_item_oneline_text_(`카드 텍스트 : `, `${szTextBase}`));

    return (
      <ListGroup variant="flush">
        {Children.toArray(arr)}
      </ListGroup>
    );
  }
  function _refresh_ui_info_ENCHANT_() {
    let arr = [];

    arr.push(_get_listgroup_item_oneline_text_(`발동시점 : `, `${GameUtil.GetEnumUIName("TurnType", pCardData.TurnType)}`));
    // 지속 턴
    arr.push(_get_listgroup_item_oneline_text_(`지속 턴 : `, `${_get_text_duration_(pCardData.DurationType, pCardData.Duration)}`));
    // 
    arr.push(_get_listgroup_item_oneline_text_(`슬롯 위치 : `, `${GameUtil.GetEnumUIName("EnchantSlot", pCardData.EnchantSlot)}`));

    // 카드 텍스트
    let szTextBase = pCardData.TextBase;
    if (!szTextBase) {
      szTextBase = "없음";
    }
    arr.push(_get_listgroup_item_oneline_text_(`카드 텍스트 : `, `${szTextBase}`));

    return (
      <ListGroup variant="flush">
        {Children.toArray(arr)}
      </ListGroup>
    );
  }

  function RefreshUI_CategoryInfo() {
    let func = null;
    switch (pCardData.Category) {
      // 지형
      case 1: {
        func = _refresh_ui_info_LAND_;
        break;
      }
      // 유닛
      case 2: {
        func = _refresh_ui_info_UNIT_;
        break;
      }
      // 마법
      case 3: {
        func = _refresh_ui_info_MAGIC_;
        break;
      }
      // 아이템
      case 4: {
        func = _refresh_ui_info_ITEM_;
        break;
      }
      // 인챈트
      case 5: {
        func = _refresh_ui_info_ENCHANT_;
        break;
      }
      default: {
        return (<></>);
      }
    }

    return (
      <>
        {func()}
      </>
    );
  }

  function RefreshUI_TextBase() {

    let szTextBase = pCardData.TextBase;
    if (!szTextBase) {
      szTextBase = "없음";
    }

    // 부가설명
    let arr = [];
    let szTextExtra = pCardData.TextExtra;
    if (szTextExtra) {
      let arrText = szTextExtra.split("<br>");
      arrText.forEach((e) => {
        arr.push(<Card.Text className='mb-1' style={{ fontSize: pGlobalSetting.fontSize }}>{e}</Card.Text>);
      });
    }
    else {
      arr.push(<Card.Text className='mb-1' style={{ fontSize: pGlobalSetting.fontSize }}>없음</Card.Text>);
    }

    return (
      <Card.Body>
        <Card.Title><b>카드 텍스트</b></Card.Title>
        <Card.Text style={{ fontSize: pGlobalSetting.fontSize }}>{szTextBase}</Card.Text>
        <Card.Title><b>부가설명</b></Card.Title>
        {Children.toArray(arr)}
      </Card.Body>
    );
  }

  function RefreshUI_Report_Error() {

    function _on_click_login_() {
      pNavigate("/account/login");
    }

    async function _on_click_send_() {
      let szText = pRefReportContent.current.value;
      if (!szText) {
        return;
      }

      let min = 3; let max = 100;
      if (szText.length < min || szText.length > max) {
        alert(`최소 ${min}자, 최대 ${max}자까지 입력할 수 있습니다.`);
        return;
      }

      let pReport = {
        ReportType: 1,
        State: 1,
        UID: pCurUser.uid,
        UserName: pCurUser.displayName,
        CreateTime: Timestamp.now(),
        UpdateTime: Timestamp.now(),
        Title: pCardData.Name,
        Content: szText,

        // ReportType : 1 의 부가정보
        NumID: pCardData.NumID,
        CardName: pCardData.Name,
      };

      if (!pReport.UID) {
        alert(`유저 정보가 유효하지 않습니다. 재로그인 후 다시 시도해주세요.`);
        return;
      }

      if (!pReport.Title || pReport.Title.length > CONST._INT.REPORT_LIMIT_TITLE_LENGTH) {
        alert(`신고 제목은 최소 1자 최대 ${CONST._INT.REPORT_LIMIT_TITLE_LENGTH}자까지 입니다.(현재 ${pReport.Title.length}자)`);
        return;
      }
      if (!GameUtil.IsValidContent(pReport.Title)) {
        alert(`신고 제목에 유효하지 않은 특수문자가 있습니다.(${GameUtil.InvalidCharInContent})`);
        return;
      }

      if (!pReport.Content || pReport.Content.length > CONST._INT.REPORT_LIMIT_CONTENT_LENGTH) {
        alert(`신고 내용은 최소 1자 최대 ${CONST._INT.REPORT_LIMIT_CONTENT_LENGTH}자까지 입니다.(현재 ${pReport.Content.length}자)`);
        return;
      }
      if (!GameUtil.IsValidContent(pReport.Content)) {
        alert(`신고 내용에 유효하지 않은 특수문자가 있습니다.(${GameUtil.InvalidCharInContent})`);
        return;
      }

      try {
        let szDBName = GameUtil.GetDBReportCollection(pReport.UID);
        let [bResult, szDocID] = await pMaind.pFbCtrl.WriteDocToDB(szDBName, "", pReport);
        if (!bResult) {
          throw new Error("write-to-userdb");
        }

        // GMDB에 저장할때는 TargetDocID까지 저장
        pReport.TargetCollection = szDBName;
        pReport.TargetDocID = szDocID;

        szDBName = GameUtil.GetDBGMReportCollection();
        [bResult, szDocID] = await pMaind.pFbCtrl.WriteDocToDB(szDBName, "", pReport);
        if (!bResult) {
          throw new Error("write-to-gmdb");
        }

        alert("신고가 완료되었습니다.");
      }
      catch (error) {
        alert(`신고에 실패했습니다. (${error.message})`);
      }
    }

    if (!pCurUser) {
      return (
        <Card.Body>
          <Card.Text>로그인 상태에서만 오류 신고할 수 있습니다.</Card.Text>
          <Button variant="outline-primary" style={{ width: "100%", fontSize: pGlobalSetting.fontSize }} onClick={_on_click_login_}>로그인</Button>
        </Card.Body>
      );
    }

    return (
      <Card.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label style={{ fontSize: pGlobalSetting.fontSize }}>신고 내용</Form.Label>
            <Form.Control as="textarea" rows={3} ref={pRefReportContent} />
          </Form.Group>
          <Button variant="outline-primary" style={{ width: "100%", fontSize: pGlobalSetting.fontSize }} onClick={_on_click_send_}>전송</Button>
        </Form>
      </Card.Body>
    );
  }

  function RefreshUI_CardHeader() {
    let arr = [];
    [
      { nTab: EnumTabType.CATEGORY_INFO, szName: "분류별" },
      { nTab: EnumTabType.TEXT_BASE, szName: "텍스트" },
      { nTab: EnumTabType.BASE_INFO, szName: "기타" },
      { nTab: EnumTabType.REPORT_ERROR, szName: "오류 신고" },
    ].forEach((pData) => {
      let szVariant = nSelectedTabType === pData.nTab ? "primary" : "outline-primary";
      let szFontWeight = nSelectedTabType === pData.nTab ? "bold" : "normal";
      arr.push(
        <Button
          variant={szVariant}
          onClick={() => funcSetTabType(pData.nTab)}
          style={{ fontWeight: szFontWeight }}
        >
          {pData.szName}
        </Button>);
    });

    return (
      <Card.Header>
        <div className='mx-auto' style={{ maxWidth: "19rem" }}>
          <ButtonGroup>{Children.toArray(arr)}</ButtonGroup>
        </div>
      </Card.Header>
    );
  }

  function RefreshUI_CardBody() {
    switch (nSelectedTabType) {
      case EnumTabType.BASE_INFO: {
        return RefreshUI_BaseInfo();
      }

      case EnumTabType.CATEGORY_INFO: {
        return RefreshUI_CategoryInfo();
      }

      case EnumTabType.TEXT_BASE: {
        return RefreshUI_TextBase();
      }

      case EnumTabType.REPORT_ERROR: {
        return RefreshUI_Report_Error();
      }

      default: {
        return (<></>);
      }
    }
  }


  return (
    <>
      <Card className='mb-3'>
        {RefreshUI_CardHeader()}
        {RefreshUI_CardBody()}
      </Card>
    </>
  );
}

export default ComCardDataView;