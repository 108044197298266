import { useNavigate, useParams, useSearchParams } from "react-router-dom";

// bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, ButtonGroup, Card, Container, Dropdown, DropdownButton, Form, InputGroup, Modal } from "react-bootstrap";

import { Children, useEffect, useRef, useState } from "react";
import { CONST, pSheetColumn, pSheetEnum } from "../mod/CardClientSheetData";
import GameUtil from "../mod/GameUtil";
import Util from "../mod/Util";
import { ComUtilFloatButton, CUtilFloatButtonParam, EnumUtilFloatButtonType } from "../mycom/ComUtilFloatButton";
import { CCardDetailModalButtonParam, ComCardDetailModal } from "../mycom/ComCardDetailModal";
import { ComLibraryPostView } from "../mycom/ComLibraryPostView";

const EnumUIEventID = {
  ON_CLICK_LOAD_MORE: 102,
  ON_CLICK_SEARCH: 103,

  // POST
  POST_ON_CLICK_DELETE: 202,
  POST_ON_SHOW_DETAIL: 203,

  // MODAL
  MODAL_ON_CLOSE: 301,
};
Object.freeze(EnumUIEventID);

const ONEPAGE_DATA_LIMIT = 10;

function PageLibraryBoard({
  pMaind
}) {
  // query param
  const [pURLSearchParams, funcSetURLSearchParams] = useSearchParams();

  // 
  let pGlobalSetting = {
    postCardSize: "40rem",

    fontSizeS: "0.8rem",
    fontColorS: "grey",

    fontSizeL: "1.2rem",
    fontColorL: "black",
  };

  // 
  const pNavigate = useNavigate();

  // 
  const [pDBResult, funcDBResult] = useState({
    TotalCount: 0,
    Datas: [],
    pOrderValueEnd: null,
    bLastFlag: false,

    Clear: function () {
      this.TotalCount = 0;
      this.Datas = [];
      this.pOrderValueEnd = null;
      this.bLastFlag = false;
    },
  });

  // DB 요청 처리 번호표
  let rDBRequestControl = useRef({
    pMap: new Map(),
    IDCount: 1,

    Register: function () {
      let nID = this.IDCount;
      this.IDCount++;

      if (!this.pMap.has(nID)) {
        this.pMap.set(nID, nID);
      }
      return nID;
    },
    UnRegister: function (nID) {
      if (this.pMap.has(nID)) {
        this.pMap.delete(nID);
      }
    },
    Clear: function () {
      this.pMap.clear();
    }
  });

  let [pEventListeners, funcSetEventListener] = useState({
    pMap: new Map(),
    IDCount: 1,

    Register: function (cb) {
      let nID = this.IDCount;
      this.IDCount++;

      if (!this.pMap.has(nID)) {
        this.pMap.set(nID, cb);
        console.log(`Register Listener : ${nID}`);
      }
      return nID;
    },
    UnRegister: function (nID) {
      if (this.pMap.has(nID)) {
        this.pMap.delete(nID);
        console.log(`!!UnRegister Listener : ${nID}`);
      }
    },
  });

  // query param 대응
  useEffect(() => {
    function _get_condition_from_query_() {
      console.log(`_get_condition_from_query_ : ${pURLSearchParams.toString()}`);

      let arrRetVal = [];

      pURLSearchParams.forEach((v, k) => {
        if (!pSheetColumn.DBColumnLibrary.mapQuery.has(k)) {
          return;
        }

        let pDataDBColumn = pSheetColumn.DBColumnLibrary.mapQuery.get(k);
        if (!pDataDBColumn) {
          return;
        }

        let pValue = v;
        if (pDataDBColumn.DataType !== "string") {
          pValue = parseInt(pValue);
        }

        arrRetVal.push({ pDataDBColumn: pDataDBColumn, Value: pValue });
      })

      return arrRetVal;
    }

    funcSetSearchCondition(_get_condition_from_query_());
  }, [pURLSearchParams]);

  const [arrSearchCondition, funcSetSearchCondition] = useState([]);
  useEffect(() => {

    // 검색 조건이 바뀌었다!

    (async function () {
      // 기존 DBResult 초기화
      pDBResult.Clear();

      // 검색 다시 실행
      await ProcDBSearch();
    })();

    return () => {
      // Unmount 시 처리
      // 처리 번호표 전부 삭제
      rDBRequestControl.current.Clear();
    };

  }, [arrSearchCondition]);

  async function ProcDBSearch() {
    // arrSearchCondition 을 기반으로 검색을 진행하고, 결과를 "추가"한다
    // 이 전단계에서 arrSearchCondition 의 세팅이 끝났어야 한다.

    let arrQueries = [];

    // 반드시 CreateTime 정렬을 맨앞에 둘것. 순서 꼬이면 색인 다시해야 할수도 있다.
    let szColumnNameOrder = "CreateTime";
    arrQueries.push(pMaind.pFbCtrl.CreateSearchQuery(szColumnNameOrder, CONST._DB_PROCTYPE.ORDER_BY, "desc"));

    // Param 으로 넘어온 조건값 추가
    arrSearchCondition.forEach((e) => {
      let pRetVal = pMaind.pFbCtrl.CreateSearchQuery(e.pDataDBColumn.DBColumnName, e.pDataDBColumn.DBProcType, e.Value);
      if (!pRetVal) {
        return;
      }

      arrQueries.push(pRetVal);
    });

    // DB 요청 전에 미리 결과 처리 번호표 등록
    let nReqID = rDBRequestControl.current.Register();

    let pSearchResult = await pMaind.pFbCtrl.GetSearchedDocs(GameUtil.GetDBLibraryCollection(),
      arrQueries,
      ONEPAGE_DATA_LIMIT,
      pDBResult.TotalCount,
      szColumnNameOrder,
      pDBResult.pOrderValueEnd);

    // DB 요청 결과 이후에도 요청 관리에 ID가 있으면 실행
    if (!rDBRequestControl.current.pMap.has(nReqID)) {
      return;
    }

    // 현재 데이터의 뒤에 추가한다
    pDBResult.Datas.push(...pSearchResult.arrResult);
    pDBResult.TotalCount = pSearchResult.TotalCount;
    pDBResult.pOrderValueEnd = pSearchResult.pOrderValueEnd;
    pDBResult.bLastFlag = pSearchResult.arrResult.length <= 0;
    funcDBResult({ ...pDBResult });
  }


  // Event Binding
  async function OnDispatchEvent(nEventID, pEventParam) {
    // main
    switch (nEventID) {
      // 
      case EnumUIEventID.ON_CLICK_LOAD_MORE: {
        ProcDBSearch();
        break;
      }

      case EnumUIEventID.ON_CLICK_SEARCH: {
        let arrInputSearch = [...pEventParam.arrConditions];
        let pNewParams = new URLSearchParams();
        arrInputSearch.forEach((e) => {
          pNewParams.set(e.pDataDBColumn.Query, String(e.Value));
        });

        funcSetURLSearchParams(pNewParams);
        break;
      }

      case EnumUIEventID.POST_ON_CLICK_DELETE: {
        (async function () {
          let pPostData = pEventParam.pPostData;
          let szCollection = GameUtil.GetDBLibraryCollection();
          let szDocID = pPostData.id;
          let szTitle = pPostData.Title;

          let isConfirm = window.confirm(`[${szTitle}] 포스트를 삭제하시겠습니까?`);
          if (!isConfirm) {
            return;
          }

          try {
            await pMaind.pFbCtrl.DeleteDocSimple(szCollection, szDocID);

            // 라이브러리 문서 삭제 후처리
            // 관련 이미지들 삭제
            if (pPostData.Images) {
              for (let i = 0; i < pPostData.Images.length; ++i) {
                let pLibImage = pPostData.Images[i];
                if (!pLibImage) {
                  continue;
                }

                let szPath = GameUtil.GetLibraryImagePath(szDocID, pLibImage.FileName);
                let [bResult2, szMessage] = await pMaind.pFbCtrl.DeleteFileInStorage(szPath);
                if (!bResult2) {
                  throw new Error(szMessage);
                }
              }
            }

            alert(`삭제 완료했습니다.`);

            // 삭제 완료. 새로고침
            window.location.reload();
          }
          catch (error) {
            alert(`삭제 실패했습니다. (${error.message})`);
            console.log(error);
          }
        })();

        break;
      }

      default: {
        break;
      }
    }

    pEventListeners.pMap.forEach((pListener, _k) => {
      if (pListener) {
        pListener(nEventID, pEventParam);
      }
    });
  }

  const arrUtilButtonParam = [
    new CUtilFloatButtonParam(EnumUtilFloatButtonType.SCROLL_BOTTOM),
    new CUtilFloatButtonParam(EnumUtilFloatButtonType.SCROLL_TOP),
  ];

  return (
    <>
      <Row className="my-3">
        <Col>
          <h2 className="text-center">라이브러리</h2>
        </Col>
      </Row>
      <UIPostSearch funcDispatch={OnDispatchEvent}></UIPostSearch>
      <UISearchCondition arrConditions={arrSearchCondition}></UISearchCondition>
      <UILibraryPostSheet pMaind={pMaind} pGlobalSetting={pGlobalSetting} pDBResult={pDBResult} funcDispatch={OnDispatchEvent}></UILibraryPostSheet>

      {/*  */}
      <ComUtilFloatButton arrParams={arrUtilButtonParam}></ComUtilFloatButton>
    </>
  );
}

function UIPostSearch({
  funcDispatch
}) {

  // key : column KIND, value : enum KIND
  let [pMapOption, funcSetMapOption] = useState(new Map());
  let [szUserName, funcSetUserName] = useState("");

  // 테이블 안만들었다. 나중에 더 추가할일 있으면 만들어라.
  let PostSearch = {};
  PostSearch.arr = [{ KIND: 1, TYPE: "LibraryCategory", UIPriority: 10, DBColumnKIND: 101 }];
  PostSearch.mapKIND = new Map();
  PostSearch.arr.forEach((e) => PostSearch.mapKIND.set(e.KIND, e));

  function ExecSearch() {
    let arrConditions = [];

    // 일반 옵션 선택 체크
    pMapOption.forEach((nEnumKIND, nColumnKIND) => {
      let pDataPostSearch = PostSearch.mapKIND.get(nColumnKIND);
      if (!pDataPostSearch) {
        return;
      }

      let pDataDBColumn = pSheetColumn.DBColumnLibrary.mapKIND.get(pDataPostSearch.DBColumnKIND);
      if (!pDataDBColumn) {
        return;
      }

      // 정보 추가 (DB 컬럼 + 선택값)
      arrConditions.push({ pDataDBColumn: pDataDBColumn, Value: nEnumKIND });
    });

    // 검색 진행하기 전에 UI 리셋
    funcSetUserName("");
    funcSetMapOption(new Map());

    // 검색 진행
    funcDispatch(EnumUIEventID.ON_CLICK_SEARCH, { arrConditions: arrConditions });
  }

  function RefreshUI_Options() {

    function __refresh_ui_dropdowns__() {
      let arrRetVal = [];


      let arrColumnsCopy = [...PostSearch.arr];
      arrColumnsCopy = arrColumnsCopy.sort((a, b) => a.UIPriority - b.UIPriority);
      arrColumnsCopy.forEach((pColumnData) => {
        if (!pSheetColumn.DBColumnLibrary.mapKIND.has(pColumnData.DBColumnKIND)) {
          return;
        }

        let pDataDBColumn = pSheetColumn.DBColumnLibrary.mapKIND.get(pColumnData.DBColumnKIND);
        let nColumnKIND = pColumnData.KIND;
        let szColumnDataType = pDataDBColumn.DataType;
        let szColumnName = pDataDBColumn.UIName; // 컬럼 타입 분류 자체의 이름

        // 선택된게 없을때의 기본값
        let bIsSelected = false;
        let szTitle = szColumnName; // 컬럼 타입 분류 자체의 이름

        if (pMapOption.has(nColumnKIND)) {
          let nSelectedEnumKIND = pMapOption.get(nColumnKIND);
          let pSelectedEnumData = pSheetEnum[`${szColumnDataType}`].mapKIND.get(nSelectedEnumKIND);
          if (pSelectedEnumData) {
            bIsSelected = true;
            szTitle = `${szColumnName} (${pSelectedEnumData.UIName})`;
          }
          else {
            // TODO : 선택되긴 했는데 Enum 테이블에 없다...어찌해야 되나
          }
        }

        function __create_dropdown_items__() {

          let arrEnumTable = pSheetEnum[`${szColumnDataType}`].arr;
          let arrEnums = [...arrEnumTable];
          arrEnums.sort((a, b) => a.UIPriority - b.UIPriority);

          let arr = [];
          arr.push(<Dropdown.Item eventKey="-1">{szColumnName}</Dropdown.Item>);
          arr.push(<Dropdown.Divider />);
          arrEnums.forEach((_e) => {
            arr.push(<Dropdown.Item eventKey={_e.KIND}>{_e.UIName}</Dropdown.Item>);
          });

          return Children.toArray(arr);
        }

        function __on_select__(eventKey) {
          // eventKey = Enum KIND
          console.log(`eventKey : ${eventKey}`);

          let nEnumKIND = parseInt(eventKey);
          if (nEnumKIND === -1) {
            // 옵션 삭제
            if (pMapOption.has(nColumnKIND)) {
              pMapOption.delete(nColumnKIND);
              funcSetMapOption(new Map(pMapOption));
            }
            else {
              // 이미 선택 안했으면 처리 필요없음
            }
          }
          else {
            let mapEnumKIND = pSheetEnum[`${szColumnDataType}`].mapKIND;
            let pSelectedEnumData = mapEnumKIND.get(nEnumKIND);
            if (!pSelectedEnumData) {
              console.error(`__on_select__ : ${szColumnDataType} not contains ${nEnumKIND}`);
              return;
            }

            // 옵션 추가 혹은 수정
            pMapOption.set(nColumnKIND, nEnumKIND);
            funcSetMapOption(new Map(pMapOption));
          }

          ExecSearch();
        }

        arrRetVal.push(
          <Col xs={12} sm={4} className="px-0 mb-1">
            <DropdownButton
              className='w-100 h-100 mb-0'
              as={ButtonGroup}
              id={`dropdown-button-drop`}
              size="sm"
              variant={bIsSelected ? "primary" : "outline-primary"}
              title={szTitle}
              onSelect={__on_select__}
            >
              {__create_dropdown_items__()}
            </DropdownButton>
          </Col>
        );
      });

      return (Children.toArray(arrRetVal));
    }

    return (
      <>
        {__refresh_ui_dropdowns__()}
      </>
    );
  }

  return (
    <Container>
      <Row xs={1} sm={2} className="px-1 mb-1">
        {RefreshUI_Options()}
      </Row>
    </Container>
  );
}

function UISearchCondition({
  arrConditions
}) {

  let arr = [];
  if (arrConditions) {
    arrConditions.forEach((e) => {
      let pDataDBColumn = e.pDataDBColumn;
      let pValue = e.Value;

      let szUIName = pDataDBColumn.UIName;
      let szValue = String(pValue);
      if (pDataDBColumn.UIShowValue) {
        if (pDataDBColumn.DataType !== "string") {
          pValue = parseInt(pValue);

          if (pDataDBColumn.DataType !== "int") {
            let pEnumInfo = pSheetEnum[`${pDataDBColumn.DataType}`].mapKIND.get(pValue);
            if (pEnumInfo) {
              szValue = pEnumInfo.UIName;
            }
          }
        }
      }

      let szCondition = `${szUIName}`;
      if (pDataDBColumn.UIShowValue) {
        szCondition = `${szUIName} (${szValue})`;
      }

      arr.push(
        <Button variant='primary' className='me-1 mb-1 px-2 py-1' style={{ fontSize: "0.8rem" }}>
          {szCondition}
        </Button>
      );
    });
  }

  if (arr.length <= 0) {
    arr.push(<Card.Text>조건 없음</Card.Text>);
  }

  return (
    <Card className='mb-3'>
      <Card.Header>현재 검색 조건</Card.Header>
      <Card.Body className='px-2 py-2'>
        {Children.toArray(arr)}
      </Card.Body>
    </Card>
  );
}


function UILibraryPostSheet({
  pMaind,
  pGlobalSetting,
  pDBResult,
  funcDispatch
}) {

  let [pDataPostModal, funcDataPostModal] = useState({
    show: false,
    pTargetPostData: null,
  });
  function OnHidePostModal() {
    pDataPostModal.show = false;
    pDataPostModal.pTargetPostData = null;
    funcDataPostModal({ ...pDataPostModal });
  }

  function OnClickDetail(pPostData) {
    pDataPostModal.show = true;
    pDataPostModal.pTargetPostData = pPostData;
    funcDataPostModal({ ...pDataPostModal });
  }

  function OnClickLoadMore() {
    funcDispatch(EnumUIEventID.ON_CLICK_LOAD_MORE);
  }

  function RefreshUI_TotalCount() {
    return (
      <p className='w-100 text-end' style={{ fontSize: "0.8rem" }}>{`총 ${pDBResult.TotalCount}개의 데이터 (${pDBResult.Datas.length}개 표시중)`}</p>
    );
  }

  function RefreshUI_Sheet() {

    let arrRetval = [];

    if (pDBResult.Datas) {
      pDBResult.Datas.forEach((pPostData) => {
        arrRetval.push(
          <UIPostSmallCard
            pMaind={pMaind}
            pPostData={pPostData}
            pGlobalSetting={pGlobalSetting}
            funcOnClickDetail={OnClickDetail}
          >
          </UIPostSmallCard>
        );
      });
    }

    return (
      Children.toArray(arrRetval)
    );
  }

  function RefreshUI_ButtonMore() {
    if (pDBResult.bLastFlag) {
      return (
        <div className="mx-auto">
          <Button variant='secondary my-3' className='w-100' size='lg'>{`게시물을 모두 로드하였습니다.`}</Button>
        </div>
      );
    }

    return (
      <div className="mx-auto">
        <Button variant='outline-primary my-3' className='w-100' size='lg' onClick={OnClickLoadMore}>{`게시물 더 불러오기`}</Button>
      </div>
    );
  }

  return (
    <>
      {RefreshUI_TotalCount()}
      <div style={{ maxWidth: "30rem", margin: "auto" }}>
        {RefreshUI_Sheet()}
        {RefreshUI_ButtonMore()}
      </div>

      {/* modal */}
      <UIPostDetailModal
        pMaind={pMaind}
        pPostData={pDataPostModal.pTargetPostData}
        bShow={pDataPostModal.show}
        funcOnHide={OnHidePostModal}
        funcDispatch={funcDispatch}
      >
      </UIPostDetailModal>
    </>
  );
}

function UIPostSmallCard({
  pMaind,
  pPostData,
  pGlobalSetting,
  funcOnClickDetail,
}) {

  let [nShowImageIndex, funcShowImageIndex] = useState(0);

  function OnClickDetail() {
    funcOnClickDetail(pPostData);
  }

  function OnClickPrevImage() {
    funcShowImageIndex(nShowImageIndex - 1);
  }

  function OnClickNextImage() {
    funcShowImageIndex(nShowImageIndex + 1);
  }

  function OnClickImage(szUrl) {
    // 넘기기 버튼의 UX에 해악인거 같다.
    // window.open(szUrl);
  }

  function __ui_sheet_item_image__(pPostData) {
    if (!pPostData.Images || pPostData.Images.length <= 0) {
      return (
        <></>
      );
    }

    function ___show_left_button__() {
      if (nShowImageIndex - 1 < 0) {
        return (<></>);
      }

      return (
        <Button variant="primary" style={{ position: "absolute", left: 0, top: "50%", transform: "translateY(-50%)", opacity: 0.5 }} onClick={OnClickPrevImage}>
          {`◀`}
        </Button>
      );
    }

    function ___show_right_button__() {
      if (nShowImageIndex + 1 >= pPostData.Images.length) {
        return (<></>);
      }

      return (
        <Button variant="primary" style={{ position: "absolute", right: 0, top: "50%", transform: "translateY(-50%)", opacity: 0.5 }} onClick={OnClickNextImage}>
          {`▶`}
        </Button>
      );
    }

    let szUrl = GameUtil.GetLibraryImagePath(pPostData.id, pPostData.Images[nShowImageIndex].FileName);
    szUrl = pMaind.pFbCtrl.GetStorageURL(szUrl);

    return (
      <>
        <div style={{ position: "relative" }}>
          {___show_left_button__()}
          {___show_right_button__()}
          <Card.Text className="mb-0" style={{ position: "absolute", right: "3%", top: "97%", transform: "translateY(-97%)", color: "white", backgroundColor: "black", fontSize: "0.9rem" }}>
            {`${nShowImageIndex + 1}/${pPostData.Images.length}`}
          </Card.Text>
          <Card.Img
            src={szUrl}
            onClick={() => OnClickImage(szUrl)}
            style={{ maxWidth: "30rem", height: "25rem", objectFit: "contain", backgroundColor: "black" }}
          >
          </Card.Img>
        </div>
      </>
    );
  }

  function __ui_sheet_item_title__(pPostData) {
    let szCategory = "불명";
    if (pSheetEnum[`LibraryCategory`].mapKIND.has(pPostData.Category)) {
      let pEnum = pSheetEnum[`LibraryCategory`].mapKIND.get(pPostData.Category);
      if (pEnum) {
        szCategory = pEnum.UIName;
      }
    }

    let szTitle = pPostData.Title;

    let pCreateTime = pPostData.CreateTime.toDate();

    return (
      <>
        <Card.Title
          className="my-1"
          style={{ fontSize: pGlobalSetting.fontSizeL, color: pGlobalSetting.fontColorL, fontWeight: "bold" }}
        >
          {`[${szCategory}] ${szTitle} `}
        </Card.Title>
        <Card.Text className="mb-1" style={{ color: pGlobalSetting.fontColorS, fontSize: pGlobalSetting.fontSizeS }}>
          {`${Util.GetTimeDiffString(pCreateTime)}`}
        </Card.Text>
      </>
    );
  }

  function __ui_sheet_item_content__(pPostData) {
    return (
      <Card.Text className="mb-1" style={{ fontSize: pGlobalSetting.fontSizeS, maxHeight: "5rem", overflow: "hidden" }}>
        {pPostData.Content}
      </Card.Text>
    );
  }

  return (
    <Card className="px-0 mb-3" style={{ maxWidth: "30rem" }}>
      <Card.Header>
        {__ui_sheet_item_title__(pPostData)}
      </Card.Header>
      <Card.Body className="px-1 py-1">
        {__ui_sheet_item_image__(pPostData)}
        {__ui_sheet_item_content__(pPostData)}

        <Button variant="outline-primary" className="py-1 w-100" style={{ fontSize: pGlobalSetting.fontSizeS }} onClick={OnClickDetail}>상세 보기</Button>
      </Card.Body>
    </Card>
  );
}

function UIPostDetailModal({
  pMaind,
  pPostData,
  bShow,
  funcOnHide,
  funcDispatch
}) {

  function OnClickDelete() {
    funcDispatch(EnumUIEventID.POST_ON_CLICK_DELETE, { pPostData: pPostData });
  }


  function RefreshUI_Buttons() {
    let arrRetVal = [];

    arrRetVal.push(
      <Button variant="danger" className='w-100 mb-1' onClick={funcOnHide}>닫기</Button>
    );

    return (
      Children.toArray(arrRetVal)
    );
  }

  return (
    <Modal
      show={bShow}
      animation={false}
      onHide={funcOnHide}
      size="lg"
    >
      <Modal.Header closeButton className='px-2 py-1'>
      </Modal.Header>

      <Modal.Body className="px-0">
        <div className="mb-3 mx-auto">
          <ComLibraryPostView
            pMaind={pMaind}
            pPostData={pPostData}
            bIsLimitView={true}
            cbOnDelete={OnClickDelete}
            cbOnList={funcOnHide}
          >
          </ComLibraryPostView>
        </div>

        {RefreshUI_Buttons()}
      </Modal.Body>
    </Modal>
  );
}

export default PageLibraryBoard;