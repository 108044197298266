import { Children, useEffect, useRef, useState } from "react";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Button, Card, Container, Dropdown, DropdownButton, Modal, OffcanvasBody } from "react-bootstrap";
import ButtonGroup from 'react-bootstrap/ButtonGroup';


import { pSheetEnum, pSheetColumn, CONST } from "../mod/CardClientSheetData";
import Util from "../mod/Util";

// enum
const EnumUIEventID = {
  ON_CLICK_OK: 101,
  ON_CLICK_RESET: 102,
  ON_CLICK_CLOSE: 103,

  EX_COLLECT_ALL_CONDITIONS: 1001,
};


function ComExtraSearchModal({
  pMaind,
  bShow,
  funcSetShow,
  cbOnOK,
  arrDefaultConditions,
}) {

  // event listener
  const rCtrlEventListeners = useRef({
    pMap: new Map(),
    IDCount: 1,

    Register: function (cb) {
      let nID = this.IDCount;
      this.IDCount++;

      if (!this.pMap.has(nID)) {
        this.pMap.set(nID, cb);
        console.log(`Register Listener : ${nID}`);
      }
      return nID;
    },
    UnRegister: function (nID) {
      if (this.pMap.has(nID)) {
        this.pMap.delete(nID);
        console.log(`!!UnRegister Listener : ${nID}`);
      }
    },
  });

  // 
  async function OnDispatchEvent(nEventID, pEventParam) {
    switch (nEventID) {
      case EnumUIEventID.ON_CLICK_OK: {
        funcSetShow(false);
        cbOnOK(pEventParam.arrConditions);
        break;
      }

      case EnumUIEventID.ON_CLICK_RESET: {
        break;
      }

      case EnumUIEventID.ON_CLICK_CLOSE: {
        funcSetShow(false);
        break;
      }
    }

    rCtrlEventListeners.current.pMap.forEach((pListener, _k) => {
      if (pListener) {
        pListener(nEventID, pEventParam);
      }
    });
  }

  function OnHide() {
    OnDispatchEvent(EnumUIEventID.ON_CLICK_CLOSE);
  }

  function RefreshUI_Buttons() {
    function _on_click_search_() {
      let arrConditions = [];
      OnDispatchEvent(EnumUIEventID.EX_COLLECT_ALL_CONDITIONS, { arrConditions: arrConditions });
      OnDispatchEvent(EnumUIEventID.ON_CLICK_OK, { arrConditions: arrConditions });
    }

    function _on_click_reset_() {
      OnDispatchEvent(EnumUIEventID.ON_CLICK_RESET);
    }

    function _on_click_close_() {
      OnDispatchEvent(EnumUIEventID.ON_CLICK_CLOSE);
    }

    return (
      <>
        <Button variant='outline-primary' className='w-100 mb-1' style={{ fontSize: "1.5rem", fontWeight: "bold" }} onClick={_on_click_search_}>검색</Button>
        <Button variant='outline-primary' className='w-100 mb-1' onClick={_on_click_reset_}>전체 초기화</Button>
        <Button variant='danger' className='w-100 mb-1' onClick={_on_click_close_}>닫기</Button>
      </>
    );
  }

  function RefreshUI_SearchGroupEtc() {
    let arrRetVal = [];
    let arrGroupKIND = [];
    pSheetColumn.ExtraSearchGroup.arr.forEach((pGroup) => {
      arrGroupKIND.push(pGroup.KIND);
    });
    arrGroupKIND.sort((a, b) => a - b);
    arrGroupKIND.forEach((nGroupKIND) => {
      let arrExceptKIND = [];
      if (nGroupKIND === 1) {
        arrExceptKIND = [1];
      }

      arrRetVal.push(
        <UIExtraSearchGroup
          funcDispatch={OnDispatchEvent}
          rCtrlEventListeners={rCtrlEventListeners}
          nSearchGroup={nGroupKIND}
          arrExceptKIND={arrExceptKIND}
          arrDefaultConditions={arrDefaultConditions}
        >
        </UIExtraSearchGroup>);
    });

    return (Children.toArray(arrRetVal));
  }

  return (
    <Modal
      show={bShow}
      animation={false}
      size='xl'
      onHide={OnHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>검색 조건</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="overflow-scroll mb-3" style={{ height: "60vh" }}>
          <UIExtraSearchGroupDefault
            funcDispatch={OnDispatchEvent}
            rCtrlEventListeners={rCtrlEventListeners}
            arrDefaultConditions={arrDefaultConditions}
          >
          </UIExtraSearchGroupDefault>
          {RefreshUI_SearchGroupEtc()}
        </div>

        {RefreshUI_Buttons()}
      </Modal.Body>
    </Modal>
  );
}

const UISearchUIHelper = {
  // 공용 처리
  OnSelectDropDown: function (eventKey, nColumnKIND, szColumnTYPE, pConditions, funcSetConditions) {
    // eventKey = Enum KIND
    console.log(`eventKey : ${eventKey}`);

    let nEnumKIND = parseInt(eventKey);
    if (nEnumKIND === -1) {
      // 옵션 삭제
      if (pConditions.pMap.has(nColumnKIND)) {
        pConditions.pMap.delete(nColumnKIND);
        funcSetConditions({ ...pConditions });
      }
      else {
        // 이미 선택 안했으면 처리 필요없음
      }
    }
    else {
      let mapEnumKIND = pSheetEnum[`${szColumnTYPE}`].mapKIND;
      let pSelectedEnumData = mapEnumKIND.get(nEnumKIND);
      if (!pSelectedEnumData) {
        console.error(`OnSelectDropDown : ${szColumnTYPE} not contains ${nEnumKIND}`);
        return;
      }

      // 옵션 추가 혹은 수정
      pConditions.pMap.set(nColumnKIND, { nEnumKIND: nEnumKIND, UIName: pSelectedEnumData.UIName });
      funcSetConditions({ ...pConditions });
    }
  },

  CreateDropDownItems: function (szColumnTYPE, szColumnName, szTitle) {
    let arrEnumTable = pSheetEnum[`${szColumnTYPE}`].arr;
    let arrCopy = [...arrEnumTable];
    arrCopy.sort((a, b) => a.UIPriority - b.UIPriority);

    let arrRetVal = [];
    arrRetVal.push(<Dropdown.Item eventKey="-1">{szColumnName}</Dropdown.Item>);
    arrRetVal.push(<Dropdown.Divider />);
    arrCopy.forEach((_e) => {
      arrRetVal.push(<Dropdown.Item eventKey={_e.KIND}>{_e.UIName}</Dropdown.Item>);
    });

    return arrRetVal;
  },

  CreateEtcColumnDropDown: function (nSearchGroup, pConditions, funcSetConditions, arrExceptKIND) {
    let arrRetVal = [];
    const arrColumns = [];
    pSheetColumn.ExtraSearch.arr.forEach((e) => {
      if (e.Group !== nSearchGroup) {
        return;
      }
      arrColumns.push(e);
    });

    arrColumns.sort((a, b) => a.UIPriority - b.UIPriority);
    arrColumns.forEach((pColumnData) => {
      // 예외처리된 KIND는 뺀다
      if (arrExceptKIND.includes(pColumnData.KIND)) {
        return;
      }

      if (!pSheetColumn.DBColumn.mapKIND.has(pColumnData.DBColumnKIND)) {
        return;
      }

      let pDataDBColumn = pSheetColumn.DBColumn.mapKIND.get(pColumnData.DBColumnKIND);
      let nColumnKIND = pColumnData.KIND;
      let szColumnDataType = pDataDBColumn.DataType;
      let szColumnName = pDataDBColumn.UIName; // 컬럼 타입 분류 자체의 이름

      // 선택된게 없을때의 기본값
      let bIsSelected = false;
      let szTitle = szColumnName; // 컬럼 타입 분류 자체의 이름

      if (pConditions.pMap.has(nColumnKIND)) {
        let pSelectedData = pConditions.pMap.get(nColumnKIND);
        bIsSelected = true;
        szTitle = `${szColumnName} (${pSelectedData.UIName})`;
      }

      arrRetVal.push(
        <Col>
          <DropdownButton
            className='w-100 mb-1'
            as={ButtonGroup}
            id={`dropdown-button-drop`}
            size="sm"
            variant={bIsSelected ? "primary" : "outline-primary"}
            title={szTitle}
            onSelect={(eventKey) => this.OnSelectDropDown(eventKey, nColumnKIND, szColumnDataType, pConditions, funcSetConditions)}
          >
            {Children.toArray(this.CreateDropDownItems(szColumnDataType, szColumnName, szTitle))}
          </DropdownButton>
        </Col>
      );
    });

    return (
      <Container>
        <Row className="mb-2" xs={1} sm={2}>
          {Children.toArray(arrRetVal)}
        </Row>
      </Container>
    );
  }
};

function UIExtraSearchGroupDefault({
  funcDispatch,
  rCtrlEventListeners,
  arrDefaultConditions,
}) {

  let rInput = useRef();

  useEffect(() => {
    const nID = rCtrlEventListeners.current.Register((nEventID, pEventParam) => {
      switch (nEventID) {
        case EnumUIEventID.ON_CLICK_RESET: {
          rInput.current.value = "";
          break;
        }

        case EnumUIEventID.EX_COLLECT_ALL_CONDITIONS: {
          let arrConditions = pEventParam.arrConditions;

          // 이름 검색 체크
          // 공백을 제외시킨다 (공백으로 분리(split) + join으로 다시 합치기)
          (() => {
            let szInputName = rInput.current.value.split(' ').join('');
            if (!szInputName) {
              return;
            }

            let pDataSearch = pSheetColumn.ExtraSearch.mapKIND.get(1);
            if (!pDataSearch) {
              return;
            }

            let pDataDBColumn;
            let [isDoubleQuotaMark, szResultDoubleQuota] = Util.GetDoubleQuotaMarkText(szInputName);
            szInputName = szResultDoubleQuota;

            if (isDoubleQuotaMark) {
              // 일치 로 검색
              pDataDBColumn = pSheetColumn.DBColumn.mapKIND.get(CONST._INT.DBCOLUMN_KIND_NAME_EQUAL);
            }
            else {
              // 포함으로 검색
              // 1글자일 경우 : 일치 로 검색
              // 2글자 이상일 경우 : 포함 으로 검색

              if (szInputName.length <= 1) {
                pDataDBColumn = pSheetColumn.DBColumn.mapKIND.get(CONST._INT.DBCOLUMN_KIND_NAME_EQUAL);
              }
              else {
                pDataDBColumn = pSheetColumn.DBColumn.mapKIND.get(CONST._INT.DBCOLUMN_KIND_NAME_CONTAINS);
              }
            }

            if (!pDataDBColumn) {
              return;
            }

            // 정보 추가 (DB 컬럼 + 입력값)
            arrConditions.push({ pDataDBColumn: pDataDBColumn, Value: szInputName });
          })();
          break;
        }

        default: {
          break;
        }
      }
    });

    return () => {
      rCtrlEventListeners.current.UnRegister(nID);
    };
  }, []);

  useEffect(() => {

    if (arrDefaultConditions) {
      for (let i = 0; i < arrDefaultConditions.length; ++i) {
        let e = arrDefaultConditions[i];
        if (e.pDataDBColumn.KIND !== CONST._INT.DBCOLUMN_KIND_NAME_EQUAL &&
          e.pDataDBColumn.KIND !== CONST._INT.DBCOLUMN_KIND_NAME_CONTAINS) {
          continue;
        }

        rInput.current.value = e.Value;
        break;
      }
    }

    return () => { };
  }, [arrDefaultConditions]);

  return (
    <Card className="mb-2">
      <Card.Header>카드명</Card.Header>
      <Card.Body>
        <InputGroup className="mb-3">
          <Form.Control ref={rInput} placeholder="카드명" aria-label="inputCardName" />
        </InputGroup>

        <Card.Text style={{ color: "grey", fontSize: "0.8rem" }}>
          {`쌍따옴표(")로 카드명을 감싸서 검색하면 이름 일치로 검색, 그 외에는 이름 포함으로 검색합니다. (1글자는 이름 일치로 검색)`}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

function UIExtraSearchGroup({
  funcDispatch,
  rCtrlEventListeners,
  nSearchGroup,
  arrExceptKIND,
  arrDefaultConditions,
}) {

  useEffect(() => {
    const nID = rCtrlEventListeners.current.Register((nEventID, pEventParam) => {
      switch (nEventID) {
        case EnumUIEventID.ON_CLICK_RESET: {
          pConditions.pMap.clear();
          funcSetConditions({ ...pConditions });
          break;
        }

        case EnumUIEventID.EX_COLLECT_ALL_CONDITIONS: {
          let arrConditions = pEventParam.arrConditions;

          pConditions.pMap.forEach((pSelectData, nColumnKIND) => {
            let pDataSearch = pSheetColumn.ExtraSearch.mapKIND.get(nColumnKIND);
            if (!pDataSearch) {
              return;
            }

            let pDataDBColumn = pSheetColumn.DBColumn.mapKIND.get(pDataSearch.DBColumnKIND);
            if (!pDataDBColumn) {
              return;
            }

            // 정보 추가 (DB 컬럼 + 선택값)
            arrConditions.push({ pDataDBColumn: pDataDBColumn, Value: pSelectData.nEnumKIND });
          });
          break;
        }

        default: {
          break;
        }
      }
    });

    return () => {
      rCtrlEventListeners.current.UnRegister(nID);
    };
  }, []);



  let pDefaultMap = new Map();
  if (arrDefaultConditions) {
    for (let i = 0; i < arrDefaultConditions.length; ++i) {
      let e = arrDefaultConditions[i];
      let pDataDBColumn = e.pDataDBColumn;
      if (pDataDBColumn.KIND === CONST._INT.DBCOLUMN_KIND_NAME_EQUAL ||
        pDataDBColumn.KIND === CONST._INT.DBCOLUMN_KIND_NAME_CONTAINS) {
        // 따로 처리한다
        continue;
      }

      let nEnumKIND = e.Value;
      let pExtraSearch = pSheetColumn.ExtraSearch.mapDBColumnKIND.get(pDataDBColumn.KIND);
      let pEnumData = pSheetEnum[`${pDataDBColumn.DataType}`].mapKIND.get(nEnumKIND);
      if (!pExtraSearch || !pEnumData) {
        continue;
      }

      if (pExtraSearch.Group !== nSearchGroup) {
        continue;
      }

      // key : ColumnKIND / value : { nEnumKIND: nEnumKIND, UIName: pSelectedEnumData.UIName }
      pDefaultMap.set(pExtraSearch.KIND, { nEnumKIND: nEnumKIND, UIName: pEnumData.UIName });
    }
  }

  const [pConditions, funcSetConditions] = useState({ pMap: pDefaultMap });

  let pSearchGroup = pSheetColumn.ExtraSearchGroup.mapKIND.get(nSearchGroup);
  if (!pSearchGroup) {
    return (<></>);
  }

  return (
    <Card className="mb-2">
      <Card.Header>{pSearchGroup.UIName}</Card.Header>
      <Card.Body>

        {
          /* ETC */
          UISearchUIHelper.CreateEtcColumnDropDown(nSearchGroup, pConditions, funcSetConditions, arrExceptKIND)
        }
      </Card.Body>
    </Card>
  );
}

export { ComExtraSearchModal }