import { Children } from "react";
import { Button } from "react-bootstrap";

const EnumUtilFloatButtonType = {
  SCROLL_TOP: 1,
  SCROLL_BOTTOM: 2,

  CUSTOM_FUNCTION: 1001,
};
Object.freeze(EnumUtilFloatButtonType);

class CUtilFloatButtonParam {
  constructor(eType, szUIText, cb) {
    this.eType = eType;
    this.szUIText = szUIText;
    this.cb = cb;
  }
  GetUIText() {
    switch (this.eType) {
      case EnumUtilFloatButtonType.SCROLL_TOP: {
        return "🔼";
      }

      case EnumUtilFloatButtonType.SCROLL_BOTTOM: {
        return "🔽";
      }

      default: {
        return this.szUIText;
      }
    }
  }
}

function ComUtilFloatButton({
  arrParams,
}) {

  if (!arrParams || arrParams.length <= 0) {
    return (
      <></>
    );
  }

  async function OnDispatchEvent(pParam) {
    if (!pParam) {
      return;
    }

    switch (pParam.eType) {
      case EnumUtilFloatButtonType.SCROLL_TOP: {
        // 맨위로 스크롤
        window.scrollTo({ left : 0, top : 0, behavior: "instant"});
        break;
      }

      case EnumUtilFloatButtonType.SCROLL_BOTTOM: {
        // 맨아래로 스크롤
        window.scrollTo({ left : 0, top : document.body.scrollHeight, behavior: "instant"});
        break;
      }

      case EnumUtilFloatButtonType.CUSTOM_FUNCTION: {
        pParam.cb();
        break;
      }

      default: {
        break;
      }
    }
  }


  let arrRetVal = [];
  const nDefaultBottom = 1;
  const nButtonSize = 3.3;
  const nBottomOffset = 0.5;

  const pDefaultStyle = {
    fontSize: "1.2rem",
    position: "fixed",
    width: `${nButtonSize}rem`,
    height: `${nButtonSize}rem`,
    // bottom : 각자 다름
    right: "0rem",
    boxShadow: " 2px 2px 3px #999",
    opacity: "0.8"
  };

  arrParams.forEach((pData, idx) => {
    let szBottom = `${nDefaultBottom + (nBottomOffset + nButtonSize) * idx}rem`;
    arrRetVal.push(
      <Button variant="outline-primary" onClick={() => { OnDispatchEvent(pData) }} style={{ bottom: szBottom, ...pDefaultStyle }}>{pData.GetUIText()}</Button>
    );
  });

  return (Children.toArray(arrRetVal));
}

export {ComUtilFloatButton, EnumUtilFloatButtonType, CUtilFloatButtonParam};
