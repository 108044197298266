import Nav from 'react-bootstrap/Nav';
import { Children } from 'react';

// personal
import { CONST } from '../mod/CardClientSheetData';
import { Link, NavLink, useMatch, useNavigate } from 'react-router-dom';
import { Button, Container, Navbar } from 'react-bootstrap';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import GameEnv from '../mod/GameEnv';


class CTabInfo {
    // Url : 탭 클릭시 이동할 라우터 주소
    // MatchPattern : 탭 선택 표시를 위한 useMatch에 넘길 패턴. Nested Route 를 사용할 경우 와일드카드(/*) 필요할수 있음.
    // Title : 탭 표시용 텍스트
    constructor(Url, MatchPattern, Title) {
        this.Url = Url;
        this.MatchPattern = MatchPattern;
        this.Title = Title;
    }
}
const arrTabInfo = [
    new CTabInfo("/", "/", "메인"),
    new CTabInfo("/cardlist", "/cardlist/*", "카드목록"),
    new CTabInfo("/userdeck/board", "/userdeck/*", "유저덱"),
    new CTabInfo("/library/board", "/library/*", "라이브러리"),
    new CTabInfo("/account/main", "/account/*", "계정")
];

function MainTabs({ pMaind }) {

    // 현재 url과 일치하는지 체크 (react router 기능)
    // 문법상의 이유로 SetMatch 같이 함수로 따로 빼줘야 루프문으로 세팅 가능한 모양이다. 씨발.
    const arrMatch = [];
    function SetMatch(i) {
        arrMatch.push(useMatch(arrTabInfo[i].MatchPattern));
    }
    for (let i = 0; i < arrTabInfo.length; ++i) {
        SetMatch(i);
    }

    const pNavigate = useNavigate();
    function OnClickTab(idx) {
        let pTabInfo = arrTabInfo[idx];
        pNavigate(pTabInfo.Url);
    }

    function OnClickBrandLogo() {
        let pTabInfo = arrTabInfo[0];
        pNavigate(pTabInfo.Url);
    }

    function GetEventKey(idx) {
        return `tabEventKey${idx}`;
    }

    function IsSelected(idx) {
        return arrMatch[idx] !== null;
    }

    function GetCurrentActiveKey() {
        for (let i = 0; i < arrMatch.length; ++i) {
            if (IsSelected(i)) {
                return GetEventKey(i);
            }
        }

        return "";
    }


    function GetTabs() {

        let arrRetVal = [];

        for (let i = 0; i < arrTabInfo.length; ++i) {
            let szTabName = arrTabInfo[i].Title;
            if (!szTabName) {
                console.log(`MainTabs : not defined tab ${i}`);
                continue;
            }

            let pStyle = {};
            if (IsSelected(i)) {
                pStyle = { backgroundColor: "#0d6efd", color: "white", fontSize: "1.1rem", fontWeight: "bold" };
            }

            arrRetVal.push(
                <Nav.Link eventKey={GetEventKey(i)} onClick={() => { OnClickTab(i); }} className="px-4 py-2" style={pStyle}>
                    {szTabName}
                </Nav.Link>
            );
        }

        // Childeren.toArray : 고유 key 할당을 React에 맡기기
        return (Children.toArray(arrRetVal));
    }


    let szLogo = `FM LUNATIC`;
    let szLogoColor = "black";
    switch (GameEnv.GetAppMode()) {

        case CONST._APP_MODE.LIVE: {
            szLogo = `FM LUNATIC`;
            szLogoColor = "black";
            break;
        }

        default: {
            szLogo = `DV LUNATIC`;
            szLogoColor = "red";
            break;
        }
    }

    return (
        <>
            {['lg'].map((expand) => (
                <Navbar key={expand} bg="light" expand={expand} className="mb-3 py-0" collapseOnSelect={true}>
                    <Container fluid>

                        <Navbar.Brand style={{ fontSize: "1.5rem", fontWeight: "bold", cursor:"pointer" }} onClick={OnClickBrandLogo}>
                            <img alt="" src="/favicon_196.png" width="32" height="32" className="d-inline-block align-center" />{' '}
                            <span style={{color:szLogoColor}}>{szLogo}</span>
                        </Navbar.Brand>

                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />

                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="end"
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                                    <img alt="" src="/favicon_196.png" width="32" height="32" className="d-inline-block align-center" />{' '}
                                    <span style={{color:szLogoColor}}>{szLogo}</span>
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body className='px-0 py-0'>
                                <Nav className="justify-content-end flex-grow-1">
                                    <Nav activeKey={GetCurrentActiveKey()} defaultActiveKey={GetEventKey(0)}>
                                        {GetTabs()}
                                    </Nav>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}
        </>
    );
}

export default MainTabs;