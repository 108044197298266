// FM DB 클라와 공유하는 부분 : 컬럼 파싱 로직

import { pSheetEnum } from "./CardClientSheetData.js";

const EnumDataColType = {
  INT: "int",
  STRING: "string",
  ARRAY_STRING: "Array<string>",
  COMPLEX_REQUIRESOUL: "Complex<RequireSoul>",
  COMPLEX_EARNSOUL: "Complex<EarnSoul>",
}
Object.freeze(EnumDataColType);

let CardColumnParser = {

  EnumDataToObj(colType, pData, szSheetName, col, row) {
    function __is_empty__(e) {
      // 0이거나 ""로 비어있을 경우에는 Empty 가 아니다.
      return e === undefined || e === null;
    }

    let pRetVal = pData;

    switch (colType) {
      case EnumDataColType.INT: {
        if (!__is_empty__(pRetVal)) {
          pRetVal = parseInt(pRetVal);
        }
        else {
          pRetVal = 0;
        }
        break;
      }

      case EnumDataColType.STRING: {
        if (!__is_empty__(pRetVal)) {
          pRetVal = String(pRetVal);
        }
        else {
          pRetVal = "";
        }
        break;
      }

      case EnumDataColType.ARRAY_STRING: {
        if (!__is_empty__(pRetVal)) {
          let __arrTemp = pRetVal.split(';'); // 구분자로 자른다
          pRetVal = [];
          __arrTemp.forEach(e => {
            if (e) pRetVal.push(e);
          });
        }
        else {
          pRetVal = [];
        }
        break;
      }

      default: {
        pRetVal = "";
        break;
      }
    }
    return pRetVal;
  },

  ObjToJSText(colType, pColumnData) {
    let szRetval = "";

    switch (colType) {
      case EnumDataColType.INT: {
        // 따옴표가 없다
        szRetval += `${pColumnData}`;
        break;
      }

      case EnumDataColType.STRING: {
        // 따옴표가 있다 (문자열이므로)
        szRetval += `"${pColumnData}"`;
        break;
      }

      case EnumDataColType.ARRAY_STRING: {
        let __szTemp = "";
        pColumnData.forEach(e => {
          __szTemp += `"${e}",`; // 따옴표를 직접 붙여줘야 한다.
        });
        // ["a","b","c",], 
        szRetval += `[${__szTemp}]`;
        break;
      }

      default: {
        // 뭐임?
        break;
      }
    }

    return szRetval;
  },

  ExcelDataToDBData(colType, pColumnData, szSheetName, col, row) {
    function __is_empty__(e) {
      // 0이거나 ""로 비어있을 경우에는 Empty 가 아니다.
      return e === undefined || e === null;
    }

    let pRetVal = pColumnData;

    let id = `[${szSheetName}](row:${row},col:${col})`;

    switch (colType) {
      case EnumDataColType.INT: {
        if (!__is_empty__(pRetVal)) {
          pRetVal = parseInt(pRetVal);
        }
        else {
          pRetVal = 0;
        }
        break;
      }

      case EnumDataColType.STRING: {
        if (!__is_empty__(pRetVal)) {
          pRetVal = String(pRetVal);
        }
        else {
          pRetVal = "";
        }
        break;
      }

      case EnumDataColType.ARRAY_STRING: {
        if (!__is_empty__(pRetVal)) {
          let __arrTemp = pRetVal.split(';'); // 구분자로 자른다
          pRetVal = [];
          __arrTemp.forEach(e => {
            if (e) pRetVal.push(e);
          });
        }
        else {
          pRetVal = [];
        }
        break;
      }

      case EnumDataColType.COMPLEX_REQUIRESOUL: 
      case EnumDataColType.COMPLEX_EARNSOUL: {
        // (DARK.1)(MU.1) => ["DARK.1", "MU.1"]
        let __extractSubstrings__ = function (str) {
          const regex = /\(([^()]+)\)/g;
          let match;
          let result = [];

          while ((match = regex.exec(str))) {
            result.push(match[1]);
          }

          return result;
        };

        let szConvert = pRetVal;
        if (!__is_empty__(szConvert)) {
          szConvert = String(szConvert);
        }
        else {
          szConvert = "";
        }

        let szVal = "";
        let arrExtract = __extractSubstrings__(szConvert);

        arrExtract.forEach((_e) => {
          // "DARK.1" => ["DARK", "1"]
          let [szSoul, szCount] = _e.split(".");

          if (!szCount) {
            throw new Error(`id ${id} : ${_e} is invalid count val`);
          }

          let nCount = parseInt(szCount);
          if (isNaN(nCount)) {
            throw new Error(`id ${id} : ${_e}'s count is not number`);
          }

          let szBaseColType;
          if (colType === EnumDataColType.COMPLEX_REQUIRESOUL) {
            szBaseColType = "RequireSoul";
          }
          else if (colType === EnumDataColType.COMPLEX_EARNSOUL) {
            szBaseColType = "EarnSoul";
          }
        
          if (!pSheetEnum[szBaseColType]) {
            // (에러) 사전 정의되지 않은 타입이다.
            throw new Error(`id ${id} : ${szBaseColType} is not defined.`);
          }

          let pEnumInfo = pSheetEnum[szBaseColType];
          if (!pEnumInfo[`mapTYPE`].has(szSoul)) {
            // (에러) 값이 비어있는것도 허용하지 않는다. 
            throw new Error(`id ${id} : ${pRetVal} is not in ${szBaseColType}.`);
          }

          // TYPE => KIND
          let nKIND = pEnumInfo[`mapTYPE`].get(szSoul).KIND;

          // KIND(02)COUNT(9) => "0209"
          szVal += `${nKIND.toString().padStart(2, '0')}${nCount.toString().padStart(2, '0')}`;
        });

        pRetVal = szVal;

        break;
      }

      default: {

        // Enum

        if (!pSheetEnum[`${colType}`]) {
          // (에러) 사전 정의되지 않은 타입이다.
          throw new Error(`id ${id} : ${colType} is not defined.`);
        }

        // 문자열 변환. 기본은 문자열이다.
        let szConvert = pRetVal;
        if (!__is_empty__(szConvert)) {
          szConvert = String(szConvert);
        }
        else {
          szConvert = "";
        }

        let pEnumInfo = pSheetEnum[`${colType}`];
        if (!pEnumInfo[`mapTYPE`].has(szConvert)) {
          // (에러) 값이 비어있는것도 허용하지 않는다. 
          throw new Error(`id ${id} : ${pRetVal} is not in ${colType}.`);
        }

        // TYPE => KIND
        pRetVal = pEnumInfo[`mapTYPE`].get(szConvert).KIND;
        break;
      }
    }

    return pRetVal;
  },

};

export { CardColumnParser };