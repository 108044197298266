// bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import { Container, Image, ListGroup, Navbar } from 'react-bootstrap';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

// 
import { useState, Children, useEffect, createRef, useRef } from "react";

// 
import { pSheetEnum, pSheetColumn, CONST } from '../mod/CardClientSheetData';
import { CardColumnParser } from '../mod/CardColumnParser';
import ComCardDetailModal from './ComCardDetailModal';
import GameUtil from '../mod/GameUtil';
import { ComExtraSearchModal } from './ComExtraSearchModal';

// enum
const EnumUIEventID = {
  ON_CLICK_SEARCH: 101,
  ON_CLICK_OPEN_EXTRA_SEARCH: 103,

  SHEET_ON_CLICK_ITEM: 201,
  SHEET_ON_CLICK_LOAD_MORE: 202,
};

const ONEPAGE_DATA_LIMIT = 50;

function ComCardErrorCheckList({
  pMaind,
  bIsLimitView,
}) {

  let [bShowExtraSearchModal, funcSetExtraSearchModal] = useState(false);

  let [pDBResult, funcDBResult] = useState({
    TotalCount: 0,
    Datas: [],
    pOrderValueEnd: null,

    Clear: function () {
      this.TotalCount = 0;
      this.Datas = [];
      this.pOrderValueEnd = null;
    },
  });

  // DB 요청 처리 번호표
  let rDBRequestControl = useRef({
    pMap: new Map(),
    IDCount: 1,

    Register: function () {
      let nID = this.IDCount;
      this.IDCount++;

      if (!this.pMap.has(nID)) {
        this.pMap.set(nID, nID);
      }
      return nID;
    },
    UnRegister: function (nID) {
      if (this.pMap.has(nID)) {
        this.pMap.delete(nID);
      }
    },
    Clear: function () {
      this.pMap.clear();
    }
  });

  let rEventListeners = useRef({
    pMap: new Map(),
    IDCount: 1,

    Register: function (cb) {
      let nID = this.IDCount;
      this.IDCount++;

      if (!this.pMap.has(nID)) {
        this.pMap.set(nID, cb);
      }
      return nID;
    },
    UnRegister: function (nID) {
      if (this.pMap.has(nID)) {
        this.pMap.delete(nID);
      }
    },
  });

  let [nSortedID, funcSortedID] = useState(1);

  useEffect(() => {

    // 검색 조건이 바뀌었다!

    (async function () {
      // 기존 DBResult 초기화
      pDBResult.Clear();

      // 검색 다시 실행
      await ProcDBSearch();
    })();

    return () => {
      // Unmount 시 처리
      // 처리 번호표 전부 삭제
      rDBRequestControl.current.Clear();
    };

  }, [nSortedID]);

  async function ProcDBSearch() {

    // arrSearchCondition 을 기반으로 검색을 진행하고, 결과를 "추가"한다
    // 이 전단계에서 arrSearchCondition 의 세팅이 끝났어야 한다.

    let arrQueries = [];

    // 반드시 NumID 정렬을 맨앞에 둘것. 순서 꼬이면 색인 다시해야 할수도 있다.
    let szColumnNameOrder = "SortedID";
    arrQueries.push(pMaind.pFbCtrl.CreateSearchQuery(szColumnNameOrder, CONST._DB_PROCTYPE.ORDER_BY, "asc"));
    arrQueries.push(pMaind.pFbCtrl.CreateSearchQuery(szColumnNameOrder, CONST._DB_PROCTYPE.START_AT, nSortedID));

    // DB 요청 전에 미리 결과 처리 번호표 등록
    let nReqID = rDBRequestControl.current.Register();

    // DB 요청 (비동기)
    let pSearchResult = await pMaind.pFbCtrl.GetSearchedDocs(GameUtil.GetDBCardListCollection(),
      arrQueries,
      ONEPAGE_DATA_LIMIT,
      pDBResult.TotalCount,
      szColumnNameOrder,
      null);

    // DB 요청 결과 이후에도 요청 관리에 ID가 있으면 실행
    if (!rDBRequestControl.current.pMap.has(nReqID)) {
      return;
    }

    // 처리 번호표 삭제
    rDBRequestControl.current.UnRegister(nReqID);

    // 결과 처리
    pDBResult.Datas.push(...pSearchResult.arrResult);
    pDBResult.TotalCount = pSearchResult.TotalCount;
    pDBResult.pOrderValueEnd = pSearchResult.pOrderValueEnd;
    funcDBResult({ ...pDBResult });
  }

  // Event Binding
  async function OnDispatchEvent(nEventID, pEventParam) {
    // main
    switch (nEventID) {
      case EnumUIEventID.SHEET_ON_CLICK_LOAD_MORE: {
        ProcDBSearch();
        break;
      }
    }

    rEventListeners.current.pMap.forEach((pListener, _k) => {
      if (pListener) {
        pListener(nEventID, pEventParam);
      }
    });
  }


  let [szInputSortedID, funcSetInputSortedID] = useState("");
  function OnClickSortedID() {
    try {
      let _nSortedID = parseInt(szInputSortedID);
      if (isNaN(_nSortedID)) {
        throw new Error(`숫자가 아니다!! ${szInputSortedID}`);
      }

      funcSortedID(_nSortedID);
    }
    catch (error) {
      alert(error.message);
    }
  }

  function OnClickMinus() {
    funcSortedID(nSortedID - ONEPAGE_DATA_LIMIT);
  }

  function OnClickPlus() {
    funcSortedID(nSortedID + ONEPAGE_DATA_LIMIT);
  }

  function OnKeyDownHaha(e) {
    // 의도 : 검색란에 키워드 입력하고 Enter 누르면 검색 기능 실행
    // Button 말고 Input 에 달아야 의도대로 동작.

    if (e.key === 'Enter') {
      OnClickSortedID();
    }
  }

  return (
    <>
      <InputGroup className='mb-5'>
        <Form.Control value={szInputSortedID} placeholder="SortedID" onKeyDown={OnKeyDownHaha} onChange={(e) => funcSetInputSortedID(e.target.value)} />
        <Button variant="outline-primary" className='px-3' onClick={OnClickSortedID}>검색</Button>
        <Button variant="outline-primary" className='px-3' onClick={OnClickMinus}>{`${ONEPAGE_DATA_LIMIT} -`}</Button>
        <Button variant="outline-primary" className='px-3' onClick={OnClickPlus}>{`${ONEPAGE_DATA_LIMIT} +`}</Button>
      </InputGroup>
      <p>{`${nSortedID} ~ ${nSortedID + pDBResult.Datas.length - 1} 표시중(SortedID 기준)`}</p>

      {/* <UICardSearch funcDispatch={OnDispatchEvent}> </UICardSearch> */}

      {/* <UICardCondition arrConditions={arrSearchCondition}></UICardCondition> */}

      <UICardSheet
        pMaind={pMaind}
        pDBResult={pDBResult}
        bIsLimitView={bIsLimitView}
        funcDispatch={OnDispatchEvent}
      >
      </UICardSheet>

      {/* <ComExtraSearchModal
        pMaind={pMaind}
        bShow={bShowExtraSearchModal}
        funcSetShow={funcSetExtraSearchModal}
        cbOnOK={(arrConditions) => { OnDispatchEvent(EnumUIEventID.ON_CLICK_SEARCH, { arrConditions: arrConditions }) }}
        arrDefaultConditions={arrSearchCondition}
      >
      </ComExtraSearchModal> */}
    </>
  );
}

function UICardCondition({
  arrConditions,
}) {

  let arr = [];
  if (arrConditions) {
    arrConditions.forEach((e) => {
      let pDataDBColumn = e.pDataDBColumn;
      let pValue = e.Value;

      let szUIName = pDataDBColumn.UIName;
      let szValue = String(pValue);
      if (pDataDBColumn.DataType !== "string") {
        pValue = parseInt(pValue);

        if (pDataDBColumn.DataType !== "int") {
          let pEnumInfo = pSheetEnum[`${pDataDBColumn.DataType}`].mapKIND.get(pValue);
          if (pEnumInfo) {
            szValue = pEnumInfo.UIName;
          }
        }
      }

      arr.push(
        <Button variant='primary' className='me-1 mb-1 px-2 py-1' style={{ fontSize: "0.8rem" }}>
          {`${szUIName} (${szValue})`}
        </Button>
      );
    });
  }

  if (arr.length <= 0) {
    arr.push(<Card.Text>조건 없음</Card.Text>);
  }

  return (
    <Card className='mb-3'>
      <Card.Header>현재 검색 조건</Card.Header>
      <Card.Body className='px-2 py-2'>
        {Children.toArray(arr)}
      </Card.Body>
    </Card>
  );
}

function UICardSearch({
  funcDispatch
}) {

  // state, ref
  const [szDefaultCardName, funcSetDefaultCardName] = useState("");

  function OnKeyDown(e) {
    // 의도 : 검색란에 키워드 입력하고 Enter 누르면 검색 기능 실행
    // Button 말고 Input 에 달아야 의도대로 동작.

    if (e.key === 'Enter') {
      ExecDefaultSearch();
    }
  }

  function OnClickDefaultSearch() {
    ExecDefaultSearch();
  }

  function ExecDefaultSearch() {

    let arrConditions = [];

    // 이름 검색 체크
    // 공백을 제외시킨다 (공백으로 분리(split) + join으로 다시 합치기)
    (() => {

      // 전처리 : 공백 제거
      let szInputName = szDefaultCardName.split(' ').join('');
      if (szInputName !== szDefaultCardName) {
        funcSetDefaultCardName(szInputName);
      }

      szInputName = szInputName.split(' ').join('');
      if (!szInputName) {
        return;
      }

      let pDataDefaultSearch = pSheetColumn.DefaultSearch.mapKIND.get(CONST._INT.DEFAULT_SEARCH_NAME_KIND);
      if (!pDataDefaultSearch) {
        return;
      }

      // 1글자일 경우 : 일치 로 검색
      // 2글자 이상일 경우 : 포함 으로 검색
      let pDataDBColumn;
      if (szInputName.length <= 1) {
        pDataDBColumn = pSheetColumn.DBColumn.mapKIND.get(CONST._INT.DBCOLUMN_KIND_NAME_EQUAL);
      }
      else {
        pDataDBColumn = pSheetColumn.DBColumn.mapKIND.get(CONST._INT.DBCOLUMN_KIND_NAME_CONTAINS);
      }

      if (!pDataDBColumn) {
        return;
      }

      // 정보 추가 (DB 컬럼 + 입력값)
      arrConditions.push({ pDataDBColumn: pDataDBColumn, Value: szInputName });
    })();

    // 검색 진행하기 전에 UI 리셋
    funcSetDefaultCardName("");

    // 검색 진행
    funcDispatch(EnumUIEventID.ON_CLICK_SEARCH, { arrConditions: arrConditions });
  }

  function OnClickOpenExtraSearch() {
    funcDispatch(EnumUIEventID.ON_CLICK_OPEN_EXTRA_SEARCH);
  }

  let pGlobalStyle = { fontSize: "1.2rem", fontWeight: "bold" };
  return (
    <Container>
      <Row xs={1} sm={2}>
        <Col xs={12} sm={9} className='px-0 mb-1'>
          <InputGroup>
            <Form.Control value={szDefaultCardName} placeholder="카드명" aria-label="inputCardName" onKeyDown={OnKeyDown} onChange={(e) => funcSetDefaultCardName(e.target.value)} />
            <Button variant="outline-primary" className='px-3' style={pGlobalStyle} onClick={OnClickDefaultSearch}>검색</Button>
          </InputGroup>
        </Col>
        <Col xs={12} sm={3} className='pe-0'>
          <div className="d-flex justify-content-end mb-2">
            <Button variant="outline-primary" className='px-3' style={pGlobalStyle} onClick={OnClickOpenExtraSearch}>상세검색</Button>
          </div>
        </Col>
      </Row>
    </Container>

  );
}


function UICardSheet({
  pMaind,
  pDBResult,
  bIsLimitView,
  funcDispatch
}) {

  // Sheet 를 감싸는 div 정의. 
  let pDivSetting = {};
  pDivSetting.className = "";
  pDivSetting.style = {};
  if (bIsLimitView) {
    // 제한된 뷰 + 스크롤 표시
    pDivSetting.className = "overflow-scroll";
    pDivSetting.style = { height: "25rem" };
  }

  function OnClickLoad() {
    funcDispatch(EnumUIEventID.SHEET_ON_CLICK_LOAD_MORE);
  }

  function RefreshUI_TotalCount() {
    return (
      <p className='w-100 text-end' style={{ fontSize: "0.8rem" }}>{`총 ${pDBResult.TotalCount}개의 데이터 (${pDBResult.Datas.length}개 표시중)`}</p>
    );
  }

  function RefreshUI_SheetItems() {
    let arr = [];
    pDBResult.Datas.forEach((pData) => {
      arr.push(
        <UICardSummary
          pMaind={pMaind}
          pData={pData}
          funcDispatch={funcDispatch}
        >
        </UICardSummary>
      );
    });
    return Children.toArray(arr);
  }

  function RefreshUI_Button() {
    let nRemainCount = pDBResult.TotalCount - pDBResult.Datas.length;
    let nLoadableCount = (nRemainCount >= ONEPAGE_DATA_LIMIT) ? ONEPAGE_DATA_LIMIT : nRemainCount;
    if (nRemainCount <= 0) {
      return (
        <Col>
          <Button variant='secondary my-3' disabled size='lg' className='w-100'>데이터를 모두 로드하였습니다.</Button>
        </Col>
      );
    }

    return (
      <Button variant='outline-primary my-3' className='w-100' size='lg' onClick={OnClickLoad}>{`${nLoadableCount}개 더 보기`}</Button>
    );
  }

  return (
    /* 
    https://react-bootstrap.netlify.app/layout/grid/#row-layout-col-sizing
    xs = (< 576px)
    sm = (>= 576px)
    md = (>= 768px)
    lg = (>= 992px)
    xl = (>= 1200px)
    xxl = (>= 1400px)
  */

    <>
      {RefreshUI_TotalCount()}

      <div className={pDivSetting.className} style={pDivSetting.style}>
        {/* // 스크롤링 시 가로로 살짝 스크롤되는거 때문에. Row 기본에 X축 margin에 -5 먹여져 있음 */}
        <Row className="mx-0">
          {RefreshUI_SheetItems()}
        </Row>
        {/* {RefreshUI_Button()} */}
      </div>

    </>
  );
}


/**
  * Card class, style
  * 
  * small
  * card width : 100px
  * card height : 142px
  */
let pCardUISetting = {
  // 
  cBase: "mb-1 px-0",
  stBase: { width: `${285 * 2 + 30}px`, cursor: "pointer" },
  // 
  cImg: "",
  stImg: { width: "285px", height: "405px", background: `url("${GameUtil.GetImageDefaultPathSmall()}")` },
  // 
  cText: "text-center my-0",
  stText: { fontSize: "0.9rem" },
};

let pMapCardUISoulSymbol = new Map([
  [1, { Symbol: "■", Sub: "암", Color: "4e1063" }], // 1 DARK
  [2, { Symbol: "■", Sub: "불", Color: "d51f0b" }], // 2 FIRE
  [3, { Symbol: "■", Sub: "물", Color: "0763f1" }], // 3 WATER
  [4, { Symbol: "■", Sub: "숲", Color: "467312" }], // 4 FOREST
  [5, { Symbol: "■", Sub: "금", Color: "b0aea4" }], // 5 IRON
  [6, { Symbol: "■", Sub: "대", Color: "7f4d1b" }], // 6 EARTH
  [7, { Symbol: "■", Sub: "빛", Color: "e7da6b" }], // 7 LIGHT
  [8, { Symbol: "E", Sub: "에", Color: "000000" }], // 8 ECAMAEL
  [9, { Symbol: "L", Sub: "전", Color: "000000" }], // 9 LEGEND
  [10, { Symbol: "M", Sub: "무", Color: "000000" }], // 10 MU
]);

let pMapRequireSoulSymbol = new Map([
  [1, { Symbol: "없", Sub: "없", Color1: "000000", Color2: "000000" }], // 1 DARK

  // 
  [11, { Symbol: "●", Sub: "암", Color1: "4e1063", Color2: "4e1063" }], // 1 DARK
  [12, { Symbol: "암", Sub: "대", Color1: "4e1063", Color2: "7f4d1b" }], // 1 DARK
  [13, { Symbol: "암", Sub: "불", Color1: "4e1063", Color2: "d51f0b" }], // 1 DARK
  [14, { Symbol: "암", Sub: "빛", Color1: "4e1063", Color2: "e7da6b" }], // 1 DARK
  [15, { Symbol: "암", Sub: "물", Color1: "4e1063", Color2: "0763f1" }], // 1 DARK

  // 
  [21, { Symbol: "●", Sub: "불", Color1: "d51f0b", Color2: "d51f0b" }], // 2 FIRE
  [22, { Symbol: "불", Sub: "숲", Color1: "d51f0b", Color2: "467312" }], // 2 FIRE
  [23, { Symbol: "불", Sub: "금", Color1: "d51f0b", Color2: "b0aea4" }], // 2 FIRE
  [24, { Symbol: "불", Sub: "물", Color1: "d51f0b", Color2: "0763f1" }], // 2 FIRE

  // 
  [31, { Symbol: "●", Sub: "물", Color1: "0763f1", Color2: "0763f1" }], // 3 WATER
  [32, { Symbol: "물", Sub: "대", Color1: "0763f1", Color2: "7f4d1b" }], // 3 WATER
  [33, { Symbol: "물", Sub: "숲", Color1: "0763f1", Color2: "467312" }], // 3 WATER

  // 
  [41, { Symbol: "●", Sub: "숲", Color1: "467312", Color2: "467312" }], // 4 FOREST
  [42, { Symbol: "숲", Sub: "금", Color1: "467312", Color2: "b0aea4" }], // 4 FOREST
  [43, { Symbol: "숲", Sub: "빛", Color1: "467312", Color2: "e7da6b" }], // 4 FOREST

  // 
  [51, { Symbol: "●", Sub: "금", Color1: "b0aea4", Color2: "b0aea4" }], // 5 IRON
  [52, { Symbol: "금", Sub: "대", Color1: "b0aea4", Color2: "7f4d1b" }], // 5 IRON
  [53, { Symbol: "금", Sub: "빛", Color1: "b0aea4", Color2: "e7da6b" }], // 5 IRON

  // 
  [61, { Symbol: "●", Sub: "대", Color1: "7f4d1b", Color2: "7f4d1b" }], // 6 EARTH
  [62, { Symbol: "대", Sub: "빛", Color1: "7f4d1b", Color2: "e7da6b" }], // 6 EARTH

  // 
  [71, { Symbol: "●", Sub: "빛", Color1: "e7da6b", Color2: "e7da6b" }], // 7 LIGHT

  [81, { Symbol: "○", Sub: "무", Color1: "000000", Color2: "000000" }], // 10 MU
  [82, { Symbol: "ⓝ", Sub: "N", Color1: "000000", Color2: "000000" }], // 10 MU
  [83, { Symbol: "①", Sub: "뽀", Color1: "000000", Color2: "000000" }], // 10 MU
]);

function UICardSummary({
  pMaind,
  pData,
  funcDispatch,
}) {

  // state, effect

  function RefreshUI_CardImg() {
    let szImagePath = GameUtil.GetImagePathLarge(pData.Category, pData.KIND);
    if (szImagePath) {
      szImagePath = pMaind.pFbCtrl.GetStorageURL(szImagePath);
    }
    else {
      szImagePath = GameUtil.GetImageDefaultPathLarge();
    }

    return (
      <Card.Img
        loading="lazy"    //이미지가 뷰포트의 일정 거리 안으로 들어와야 불러옵니다.
        decoding="async"  //다른 콘텐츠의 표시 지연을 피하기 위해 이미지를 비동기적으로 디코딩 합니다.
        src={szImagePath} variant="top" className={pCardUISetting.cImg} style={pCardUISetting.stImg} />
    );
  }

  function RefreshUI_Name_Soul_Rank() {
    // Name
    // Rank

    let pUIData = pMapCardUISoulSymbol.get(pData.CardUISoul);
    let szUINameRankType = "랭크없음";
    if (pData.RankType) {
      szUINameRankType = pSheetEnum[`RankType`].mapKIND.get(pData.RankType).UIName;
    }

    return (
      <>
        <Card.Text className='px-0'>
          <span className='px-0' style={{ color: `#${pUIData.Color}` }}>
            {`${pUIData.Symbol}${pUIData.Sub}`}
          </span >
          {` `}
          {`${pData.Name}`}
          {` (${szUINameRankType})`}
        </Card.Text>
      </>
    );
  }

  function SplitStringByLength(str, length) {
    let result = [];
    for (let i = 0; i < str.length; i += length) {
      result.push(str.slice(i, i + length));
    }
    return result;
  }

  function RefreshUI_RequireSoul() {
    let arr = [];

    let arrRequireSoul = SplitStringByLength(pData.RequireSoul, 4);
    arrRequireSoul.forEach((szRequireSoul) => {
      let arrSplit = SplitStringByLength(szRequireSoul, 2);
      let nSoulType = parseInt(arrSplit[0]);
      let nSoulCount = parseInt(arrSplit[1]);

      let pUIData = pMapRequireSoulSymbol.get(nSoulType);
      if (!pUIData) {
        console.log(`!!!!${nSoulType}`);
      }

      for (let i = 0; i < nSoulCount; ++i) {

        arr.push(
          <span className='px-0' style={{ color: `#${pUIData.Color1}` }}>
            {`${pUIData.Symbol}`}
          </span >
        );
        arr.push(
          <>
            <span className='px-0' style={{ color: `#${pUIData.Color2}` }}>
              {`${pUIData.Sub}`}
            </span ><br />
          </>
        );
      }
    });

    return (
      <div className='mt-4'>
        {Children.toArray(arr)}
      </div>
    );
  }

  function RefreshUI_Center() {
    return (
      <div className='px-0' style={{ width: "160px", height: "190px" }}>
        <br />
        <Card.Text className='mb-1'>{`KIND : ${pData.KIND}`}</Card.Text>
        <Card.Text className='mb-1'>{`ID : ${pData.id}`}</Card.Text>
        <Card.Text className='mb-1'>{`SortedID : ${pData.SortedID}`}</Card.Text>
      </div>
    );
  }

  function RefreshUI_Race_Rare_Company() {
    let szRaceType = pSheetEnum[`RaceType`].mapKIND.get(pData.RaceType).UIName;
    let szRareType = pSheetEnum[`RareType`].mapKIND.get(pData.RareType).UIName;
    let szRareUI = pSheetEnum[`RareUI`].mapKIND.get(pData.RareUI).TYPE;
    let szCompanyType = pSheetEnum[`CompanyType`].mapKIND.get(pData.CompanyType).UIName;

    return (
      <>
        <Card.Text className='px-0 my-0' style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
          {`${szRaceType} / ${szCompanyType}`}
        </Card.Text>
        <Card.Text className='px-0 my-0' style={{ fontSize: "0.8rem", fontWeight: "bold" }}>
          {`${szRareType}(${szRareUI})`}
        </Card.Text>
      </>
    );
  }

  function RefreshUI_TopStatValue() {
    if (pData.Category === 2) {
      // 유닛
      return RefreshUI_TopCoinStat();
    }
    else if (pData.Category === 4) {
      // 아이템
      return RefreshUI_TopItemStat();
    }
    else {
      return (<></>);
    }
  }

  function RefreshUI_TopCoinStat() {
    function _get_text_coin_(pCoinType, pCoin) {
      if (pCoinType === 1) {
        return `0`; // 1 이면 아예 코인 설정이 없는거다
      }

      let szCoinType = `${GameUtil.GetEnumUIName("CoinType", pCoinType)}`;
      return `${szCoinType}${String(pCoin)}`;
    }

    return (
      <Card.Text className='px-0 my-0' style={{ fontWeight: "bold" }}>
        {`${_get_text_coin_(pData.ATKCoinType, pData.ATKCoin)} / ${_get_text_coin_(pData.DEFCoinType, pData.DEFCoin)} / ${_get_text_coin_(pData.HPCoinType, pData.HPCoin)}`}
      </Card.Text>
    );
  }

  function RefreshUI_TopItemStat() {
    function _get_text_stat_(pStatType, pStatValue) {
      let szStatType = "";
      if (pStatType !== 1) {
        // 1을 제외한 나머지는 어떤 식으로든 기호가 있는 것이다.
        szStatType = `${GameUtil.GetEnumUIName("StatType", pStatType)}`;
      }

      return `${szStatType}${String(pStatValue)}`;
    }

    let szCoinOption = `${GameUtil.GetEnumUIName("CoinOption", pData.CoinOption)}`;
    let szProbOption = `${GameUtil.GetEnumUIName("ProbOption", pData.ProbOption)}`;

    return (
      <Card.Text className='px-0 my-0' style={{ fontWeight: "bold" }}>
        {`${_get_text_stat_(pData.ATKStatType, pData.ATK)} / ${_get_text_stat_(pData.DEFStatType, pData.DEF)} / ${_get_text_stat_(pData.HPStatType, pData.HP)} / ${szCoinOption} / ${szProbOption}`}
      </Card.Text>
    );
  }

  function RefreshUI_RightStat() {
    if (pData.Category === 2) {
      // 유닛
      return RefreshUI_RightUnitStat();
    }
    else if (pData.Category === 4) {
      // 아이템
      return RefreshUI_RightItemStat();
    }
    else {
      return (<></>);
    }
  }

  function RefreshUI_RightUnitStat() {
    function _get_text_stat_(pStatType, pStatValue) {
      let szStatType = "";
      if (pStatType !== 1) {
        // 1을 제외한 나머지는 어떤 식으로든 기호가 있는 것이다.
        szStatType = `${GameUtil.GetEnumUIName("StatType", pStatType)}`;
      }

      return `${szStatType}${String(pStatValue)}`;
    }

    let szUnitType = GameUtil.GetEnumUIName("UnitType", pData.UnitType);

    return (
      <>
        <Card.Text className='px-0 my-0' style={{ fontWeight: "bold" }}>
          {`${szUnitType}`}
        </Card.Text>
        <Card.Text className='px-0 my-0' style={{ fontWeight: "bold" }}>
          <span>{`${_get_text_stat_(pData.ATKStatType, pData.ATK)}`}</span><br />
          <span>{`${_get_text_stat_(pData.DEFStatType, pData.DEF)}`}</span><br />
          <span>{`${_get_text_stat_(pData.HPStatType, pData.HP)}`}</span><br />
        </Card.Text>
      </>
    );
  }

  function RefreshUI_RightItemStat() {
    let szUnitType = GameUtil.GetEnumUIName("UnitType", pData.UnitType);

    return (
      <Card.Text className='px-0 my-0' style={{ fontWeight: "bold" }}>
        {`${szUnitType}`}
      </Card.Text>
    );
  }

  function RefreshUI_Skill() {
    function _get_button_skill_(pSkill01, pSkill02) {
      let arr = [];

      let isAddSkill = false;
      if (pSkill01 !== 1) {
        isAddSkill = true;
        arr.push(<Button className='py-0 me-1' style={{ fontSize: "0.9rem" }}>{`${GameUtil.GetEnumUIName("Skill1", pSkill01)}`}</Button>);
      }

      if (pSkill02 !== 1) {
        isAddSkill = true;
        arr.push(<Button className='py-0 me-1' style={{ fontSize: "0.9rem" }}>{`${GameUtil.GetEnumUIName("Skill2", pSkill02)}`}</Button>);
      }

      if (!isAddSkill) {
        arr.push(<>없음</>);
      }

      return (
        <ListGroup.Item className='py-1'>
          <p className='my-0' style={{ fontSize: "0.9rem" }}><b>스킬 : </b>{Children.toArray(arr)}</p>
        </ListGroup.Item>
      );
    }

    return (
      <>
        {_get_button_skill_(pData.Skill1, pData.Skill2)}
      </>
    );
  }


  return (
    <>
      <Card className={pCardUISetting.cBase} style={pCardUISetting.stBase}>
        <Container>
          <Row>
            <Col className='px-0'>
              {RefreshUI_CardImg()}
            </Col>

            <Col className='px-0'>
              <Container>
                <Row>
                  {RefreshUI_Name_Soul_Rank()}
                </Row>

                <Row>
                  {/* 소울 */}
                  <Col xs={2} className='px-0'>
                    {RefreshUI_RequireSoul()}
                  </Col>

                  <Col xs={10} className='px-0'>

                    <Container>
                      <Row>
                        {RefreshUI_Center()}
                      </Row>
                      <Row>
                        {RefreshUI_Race_Rare_Company()}
                      </Row>
                      <Row>
                        {RefreshUI_TopStatValue()}
                      </Row>

                      <Row>
                        <Container>
                          <Row>
                            <Col xs={3}>
                              {RefreshUI_RightStat()}
                            </Col>
                            <Col xs={9}>
                              {RefreshUI_Skill()}
                            </Col>
                          </Row>
                        </Container>
                      </Row>

                    </Container>

                  </Col>

                </Row>
              </Container>
            </Col>

          </Row>
        </Container>


      </Card>
    </>
  );
}

export { ComCardErrorCheckList };
