import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ButtonGroup, Card, Container, Form, Image, ListGroup, Table } from 'react-bootstrap';

import { useState, Children, useEffect, createRef, useRef } from "react";

// 
import { pSheetEnum, pSheetColumn, CONST } from '../mod/CardClientSheetData';
import { CardColumnParser } from '../mod/CardColumnParser';
import GameUtil from '../mod/GameUtil';
import { useNavigate } from 'react-router-dom';
import { Timestamp } from 'firebase/firestore';
import ComCardDataView from './ComCardDataView';

// enum
const EnumUIEventID = {
  ON_CLICK_REPORT_ERROR: 101,
};

// class
class CCardDetailModalButtonParam {
  constructor(szName, szVariant, funcOnClick) {
    this.szName = szName;
    this.szVariant = szVariant;
    this.funcOnClick = funcOnClick;
  }
}

function ComCardDetailModal({
  pMaind,
  pDetailData,
  arrButtonParams, // Array<CCardDetailModalButtonParam>
  funcOnHide,
}) {

  const [pCurUser, funcSetCurUserPointer] = useState(pMaind.pFbCtrl.GetAuthCurrentUser());

  let rEventListeners = useRef({
    pMap: new Map(),
    IDCount: 1,

    Register: function (cb) {
      let nID = this.IDCount;
      this.IDCount++;

      if (!this.pMap.has(nID)) {
        this.pMap.set(nID, cb);
        console.log(`Register Listener : ${nID}`);
      }
      return nID;
    },
    UnRegister: function (nID) {
      if (this.pMap.has(nID)) {
        this.pMap.delete(nID);
        console.log(`!!UnRegister Listener : ${nID}`);
      }
    },
  });


  // Event Binding
  async function OnDispatchEvent(nEventID, pEventParam) {
    // main
    switch (nEventID) {

      case EnumUIEventID.ON_CLICK_REPORT_ERROR: {
        let szText = pEventParam.szText;
        if (!szText) {
          return;
        }

        let min = 3; let max = 100;
        if (szText.length < min || szText.length > max) {
          alert(`최소 ${min}자, 최대 ${max}자까지 입력할 수 있습니다.`);
          return;
        }

        let pReport = {
          ReportType: 1,
          State: 1,
          UID: pCurUser.uid,
          UserName: pCurUser.displayName,
          CreateTime: Timestamp.now(),
          UpdateTime: Timestamp.now(),
          Title: pDetailData.pData.Name,
          Content: szText,

          // ReportType : 1 의 부가정보
          NumID: pDetailData.pData.NumID,
          CardName: pDetailData.pData.Name,
        };

        if (!pReport.UID) {
          alert(`유저 정보가 유효하지 않습니다. 재로그인 후 다시 시도해주세요.`);
          return;
        }

        if (!pReport.Title || pReport.Title.length > CONST._INT.REPORT_LIMIT_TITLE_LENGTH) {
          alert(`신고 제목은 최소 1자 최대 ${CONST._INT.REPORT_LIMIT_TITLE_LENGTH}자까지 입니다.(현재 ${pReport.Title.length}자)`);
          return;
        }
        if (!GameUtil.IsValidContent(pReport.Title)) {
          alert(`신고 제목에 유효하지 않은 특수문자가 있습니다.(${GameUtil.InvalidCharInContent})`);
          return;
        }

        if (!pReport.Content || pReport.Content.length > CONST._INT.REPORT_LIMIT_CONTENT_LENGTH) {
          alert(`신고 내용은 최소 1자 최대 ${CONST._INT.REPORT_LIMIT_CONTENT_LENGTH}자까지 입니다.(현재 ${pReport.Content.length}자)`);
          return;
        }
        if (!GameUtil.IsValidContent(pReport.Content)) {
          alert(`신고 내용에 유효하지 않은 특수문자가 있습니다.(${GameUtil.InvalidCharInContent})`);
          return;
        }

        try {
          let szDBName = GameUtil.GetDBReportCollection(pReport.UID);
          let [bResult, szDocID] = await pMaind.pFbCtrl.WriteDocToDB(szDBName, "", pReport);
          if (!bResult) {
            throw new Error("write-to-userdb");
          }

          // GMDB에 저장할때는 TargetDocID까지 저장
          pReport.TargetCollection = szDBName;
          pReport.TargetDocID = szDocID;

          szDBName = GameUtil.GetDBGMReportCollection();
          [bResult, szDocID] = await pMaind.pFbCtrl.WriteDocToDB(szDBName, "", pReport);
          if (!bResult) {
            throw new Error("write-to-gmdb");
          }

          alert("신고가 완료되었습니다.");
        }
        catch (error) {
          alert(`신고에 실패했습니다. (${error.message})`);
        }

        break;
      }

      default: {
        return;
      }
    }

    rEventListeners.current.pMap.forEach((pListener, _k) => {
      if (pListener) {
        pListener(nEventID, pEventParam);
      }
    });
  }

  // Event Bindings
  function OnHide() {
    funcOnHide(null);
  }



  function RefreshUI_Buttons() {
    let arrRetVal = [];

    if (arrButtonParams) {
      arrButtonParams.forEach((e) => {
        arrRetVal.push(
          <Button variant={e.szVariant} className='w-100 mb-1' onClick={() => e.funcOnClick(pDetailData.pData)}>{e.szName}</Button>
        );
      });
    }

    return (
      Children.toArray(arrRetVal)
    );
  }

  if (!pDetailData || !pDetailData.pData) {
    return (<></>);
  }


  return (
    <Modal
      show={pDetailData.show}
      animation={false}
      onHide={OnHide}
    >

      <Modal.Header closeButton className='px-2 py-1'>
        <Modal.Title><b>{pDetailData.pData.Name}</b></Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <ComCardDataView
          pMaind={pMaind}
          pCurUser={pCurUser}
          pCardData={pDetailData.pData}
          bIsLimitView={true}
        >
        </ComCardDataView>

        {RefreshUI_Buttons()}
      </Modal.Body>
    </Modal>
  );
}

export { ComCardDetailModal, CCardDetailModalButtonParam };