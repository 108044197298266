import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GameUtil from "../mod/GameUtil";
import { ComLibraryPostView } from "../mycom/ComLibraryPostView";

function PageLibraryPost({
  pMaind
}) {
  const pParams = useParams();
  const [pUIData, funcSetUIData] = useState({
    szID: pParams.id,
    pPostData: null,
  });

  const pNavigate = useNavigate();

  useEffect(() => {
    (async function () {
      if (!pUIData.szID) {
        pNavigate("/library/board");
        return;
      }

      let szCollection = GameUtil.GetDBLibraryCollection();
      const pSearchResult = await pMaind.pFbCtrl.GetDocSimple(szCollection, pUIData.szID);
      if (!pSearchResult) {
        alert("유효하지 않은 게시물 ID입니다. 라이브러리 메인 페이지로 이동합니다.");
        pNavigate("/library/board");
        return;
      }

      pUIData.pPostData = pSearchResult;
      funcSetUIData({ ...pUIData });
    })();
  }, []);


  async function OnClickDelete(pPostData) {
    let szCollection = GameUtil.GetDBLibraryCollection();
    let szDocID = pPostData.id;
    let szTitle = pPostData.Title;

    let isConfirm = window.confirm(`[${szTitle}] 포스트를 삭제하시겠습니까?`);
    if (!isConfirm) {
      return;
    }

    try {
      await pMaind.pFbCtrl.DeleteDocSimple(szCollection, szDocID);

      // 라이브러리 문서 삭제 후처리
      // 관련 이미지들 삭제
      if (pPostData.Images) {
        for (let i = 0; i < pPostData.Images.length; ++i) {
          let pLibImage = pPostData.Images[i];
          if (!pLibImage) {
            continue;
          }

          let szPath = GameUtil.GetLibraryImagePath(szDocID, pLibImage.FileName);
          let [bResult2, szMessage] = await pMaind.pFbCtrl.DeleteFileInStorage(szPath);
          if (!bResult2) {
            throw new Error(szMessage);
          }
        }
      }

      alert(`삭제 완료했습니다.`);

      // 삭제 완료. 게시판으로.
      pNavigate("/library/board");
    }
    catch (error) {
      // TODO : 삭제에 실패
    }
  }

  function OnClickList() {
    // 게시판으로.
    pNavigate("/library/board");
  }

  if (!pUIData.szID) {
    return (
      <p>유효하지 않은 게시물 ID입니다. 라이브러리 메인 페이지로 이동합니다.</p>
    );
  }

  if (!pUIData.pPostData) {
    return (
      <p>로드 중입니다.</p>
    );
  }

  return (
    <>
      <ComLibraryPostView
        pMaind={pMaind}
        pPostData={pUIData.pPostData}
        bIsLimitView={false}
        cbOnDelete={OnClickDelete}
        cbOnList={OnClickList}
        cbOnPostSearchByUID={() => { }}
      >
      </ComLibraryPostView>
    </>
  );
}

export default PageLibraryPost;