import { Children, useEffect, useRef, useState } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { CONST } from "../mod/CardClientSheetData";
import GameUtil from "../mod/GameUtil";
import Util from "../mod/Util";

const ONEPAGE_DATA_LIMIT = 4;

function ComGMNoticeView({
  pMaind,
  bIsLimitView
}) {

  let [pDBResult, funcDBResult] = useState({
    TotalCount: 0,
    Datas: [],
    pOrderValueEnd: null,
    bLastFlag: false,

    Clear: function () {
      this.TotalCount = 0;
      this.Datas = [];
      this.pOrderValueEnd = null;
      this.bLastFlag = false;
    },
  });

  // DB 요청 처리 번호표
  let rDBRequestControl = useRef({
    pMap: new Map(),
    IDCount: 1,

    Register: function () {
      let nID = this.IDCount;
      this.IDCount++;

      if (!this.pMap.has(nID)) {
        this.pMap.set(nID, nID);
      }
      return nID;
    },
    UnRegister: function (nID) {
      if (this.pMap.has(nID)) {
        this.pMap.delete(nID);
      }
    },
    Clear: function () {
      this.pMap.clear();
    }
  });


  let [pDataModal, funcSetDataModal] = useState({
    show: false,
    pData: null,
  });
  function OnHideModal() {
    pDataModal.show = false;
    pDataModal.pData = null;
    funcSetDataModal({ ...pDataModal });
  }

  useEffect(() => {
    (async function () {
      // 기존 DBResult 초기화
      pDBResult.Clear();

      // 검색 다시 실행
      await ProcDBSearch();
    })();

    return () => {
      // Unmount 시 처리
      // 처리 번호표 전부 삭제
      rDBRequestControl.current.Clear();
    };
  }, []);

  async function ProcDBSearch() {
    // arrSearchCondition 을 기반으로 검색을 진행하고, 결과를 "추가"한다
    // 이 전단계에서 arrSearchCondition 의 세팅이 끝났어야 한다.

    let arrQueries = [];

    // 반드시 NumID 정렬을 맨앞에 둘것. 순서 꼬이면 색인 다시해야 할수도 있다.
    let szColumnNameOrder = "createtime";
    arrQueries.push(pMaind.pFbCtrl.CreateSearchQuery(szColumnNameOrder, CONST._DB_PROCTYPE.ORDER_BY, "desc"));

    // DB 요청 전에 미리 결과 처리 번호표 등록
    let nReqID = rDBRequestControl.current.Register();

    // DB 요청 (비동기)
    let pSearchResult = await pMaind.pFbCtrl.GetSearchedDocs(GameUtil.GetDBGMNoticeCollection(),
      arrQueries,
      ONEPAGE_DATA_LIMIT,
      pDBResult.TotalCount,
      szColumnNameOrder,
      pDBResult.pOrderValueEnd);

    // DB 요청 결과 이후에도 요청 관리에 ID가 있으면 실행
    if (!rDBRequestControl.current.pMap.has(nReqID)) {
      return;
    }

    // 처리 번호표 삭제
    rDBRequestControl.current.UnRegister(nReqID);

    // 결과 처리
    pDBResult.Datas.push(...pSearchResult.arrResult);
    pDBResult.TotalCount = pSearchResult.TotalCount;
    pDBResult.pOrderValueEnd = pSearchResult.pOrderValueEnd;
    pDBResult.bLastFlag = pSearchResult.arrResult.length <= 0;
    funcDBResult({ ...pDBResult });
  }

  function OnClickLoadMore() {
    ProcDBSearch();
  }

  function RefreshUI_Posts() {
    let arr = [];

    function OnClick(pData) {
      pDataModal.show = true;
      pDataModal.pData = pData;
      funcSetDataModal({ ...pDataModal });
    }

    pDBResult.Datas.forEach((pData) => {
      let pCreateTime = pData.createtime.toDate();
      arr.push(<Card className="mb-1">
        <Card.Body className="px-2 py-2" onClick={() => OnClick(pData)}>
          <Card.Text className="mb-0" style={{ fontSize: "0.9rem" }}>{`${pData.title}`}</Card.Text>
          <Card.Text style={{ fontSize: "0.7rem" }}>{`${Util.GetTimeDiffString(pCreateTime)}`}</Card.Text>
        </Card.Body>
      </Card>
      );
    });

    return (Children.toArray(arr));
  }

  function RefreshUI_ButtonMore() {
    if (pDBResult.bLastFlag) {
      return (
        <div className="mx-auto">
          <Button variant='secondary my-3' className='w-100'>{`게시물을 모두 로드하였습니다.`}</Button>
        </div>
      );
    }

    return (
      <div className="mx-auto">
        <Button variant='outline-primary my-3' className='w-100' onClick={OnClickLoadMore}>{`게시물 더 불러오기`}</Button>
      </div>
    );
  }

  // 전체를 감싸는 div 정의.
  let pDivSetting = {};
  pDivSetting.className = "";
  pDivSetting.style = {};
  if (bIsLimitView) {
    // 제한된 뷰 + 스크롤 표시
    pDivSetting.className = "overflow-scroll";
    pDivSetting.style = { maxHeight: "20rem" };
  }

  return (
    <>
      <Card>
        <Card.Header>공지사항</Card.Header>
        <Card.Body>
          <div className={pDivSetting.className} style={pDivSetting.style}>
            {RefreshUI_Posts()}
            {RefreshUI_ButtonMore()}
          </div>
        </Card.Body>
      </Card>

      {/* Modal */}
      <UIGMNoticeModal
        pMaind={pMaind}
        bShow={pDataModal.show}
        funcOnHide={OnHideModal}
        pData={pDataModal.pData}
      >
      </UIGMNoticeModal>
    </>
  );
}

function UIGMNoticeModal({
  pMaind,
  bShow,
  funcOnHide,
  pData,
}) {
  function OnHide() {
    funcOnHide();
  }

  function OnClickClose() {
    funcOnHide();
  }

  if (!pData) {
    return (
      <>
      </>);
  }

  let szFontSize = "0.9rem";

  let pCreateTime = pData.createtime.toDate();

  return (
    <Modal
      show={bShow}
      animation={false}
      onHide={OnHide}
    >
      <Modal.Header closeButton className="px-2 py-2">
        <Modal.Title>{`${pData.title}`}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="px-2 py-2">
        <div className="overflow-scroll" style={{ maxHeight: "65vh" }}>
          <p style={{ fontSize: szFontSize }}>{`작성시간 : ${Util.GetTimeDiffString(pCreateTime)}`}</p>
          <Card className="mb-3">
            <Card.Header>내용</Card.Header>
            <Card.Body>
              {/* whiteSpace: pre-wrap => 텍스트 줄바꿈, 연속 공백 유지 */}
              <Card.Text style={{ fontSize: "0.9rem", whiteSpace: "pre-wrap" }}>
                {pData.content}
              </Card.Text>
            </Card.Body>
          </Card>
        </div>

        <Button variant="danger" className='w-100 mb-1' onClick={OnClickClose}>닫기</Button>
      </Modal.Body>
    </Modal>
  );
}

export default ComGMNoticeView;