import { useNavigate, useParams, useSearchParams } from "react-router-dom";

// bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, ButtonGroup, Card, Container, Dropdown, DropdownButton, Form, InputGroup, Modal } from "react-bootstrap";

import { Children, useEffect, useRef, useState } from "react";
import { ComUserDeckPostView } from "../mycom/ComUserDeckPostView";
import { CONST, pSheetColumn, pSheetEnum } from "../mod/CardClientSheetData";
import GameUtil from "../mod/GameUtil";
import Util from "../mod/Util";
import { ComUtilFloatButton, CUtilFloatButtonParam, EnumUtilFloatButtonType } from "../mycom/ComUtilFloatButton";
import { CCardDetailModalButtonParam, ComCardDetailModal } from "../mycom/ComCardDetailModal";
import ComUserDeckSummary from "../mycom/ComUserDeckSummary";

const EnumUIEventID = {
  ON_CLICK_WRITE: 101,
  ON_CLICK_LOAD_MORE: 102,
  ON_CLICK_SEARCH: 103,

  // POST
  POST_ON_CLICK_DELETE: 202,
  POST_ON_SHOW_DETAIL: 203,
  POST_ON_CLICK_CARD: 204,

  // MODAL
  MODAL_ON_CLOSE: 301,
};
Object.freeze(EnumUIEventID);

const ONEPAGE_DATA_LIMIT = 10;

function PageUserDeckBoard({ pMaind }) {
  // query param
  const [pURLSearchParams, funcSetURLSearchParams] = useSearchParams();

  // 
  let pGlobalSetting = {
    postCardSize: "40rem",

    fontSizeS: "0.8rem",
    fontColorS: "grey",

    fontSizeL: "1.2rem",
    fontColorL: "black",
  };

  // 
  const pNavigate = useNavigate();

  // 
  const [pDBResult, funcDBResult] = useState({
    TotalCount: 0,
    Datas: [],
    pOrderValueEnd: null,
    bLastFlag: false,

    Clear: function () {
      this.TotalCount = 0;
      this.Datas = [];
      this.pOrderValueEnd = null;
      this.bLastFlag = false;
    },
  });

  // DB 요청 처리 번호표
  let rDBRequestControl = useRef({
    pMap: new Map(),
    IDCount: 1,

    Register: function () {
      let nID = this.IDCount;
      this.IDCount++;

      if (!this.pMap.has(nID)) {
        this.pMap.set(nID, nID);
      }
      return nID;
    },
    UnRegister: function (nID) {
      if (this.pMap.has(nID)) {
        this.pMap.delete(nID);
      }
    },
    Clear: function () {
      this.pMap.clear();
    }
  });

  let [pEventListeners, funcSetEventListener] = useState({
    pMap: new Map(),
    IDCount: 1,

    Register: function (cb) {
      let nID = this.IDCount;
      this.IDCount++;

      if (!this.pMap.has(nID)) {
        this.pMap.set(nID, cb);
        console.log(`Register Listener : ${nID}`);
      }
      return nID;
    },
    UnRegister: function (nID) {
      if (this.pMap.has(nID)) {
        this.pMap.delete(nID);
        console.log(`!!UnRegister Listener : ${nID}`);
      }
    },
  });



  // key : szNumID / value : pCardData
  let pMapCardDataCache = useRef(new Map());

  // 카드 상세정보 모달창 용 
  let [pDetailData, funcSetDetailData] = useState({
    show: false,
    pData: null,
    arrButtonParams: [
      new CCardDetailModalButtonParam("관련 유저덱 검색", "outline-primary", OnClickSearchUserDeck),
      new CCardDetailModalButtonParam("닫기", "danger", OnClickModalClose),
    ]
  });
  function OnClickSearchUserDeck(pCardData) {
    let pSearchParams = new URLSearchParams();
    pSearchParams.append("card", pCardData.NumID);
    pNavigate(`/userdeck/board?${pSearchParams.toString()}`);

    // 닫기
    pDetailData.show = false;
    funcSetDetailData({ ...pDetailData });
  }

  function OnClickModalClose(pCardData) {
    // pCardData 실제로 쓰진 않는다.

    // 닫기
    pDetailData.show = false;
    funcSetDetailData({ ...pDetailData });
  }



  // query param 대응
  useEffect(() => {
    function _get_condition_from_query_() {
      console.log(`_get_condition_from_query_ : ${pURLSearchParams.toString()}`);

      let arrRetVal = [];

      pURLSearchParams.forEach((v, k) => {
        if (!pSheetColumn.DBColumnUserDeck.mapQuery.has(k)) {
          return;
        }

        let pDataDBColumn = pSheetColumn.DBColumnUserDeck.mapQuery.get(k);
        if (!pDataDBColumn) {
          return;
        }

        let pValue = v;
        if (pDataDBColumn.DataType !== "string") {
          pValue = parseInt(pValue);
        }

        arrRetVal.push({ pDataDBColumn: pDataDBColumn, Value: pValue });
      })

      return arrRetVal;
    }

    funcSetSearchCondition(_get_condition_from_query_());
  }, [pURLSearchParams]);

  const [arrSearchCondition, funcSetSearchCondition] = useState([]);
  useEffect(() => {

    // 검색 조건이 바뀌었다!

    (async function () {
      // 기존 DBResult 초기화
      pDBResult.Clear();

      // 검색 다시 실행
      await ProcDBSearch();
    })();

    return () => {
      // Unmount 시 처리
      // 처리 번호표 전부 삭제
      rDBRequestControl.current.Clear();
    };

  }, [arrSearchCondition]);

  async function ProcDBSearch() {
    // arrSearchCondition 을 기반으로 검색을 진행하고, 결과를 "추가"한다
    // 이 전단계에서 arrSearchCondition 의 세팅이 끝났어야 한다.

    let arrQueries = [];

    // 반드시 CreateTime 정렬을 맨앞에 둘것. 순서 꼬이면 색인 다시해야 할수도 있다.
    let szColumnNameOrder = "CreateTime";
    arrQueries.push(pMaind.pFbCtrl.CreateSearchQuery(szColumnNameOrder, CONST._DB_PROCTYPE.ORDER_BY, "desc"));

    // Param 으로 넘어온 조건값 추가
    arrSearchCondition.forEach((e) => {
      let pRetVal = pMaind.pFbCtrl.CreateSearchQuery(e.pDataDBColumn.DBColumnName, e.pDataDBColumn.DBProcType, e.Value);
      if (!pRetVal) {
        return;
      }

      arrQueries.push(pRetVal);
    });

    // DB 요청 전에 미리 결과 처리 번호표 등록
    let nReqID = rDBRequestControl.current.Register();

    let pSearchResult = await pMaind.pFbCtrl.GetSearchedDocs(GameUtil.GetDBUserDeckCollection(),
      arrQueries,
      ONEPAGE_DATA_LIMIT,
      pDBResult.TotalCount,
      szColumnNameOrder,
      pDBResult.pOrderValueEnd);

    // DB 요청 결과 이후에도 요청 관리에 ID가 있으면 실행
    if (!rDBRequestControl.current.pMap.has(nReqID)) {
      return;
    }

    // 현재 데이터의 뒤에 추가한다
    pDBResult.Datas.push(...pSearchResult.arrResult);
    pDBResult.TotalCount = pSearchResult.TotalCount;
    pDBResult.pOrderValueEnd = pSearchResult.pOrderValueEnd;
    pDBResult.bLastFlag = pSearchResult.arrResult.length <= 0;
    funcDBResult({ ...pDBResult });
  }


  // Event Binding
  async function OnDispatchEvent(nEventID, pEventParam) {
    // main
    switch (nEventID) {
      case EnumUIEventID.ON_CLICK_WRITE: {
        pNavigate("/userdeck/write");
        break;
      }

      // 

      case EnumUIEventID.ON_CLICK_LOAD_MORE: {
        ProcDBSearch();
        break;
      }

      case EnumUIEventID.ON_CLICK_SEARCH: {
        let arrInputSearch = [...pEventParam.arrConditions];
        let pNewParams = new URLSearchParams();
        arrInputSearch.forEach((e) => {
          pNewParams.set(e.pDataDBColumn.Query, String(e.Value));
        });

        funcSetURLSearchParams(pNewParams);
        break;
      }

      case EnumUIEventID.POST_ON_CLICK_DELETE: {
        (async function () {
          let szCollection = GameUtil.GetDBUserDeckCollection();
          let szDocID = pEventParam.szDocID;
          let szTitle = pEventParam.szTitle;

          let isConfirm = window.confirm(`[${szTitle}] 유저덱을 삭제하시겠습니까?`);
          if (!isConfirm) {
            return;
          }

          try {
            await pMaind.pFbCtrl.DeleteDocSimple(szCollection, szDocID);

            alert(`삭제 완료했습니다.`);

            // 삭제 완료. 새로고침
            window.location.reload();
          }
          catch (error) {
            alert(`삭제 실패했습니다. (${error.message})`);
          }
        })();

        break;
      }

      case EnumUIEventID.POST_ON_CLICK_CARD: {
        let szNumID = String(pEventParam.NumID);
        if (!szNumID) {
          break;
        }

        (async function () {
          let szCollection = GameUtil.GetDBCardListCollection();
          let szDocID = szNumID;

          try {
            let pCardData;
            if (pMapCardDataCache.current.has(szDocID)) {
              pCardData = pMapCardDataCache.current.get(szDocID);
            }
            else {
              pCardData = await pMaind.pFbCtrl.GetDocSimple(szCollection, szDocID);
            }

            if (!pCardData) {
              return;
            }

            if (!pMapCardDataCache.current.has(szDocID)) {
              pMapCardDataCache.current.set(szDocID, pCardData);
            }

            pDetailData.show = true;
            pDetailData.pData = pCardData;
            funcSetDetailData({ ...pDetailData });
          }
          catch (error) {
            // TODO : 실패
            console.error(error);
          }
        })();

        break;
      }

      default: {
        break;
      }
    }

    pEventListeners.pMap.forEach((pListener, _k) => {
      if (pListener) {
        pListener(nEventID, pEventParam);
      }
    });
  }

  const arrUtilButtonParam = [
    new CUtilFloatButtonParam(EnumUtilFloatButtonType.SCROLL_BOTTOM),
    new CUtilFloatButtonParam(EnumUtilFloatButtonType.SCROLL_TOP),
  ];

  return (
    <>
      <Row className="my-3">
        <Col>
          <h2 className="text-center">유저덱</h2>
        </Col>
      </Row>
      <UIUserDeckBasicMenu pMaind={pMaind} pGlobalSetting={pGlobalSetting} funcDispatch={OnDispatchEvent}></UIUserDeckBasicMenu>
      <UIPostSearch funcDispatch={OnDispatchEvent}></UIPostSearch>
      <UISearchCondition arrConditions={arrSearchCondition}></UISearchCondition>
      <UIUserDeckPostSheet pMaind={pMaind} pGlobalSetting={pGlobalSetting} pDBResult={pDBResult} funcDispatch={OnDispatchEvent}></UIUserDeckPostSheet>

      {/*  */}
      <ComUtilFloatButton arrParams={arrUtilButtonParam}></ComUtilFloatButton>

      {/* modal */}
      <ComCardDetailModal
        pMaind={pMaind}
        pDetailData={pDetailData}
        funcSetDetailData={funcSetDetailData}
        arrButtonParams={pDetailData.arrButtonParams}
        funcOnHide={OnClickModalClose}
      >
      </ComCardDetailModal>
    </>
  );
}

function UIPostSearch({
  funcDispatch
}) {

  // key : column KIND, value : enum KIND
  let [pMapOption, funcSetMapOption] = useState(new Map());
  let [szUserName, funcSetUserName] = useState("");

  // 테이블 안만들었다. 나중에 더 추가할일 있으면 만들어라.
  let PostSearch = {};
  PostSearch.arr = [{ KIND: 1, TYPE: "UserDeckSoulType", UIPriority: 10, DBColumnKIND: 201 }];
  PostSearch.mapKIND = new Map();
  PostSearch.arr.forEach((e) => PostSearch.mapKIND.set(e.KIND, e));

  function OnKeyDown(e) {
    if (e.key === 'Enter') {
      ExecSearch();
    }
  }

  function OnClickSearch() {
    ExecSearch();
  }

  function ExecSearch() {
    let arrConditions = [];

    // 일반 옵션 선택 체크
    pMapOption.forEach((nEnumKIND, nColumnKIND) => {
      let pDataPostSearch = PostSearch.mapKIND.get(nColumnKIND);
      if (!pDataPostSearch) {
        return;
      }

      let pDataDBColumn = pSheetColumn.DBColumnUserDeck.mapKIND.get(pDataPostSearch.DBColumnKIND);
      if (!pDataDBColumn) {
        return;
      }

      // 정보 추가 (DB 컬럼 + 선택값)
      arrConditions.push({ pDataDBColumn: pDataDBColumn, Value: nEnumKIND });
    });

    // 이름 검색 체크
    // 공백을 제외시킨다 (공백으로 분리(split) + join으로 다시 합치기)
    (() => {

      // 전처리 : 공백 제거
      let szInputName = szUserName.split(' ').join('');
      if (szInputName !== szUserName) {
        funcSetUserName(szInputName);
      }

      szInputName = szInputName.split(' ').join('');
      if (!szInputName) {
        return;
      }

      let pDataDBColumn = pSheetColumn.DBColumnUserDeck.mapKIND.get(101);
      if (!pDataDBColumn) {
        return;
      }

      // 정보 추가 (DB 컬럼 + 입력값)
      arrConditions.push({ pDataDBColumn: pDataDBColumn, Value: szInputName });
    })();

    // 검색 진행하기 전에 UI 리셋
    funcSetUserName("");
    funcSetMapOption(new Map());

    // 검색 진행
    funcDispatch(EnumUIEventID.ON_CLICK_SEARCH, { arrConditions: arrConditions });
  }

  function RefreshUI_Options() {

    function __refresh_ui_dropdowns__() {
      let arrRetVal = [];


      let arrColumnsCopy = [...PostSearch.arr];
      arrColumnsCopy = arrColumnsCopy.sort((a, b) => a.UIPriority - b.UIPriority);
      arrColumnsCopy.forEach((pColumnData) => {
        if (!pSheetColumn.DBColumnUserDeck.mapKIND.has(pColumnData.DBColumnKIND)) {
          return;
        }

        let pDataDBColumn = pSheetColumn.DBColumnUserDeck.mapKIND.get(pColumnData.DBColumnKIND);
        let nColumnKIND = pColumnData.KIND;
        let szColumnDataType = pDataDBColumn.DataType;
        let szColumnName = pDataDBColumn.UIName; // 컬럼 타입 분류 자체의 이름

        // 선택된게 없을때의 기본값
        let bIsSelected = false;
        let szTitle = szColumnName; // 컬럼 타입 분류 자체의 이름

        if (pMapOption.has(nColumnKIND)) {
          let nSelectedEnumKIND = pMapOption.get(nColumnKIND);
          let pSelectedEnumData = pSheetEnum[`${szColumnDataType}`].mapKIND.get(nSelectedEnumKIND);
          if (pSelectedEnumData) {
            bIsSelected = true;
            szTitle = `${szColumnName} (${pSelectedEnumData.UIName})`;
          }
          else {
            // TODO : 선택되긴 했는데 Enum 테이블에 없다...어찌해야 되나
          }
        }

        function __create_dropdown_items__() {

          let arrEnumTable = pSheetEnum[`${szColumnDataType}`].arr;
          let arrEnums = [...arrEnumTable];
          arrEnums.sort((a, b) => a.UIPriority - b.UIPriority);

          let arr = [];
          arr.push(<Dropdown.Item eventKey="-1">{szColumnName}</Dropdown.Item>);
          arr.push(<Dropdown.Divider />);
          arrEnums.forEach((_e) => {
            arr.push(<Dropdown.Item eventKey={_e.KIND}>{_e.UIName}</Dropdown.Item>);
          });

          return Children.toArray(arr);
        }

        function __on_select__(eventKey) {
          // eventKey = Enum KIND
          console.log(`eventKey : ${eventKey}`);

          let nEnumKIND = parseInt(eventKey);
          if (nEnumKIND === -1) {
            // 옵션 삭제
            if (pMapOption.has(nColumnKIND)) {
              pMapOption.delete(nColumnKIND);
              funcSetMapOption(new Map(pMapOption));
            }
            else {
              // 이미 선택 안했으면 처리 필요없음
            }
          }
          else {
            let mapEnumKIND = pSheetEnum[`${szColumnDataType}`].mapKIND;
            let pSelectedEnumData = mapEnumKIND.get(nEnumKIND);
            if (!pSelectedEnumData) {
              console.error(`__on_select__ : ${szColumnDataType} not contains ${nEnumKIND}`);
              return;
            }

            // 옵션 추가 혹은 수정
            pMapOption.set(nColumnKIND, nEnumKIND);
            funcSetMapOption(new Map(pMapOption));
          }
        }

        arrRetVal.push(
          <Col xs={12} sm={4} className="px-0 mb-1">
            <DropdownButton
              className='w-100 h-100 mb-0'
              as={ButtonGroup}
              id={`dropdown-button-drop`}
              size="sm"
              variant={bIsSelected ? "primary" : "outline-primary"}
              title={szTitle}
              onSelect={__on_select__}
            >
              {__create_dropdown_items__()}
            </DropdownButton>
          </Col>
        );
      });

      return (Children.toArray(arrRetVal));
    }

    return (
      <>
        {__refresh_ui_dropdowns__()}
        <Col xs={12} sm={8} className="px-0">
          <InputGroup>
            <Form.Control className="py-1" value={szUserName} placeholder="유저 이름" aria-label="inputUserName" onKeyDown={OnKeyDown} onChange={(e) => funcSetUserName(e.target.value)} />
            <Button variant="outline-primary" className='px-3' onClick={OnClickSearch}>검색</Button>
          </InputGroup>
        </Col>
      </>
    );
  }

  return (
    <Container>
      <Row xs={1} sm={2} className="px-1 mb-1">
        {RefreshUI_Options()}
      </Row>
    </Container>
  );
}

function UISearchCondition({
  arrConditions
}) {

  let arr = [];
  if (arrConditions) {
    arrConditions.forEach((e) => {
      let pDataDBColumn = e.pDataDBColumn;
      let pValue = e.Value;

      let szUIName = pDataDBColumn.UIName;
      let szValue = String(pValue);
      if (pDataDBColumn.UIShowValue) {
        if (pDataDBColumn.DataType !== "string") {
          pValue = parseInt(pValue);

          if (pDataDBColumn.DataType !== "int") {
            let pEnumInfo = pSheetEnum[`${pDataDBColumn.DataType}`].mapKIND.get(pValue);
            if (pEnumInfo) {
              szValue = pEnumInfo.UIName;
            }
          }
        }
      }

      let szCondition = `${szUIName}`;
      if (pDataDBColumn.UIShowValue) {
        szCondition = `${szUIName} (${szValue})`;
      }

      arr.push(
        <Button variant='primary' className='me-1 mb-1 px-2 py-1' style={{ fontSize: "0.8rem" }}>
          {szCondition}
        </Button>
      );
    });
  }

  if (arr.length <= 0) {
    arr.push(<Card.Text>조건 없음</Card.Text>);
  }

  return (
    <Card className='mb-3'>
      <Card.Header>현재 검색 조건</Card.Header>
      <Card.Body className='px-2 py-2'>
        {Children.toArray(arr)}
      </Card.Body>
    </Card>
  );
}

function UIUserDeckBasicMenu({
  pMaind,
  pGlobalSetting,
  funcDispatch
}) {

  function OnClickWrite() {
    funcDispatch(EnumUIEventID.ON_CLICK_WRITE);
  }

  return (
    <div className="mx-auto">
      <div className="d-flex justify-content-end mb-3">
        <Button variant="outline-primary" onClick={OnClickWrite}>유저덱 작성</Button>
      </div>
    </div>
  );
}

function UIUserDeckPostSheet({
  pMaind,
  pGlobalSetting,
  pDBResult,
  funcDispatch
}) {

  let [pDataPostModal, funcDataPostModal] = useState({
    show: false,
    pTargetPostData: null,
  });
  function OnHidePostModal() {
    pDataPostModal.show = false;
    pDataPostModal.pTargetPostData = null;
    funcDataPostModal({ ...pDataPostModal });
  }

  let nCardImgRate = 1;
  let pCardUISetting = {
    // 
    cImg: "",
    stImg: { width: `${100 * nCardImgRate}px`, height: `${142 * nCardImgRate}px`, background: `url("${GameUtil.GetImageDefaultPathSmall()}")` },
  };

  function OnClickDetail(pPostData) {
    pDataPostModal.show = true;
    pDataPostModal.pTargetPostData = pPostData;
    funcDataPostModal({ ...pDataPostModal });
  }

  function OnClickDelete(pPostData) {
    funcDispatch(EnumUIEventID.POST_ON_CLICK_DELETE, { szDocID: pPostData.id, szTitle: pPostData.Title });
  }

  function OnClickLoadMore() {
    funcDispatch(EnumUIEventID.ON_CLICK_LOAD_MORE);
  }

  function OnClickCardImg(nNumID) {
    funcDispatch(EnumUIEventID.POST_ON_CLICK_CARD, { NumID: nNumID });
  }

  function RefreshUI_TotalCount() {
    return (
      <p className='w-100 text-end' style={{ fontSize: "0.8rem" }}>{`총 ${pDBResult.TotalCount}개의 데이터 (${pDBResult.Datas.length}개 표시중)`}</p>
    );
  }

  function RefreshUI_Sheet() {

    let arrRetval = [];

    function __ui_sheet_item_title__(pPostData) {
      let szSoulType = "불명";
      if (pSheetEnum[`UserDeckSoulType`].mapKIND.has(pPostData.SoulType)) {
        let pEnum = pSheetEnum[`UserDeckSoulType`].mapKIND.get(pPostData.SoulType);
        if (pEnum) {
          szSoulType = pEnum.UIName;
        }
      }

      let szTitle = pPostData.Title;
      if (!GameUtil.IsValidContent(szTitle)) {
        szTitle = "(INVALID TEXT)";
      }

      return (
        <Card.Title
          className="mb-1"
          style={{ fontSize: pGlobalSetting.fontSizeL, color: pGlobalSetting.fontColorL, fontWeight: "bold" }}
        >
          {`[${szSoulType}] ${szTitle}`}
        </Card.Title>
      );
    }

    function __ui_sheet_item_default__(pPostData) {

      let pCreateTime = pPostData.CreateTime.toDate();

      return (
        <>
          <Card.Text className="mb-0" style={{ color: pGlobalSetting.fontColorS, fontSize: pGlobalSetting.fontSizeS }}>
            {`${pPostData.UserName} (${Util.GetTimeDiffString(pCreateTime)})`}
          </Card.Text>
        </>
      );
    }

    function __ui_sheet_item_cards__(pPostData) {
      let arr = [];

      let arrData = [];
      for (let i = 0; i < pPostData.Deck.length; ++i) {
        if (pPostData.Deck[i].KeyCard !== 1) {
          continue;
        }

        arrData.push(pPostData.Deck[i]);
      }

      // 정렬
      arrData = arrData.sort(GameUtil.SortUIUserDeckCard);

      for (let i = 0; i < arrData.length; ++i) {
        let nNumID = arrData[i].NumID;

        let nCategory = GameUtil.NumIDHelper.GetCategory(nNumID);
        let nKIND = GameUtil.NumIDHelper.GetKIND(nNumID);

        let szImagePath = GameUtil.GetImagePathSmall(nCategory, nKIND);
        szImagePath = pMaind.pFbCtrl.GetStorageURL(szImagePath);

        arr.push(
          <Card.Img
            loading="lazy"    //이미지가 뷰포트의 일정 거리 안으로 들어와야 불러옵니다.
            decoding="async"  //다른 콘텐츠의 표시 지연을 피하기 위해 이미지를 비동기적으로 디코딩 합니다.  
            src={szImagePath} onClick={() => OnClickCardImg(nNumID)} variant="top" className={pCardUISetting.cImg} style={pCardUISetting.stImg} />);

        if (arr.length >= 10) {
          break;
        }
      }

      if (arr.length <= 0) {
        // 이러면 안되지만 정말 비어있을 경우
        arr.push(<div className={pCardUISetting.cImg} style={pCardUISetting.stImg} />);
      }

      return (
        <div className="mb-1">
          {Children.toArray(arr)}
        </div>
      );
    }

    function __ui_sheet_item_buttons__(pPostData) {

      return (
        <Button variant="outline-primary" className="py-1 w-100" style={{ fontSize: pGlobalSetting.fontSizeS }} onClick={() => { OnClickDetail(pPostData) }}>상세 보기</Button>
      );
    }

    if (pDBResult.Datas) {
      pDBResult.Datas.forEach((pPostData) => {
        arrRetval.push(
          <Card className="mb-3 mx-auto">
            <Card.Header onClick={() => { OnClickDetail(pPostData) }}>
              {__ui_sheet_item_title__(pPostData)}
              {__ui_sheet_item_default__(pPostData)}
            </Card.Header>
            <Card.Body className="ps-2 pe-1 pt-1 pb-2">
              
              <ComUserDeckSummary
                arrDeck={pPostData.Deck}
                bShowCategory={true}
              >
              </ComUserDeckSummary>

              {__ui_sheet_item_cards__(pPostData)}
              {__ui_sheet_item_buttons__(pPostData)}
            </Card.Body>
          </Card>
        );
      });
    }

    return (
      Children.toArray(arrRetval)
    );
  }

  function RefreshUI_ButtonMore() {
    if (pDBResult.bLastFlag) {
      return (
        <div className="mx-auto">
          <Button variant='secondary my-3' className='w-100' size='lg'>{`게시물을 모두 로드하였습니다.`}</Button>
        </div>
      );
    }

    return (
      <div className="mx-auto">
        <Button variant='outline-primary my-3' className='w-100' size='lg' onClick={OnClickLoadMore}>{`게시물 더 불러오기`}</Button>
      </div>
    );
  }

  return (
    <>
      {RefreshUI_TotalCount()}
      {RefreshUI_Sheet()}
      {RefreshUI_ButtonMore()}

      {/* modal */}
      <UIPostDetailModal
        pMaind={pMaind}
        pPostData={pDataPostModal.pTargetPostData}
        bShow={pDataPostModal.show}
        funcOnHide={OnHidePostModal}
        funcDispatch={funcDispatch}
      >
      </UIPostDetailModal>
    </>
  );
}

function UIPostDetailModal({
  pMaind,
  pPostData,
  bShow,
  funcOnHide,
  funcDispatch
}) {

  function OnClickDelete() {
    funcDispatch(EnumUIEventID.POST_ON_CLICK_DELETE, { szDocID: pPostData.id, szTitle: pPostData.Title });
  }

  function OnClickCardImg(nNumID) {
    funcDispatch(EnumUIEventID.POST_ON_CLICK_CARD, { NumID: nNumID });
  }

  function RefreshUI_Buttons() {
    let arrRetVal = [];

    arrRetVal.push(
      <Button variant="danger" className='w-100 mb-1' onClick={funcOnHide}>닫기</Button>
    );

    return (
      Children.toArray(arrRetVal)
    );
  }

  return (
    <Modal
      show={bShow}
      animation={false}
      onHide={funcOnHide}
      size="lg"
    >
      <Modal.Header closeButton className='px-2 py-1'>
      </Modal.Header>

      <Modal.Body className="px-0">
        <div className="mb-3 mx-auto">
          <ComUserDeckPostView
            pMaind={pMaind}
            pPostData={pPostData}
            bIsLimitView={true}
            cbOnDelete={OnClickDelete}
            cbOnList={funcOnHide}
            cbOnClickCardImg={OnClickCardImg}
            cbOnPostSearchByUID={() => { funcOnHide() }}
          >
          </ComUserDeckPostView>
        </div>

        {RefreshUI_Buttons()}
      </Modal.Body>
    </Modal>
  );
}

export { PageUserDeckBoard };