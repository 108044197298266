// bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import { Container, Image, Navbar } from 'react-bootstrap';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

// 
import { useState, Children, useEffect, createRef, useRef } from "react";

// 
import { pSheetEnum, pSheetColumn, CONST } from '../mod/CardClientSheetData';
import { CardColumnParser } from '../mod/CardColumnParser';
import ComCardDetailModal from './ComCardDetailModal';
import GameUtil from '../mod/GameUtil';
import { ComExtraSearchModal } from './ComExtraSearchModal';
import Util from '../mod/Util';

// enum
const EnumUIEventID = {
  ON_CLICK_SEARCH: 101,
  ON_CLICK_OPEN_EXTRA_SEARCH: 103,

  SHEET_ON_CLICK_ITEM: 201,
  SHEET_ON_CLICK_LOAD_MORE: 202,
};

const ONEPAGE_DATA_LIMIT = 30;

function ComCardList({
  pMaind,
  bIsLimitView,
  cbOnClickItem,
  cbOnClickSearch,
  arrSearchCondition,
}) {

  let [bShowExtraSearchModal, funcSetExtraSearchModal] = useState(false);

  let [pDBResult, funcDBResult] = useState({
    TotalCount: 0,
    Datas: [],
    pOrderValueEnd: null,

    Clear: function () {
      this.TotalCount = 0;
      this.Datas = [];
      this.pOrderValueEnd = null;
    },
  });

  // DB 요청 처리 번호표
  let rDBRequestControl = useRef({
    pMap: new Map(),
    IDCount: 1,

    Register: function () {
      let nID = this.IDCount;
      this.IDCount++;

      if (!this.pMap.has(nID)) {
        this.pMap.set(nID, nID);
      }
      return nID;
    },
    UnRegister: function (nID) {
      if (this.pMap.has(nID)) {
        this.pMap.delete(nID);
      }
    },
    Clear: function () {
      this.pMap.clear();
    }
  });

  let rEventListeners = useRef({
    pMap: new Map(),
    IDCount: 1,

    Register: function (cb) {
      let nID = this.IDCount;
      this.IDCount++;

      if (!this.pMap.has(nID)) {
        this.pMap.set(nID, cb);
        console.log(`Register Listener : ${nID}`);
      }
      return nID;
    },
    UnRegister: function (nID) {
      if (this.pMap.has(nID)) {
        this.pMap.delete(nID);
        console.log(`!!UnRegister Listener : ${nID}`);
      }
    },
  });

  useEffect(() => {

    // 검색 조건이 바뀌었다!

    (async function () {
      // 기존 DBResult 초기화
      pDBResult.Clear();

      // 검색 다시 실행
      await ProcDBSearch();
    })();

    return () => {
      // Unmount 시 처리
      // 처리 번호표 전부 삭제
      rDBRequestControl.current.Clear();
    };

  }, [arrSearchCondition]);

  async function ProcDBSearch() {

    // arrSearchCondition 을 기반으로 검색을 진행하고, 결과를 "추가"한다
    // 이 전단계에서 arrSearchCondition 의 세팅이 끝났어야 한다.

    let arrQueries = [];

    // 반드시 NumID 정렬을 맨앞에 둘것. 순서 꼬이면 색인 다시해야 할수도 있다.
    let szColumnNameOrder = "NumID";
    arrQueries.push(pMaind.pFbCtrl.CreateSearchQuery(szColumnNameOrder, CONST._DB_PROCTYPE.ORDER_BY, "desc"));

    // Param 으로 넘어온 조건값 추가
    let arrSearchCopy = [...arrSearchCondition];
    // Priority 로 정렬
    arrSearchCopy = arrSearchCopy.sort((a, b) => a.pDataDBColumn.DBSearchPriority - b.pDataDBColumn.DBSearchPriority);
    // 쿼리문 생성
    arrSearchCopy.forEach((e) => {
      let pRetVal = pMaind.pFbCtrl.CreateSearchQuery(e.pDataDBColumn.DBColumnName, e.pDataDBColumn.DBProcType, e.Value);
      if (!pRetVal) {
        return;
      }

      arrQueries.push(pRetVal);
    });

    // DB 요청 전에 미리 결과 처리 번호표 등록
    let nReqID = rDBRequestControl.current.Register();

    // DB 요청 (비동기)
    let pSearchResult = await pMaind.pFbCtrl.GetSearchedDocs(GameUtil.GetDBCardListCollection(),
      arrQueries,
      ONEPAGE_DATA_LIMIT,
      pDBResult.TotalCount,
      szColumnNameOrder,
      pDBResult.pOrderValueEnd);

    // DB 요청 결과 이후에도 요청 관리에 ID가 있으면 실행
    if (!rDBRequestControl.current.pMap.has(nReqID)) {
      return;
    }

    // 처리 번호표 삭제
    rDBRequestControl.current.UnRegister(nReqID);

    // 결과 처리
    pDBResult.Datas.push(...pSearchResult.arrResult);
    pDBResult.TotalCount = pSearchResult.TotalCount;
    pDBResult.pOrderValueEnd = pSearchResult.pOrderValueEnd;
    funcDBResult({ ...pDBResult });
  }

  // Event Binding
  async function OnDispatchEvent(nEventID, pEventParam) {
    // main
    switch (nEventID) {
      case EnumUIEventID.ON_CLICK_SEARCH: {
        cbOnClickSearch([...pEventParam.arrConditions]);
        break;
      }

      case EnumUIEventID.ON_CLICK_OPEN_EXTRA_SEARCH: {
        funcSetExtraSearchModal(true);
        break;
      }

      case EnumUIEventID.SHEET_ON_CLICK_ITEM: {
        let pData = pEventParam.pData;
        if (!pData) {
          break;
        }
        cbOnClickItem(pData);
        break;
      }

      case EnumUIEventID.SHEET_ON_CLICK_LOAD_MORE: {
        ProcDBSearch();
        break;
      }
    }

    rEventListeners.current.pMap.forEach((pListener, _k) => {
      if (pListener) {
        pListener(nEventID, pEventParam);
      }
    });
  }


  return (
    <>
      <UICardSearch funcDispatch={OnDispatchEvent}> </UICardSearch>

      <UICardCondition arrConditions={arrSearchCondition}></UICardCondition>

      <UICardSheet
        pMaind={pMaind}
        pDBResult={pDBResult}
        bIsLimitView={bIsLimitView}
        funcDispatch={OnDispatchEvent}
      >
      </UICardSheet>

      <ComExtraSearchModal
        pMaind={pMaind}
        bShow={bShowExtraSearchModal}
        funcSetShow={funcSetExtraSearchModal}
        cbOnOK={(arrConditions) => { OnDispatchEvent(EnumUIEventID.ON_CLICK_SEARCH, { arrConditions: arrConditions }) }}
        arrDefaultConditions={arrSearchCondition}
      >
      </ComExtraSearchModal>
    </>
  );
}

function UICardCondition({
  arrConditions,
}) {

  let arr = [];
  if (arrConditions) {
    arrConditions.forEach((e) => {
      let pDataDBColumn = e.pDataDBColumn;
      let pValue = e.Value;

      let szUIName = pDataDBColumn.UIName;
      let szValue = String(pValue);
      if (pDataDBColumn.DataType !== "string") {
        pValue = parseInt(pValue);

        if (pDataDBColumn.DataType !== "int") {
          let pEnumInfo = pSheetEnum[`${pDataDBColumn.DataType}`].mapKIND.get(pValue);
          if (pEnumInfo) {
            szValue = pEnumInfo.UIName;
          }
        }
      }

      arr.push(
        <Button variant='primary' className='me-1 mb-1 px-2 py-1' style={{ fontSize: "0.8rem" }}>
          {`${szUIName} (${szValue})`}
        </Button>
      );
    });
  }

  if (arr.length <= 0) {
    arr.push(<Card.Text>조건 없음</Card.Text>);
  }

  return (
    <Card className='mb-3'>
      <Card.Header>현재 검색 조건</Card.Header>
      <Card.Body className='px-2 py-2'>
        {Children.toArray(arr)}
      </Card.Body>
    </Card>
  );
}

function UICardSearch({
  funcDispatch
}) {

  // state, ref
  const [szDefaultCardName, funcSetDefaultCardName] = useState("");

  function OnKeyDown(e) {
    // 의도 : 검색란에 키워드 입력하고 Enter 누르면 검색 기능 실행
    // Button 말고 Input 에 달아야 의도대로 동작.

    if (e.key === 'Enter') {
      ExecDefaultSearch();
    }
  }

  function OnClickDefaultSearch() {
    ExecDefaultSearch();
  }

  function ExecDefaultSearch() {

    let arrConditions = [];

    // 이름 검색 체크
    // 공백을 제외시킨다 (공백으로 분리(split) + join으로 다시 합치기)
    (() => {

      // 전처리 : 공백 제거
      let szInputName = szDefaultCardName.split(' ').join('');
      if (szInputName !== szDefaultCardName) {
        funcSetDefaultCardName(szInputName);
      }

      szInputName = szInputName.split(' ').join('');
      if (!szInputName) {
        return;
      }

      let pDataDefaultSearch = pSheetColumn.DefaultSearch.mapKIND.get(CONST._INT.DEFAULT_SEARCH_NAME_KIND);
      if (!pDataDefaultSearch) {
        return;
      }

      let pDataDBColumn;
      let [isDoubleQuotaMark, szResultDoubleQuota] = Util.GetDoubleQuotaMarkText(szInputName);
      szInputName = szResultDoubleQuota;

      if (isDoubleQuotaMark) {
        // 일치 로 검색
        pDataDBColumn = pSheetColumn.DBColumn.mapKIND.get(CONST._INT.DBCOLUMN_KIND_NAME_EQUAL);
      }
      else {
        // 포함으로 검색
        // 1글자일 경우 : 일치 로 검색
        // 2글자 이상일 경우 : 포함 으로 검색

        if (szInputName.length <= 1) {
          pDataDBColumn = pSheetColumn.DBColumn.mapKIND.get(CONST._INT.DBCOLUMN_KIND_NAME_EQUAL);
        }
        else {
          pDataDBColumn = pSheetColumn.DBColumn.mapKIND.get(CONST._INT.DBCOLUMN_KIND_NAME_CONTAINS);
        }
      }


      if (!pDataDBColumn) {
        return;
      }

      // 정보 추가 (DB 컬럼 + 입력값)
      arrConditions.push({ pDataDBColumn: pDataDBColumn, Value: szInputName });
    })();

    // 검색 진행하기 전에 UI 리셋
    funcSetDefaultCardName("");

    // 검색 진행
    funcDispatch(EnumUIEventID.ON_CLICK_SEARCH, { arrConditions: arrConditions });
  }

  function OnClickOpenExtraSearch() {
    funcDispatch(EnumUIEventID.ON_CLICK_OPEN_EXTRA_SEARCH);
  }

  let pGlobalStyle = { fontSize: "1.2rem", fontWeight: "bold" };
  return (
    <Container>
      <Row xs={1} sm={2}>
        <Col xs={12} sm={9} className='px-0 mb-1'>
          <InputGroup>
            <Form.Control value={szDefaultCardName} placeholder="카드명" aria-label="inputCardName" onKeyDown={OnKeyDown} onChange={(e) => funcSetDefaultCardName(e.target.value)} />
            <Button variant="outline-primary" className='px-3' style={pGlobalStyle} onClick={OnClickDefaultSearch}>검색</Button>
          </InputGroup>
        </Col>
        <Col xs={12} sm={3} className='pe-0'>
          <div className="d-flex justify-content-end mb-2">
            <Button variant="outline-primary" className='px-3' style={pGlobalStyle} onClick={OnClickOpenExtraSearch}>상세검색</Button>
          </div>
        </Col>
      </Row>
    </Container>

  );
}


function UICardSheet({
  pMaind,
  pDBResult,
  bIsLimitView,
  funcDispatch
}) {

  // Sheet 를 감싸는 div 정의. 
  let pDivSetting = {};
  pDivSetting.className = "";
  pDivSetting.style = {};
  if (bIsLimitView) {
    // 제한된 뷰 + 스크롤 표시
    pDivSetting.className = "overflow-scroll";
    pDivSetting.style = { height: "25rem" };
  }

  function OnClickLoad() {
    funcDispatch(EnumUIEventID.SHEET_ON_CLICK_LOAD_MORE);
  }

  function RefreshUI_TotalCount() {
    return (
      <p className='w-100 text-end' style={{ fontSize: "0.8rem" }}>{`총 ${pDBResult.TotalCount}개의 데이터 (${pDBResult.Datas.length}개 표시중)`}</p>
    );
  }

  function RefreshUI_SheetItems() {
    let arr = [];
    pDBResult.Datas.forEach((pData) => {
      arr.push(
        <UICardSummary
          pMaind={pMaind}
          pData={pData}
          funcDispatch={funcDispatch}
        >
        </UICardSummary>
      );
    });
    return Children.toArray(arr);
  }

  function RefreshUI_Button() {
    let nRemainCount = pDBResult.TotalCount - pDBResult.Datas.length;
    let nLoadableCount = (nRemainCount >= ONEPAGE_DATA_LIMIT) ? ONEPAGE_DATA_LIMIT : nRemainCount;
    if (nRemainCount <= 0) {
      return (
        <Col>
          <Button variant='secondary my-3' disabled size='lg' className='w-100'>데이터를 모두 로드하였습니다.</Button>
        </Col>
      );
    }

    return (
      <Button variant='outline-primary my-3' className='w-100' size='lg' onClick={OnClickLoad}>{`${nLoadableCount}개 더 보기`}</Button>
    );
  }

  return (
    /* 
    https://react-bootstrap.netlify.app/layout/grid/#row-layout-col-sizing
    xs = (< 576px)
    sm = (>= 576px)
    md = (>= 768px)
    lg = (>= 992px)
    xl = (>= 1200px)
    xxl = (>= 1400px)
  */

    <>
      {RefreshUI_TotalCount()}

      <div className={pDivSetting.className} style={pDivSetting.style}>
        {/* // 스크롤링 시 가로로 살짝 스크롤되는거 때문에. Row 기본에 X축 margin에 -5 먹여져 있음 */}
        <Row className="mx-0">
          {RefreshUI_SheetItems()}
        </Row>
        {RefreshUI_Button()}
      </div>

    </>
  );
}


/**
  * Card class, style
  * 
  * small
  * card width : 100px
  * card height : 142px
  */
let pCardUISetting = {
  // 
  cBase: "mb-1 px-0",
  stBase: { width: "100px", cursor: "pointer" },
  // 
  cImg: "",
  stImg: { width: "100px", height: "142px", background: `url("${GameUtil.GetImageDefaultPathSmall()}")` },
  // 
  cText: "text-center my-0",
  stText: { fontSize: "0.9rem", fontWeight: "bold" },
};

function UICardSummary({
  pMaind,
  pData,
  funcDispatch,
}) {

  // state, effect

  // event binding
  function OnClickDetail() {
    funcDispatch(EnumUIEventID.SHEET_ON_CLICK_ITEM, { pData: pData });
  }


  let szImagePath = GameUtil.GetImagePathSmall(pData.Category, pData.KIND);
  if (szImagePath) {
    szImagePath = pMaind.pFbCtrl.GetStorageURL(szImagePath);
  }
  else {
    szImagePath = GameUtil.GetImageDefaultPathSmall();
  }

  return (
    <>
      <Card className={pCardUISetting.cBase} style={pCardUISetting.stBase} onClick={OnClickDetail}>
        <Card.Img
          loading="lazy"    //이미지가 뷰포트의 일정 거리 안으로 들어와야 불러옵니다.
          decoding="async"  //다른 콘텐츠의 표시 지연을 피하기 위해 이미지를 비동기적으로 디코딩 합니다.
          src={szImagePath} variant="top" className={pCardUISetting.cImg} style={pCardUISetting.stImg} />
        <Card.Text className={pCardUISetting.cText} style={pCardUISetting.stText}>{pData.Name}</Card.Text>
      </Card>
    </>
  );
}

export { ComCardList };
