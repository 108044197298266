import { CONST, pSheetColumn, pSheetEnum } from "./CardClientSheetData";
import FirebaseManager from "./FirebaseManager";
import GameUtil from "./GameUtil";

class Maind {
  constructor() {
    this.pFbCtrl = new FirebaseManager();

    this.bInit = false;
    this.bInitComplete = false;

    this.CardTotalCount = 1;
  }

  async Init(cbOnComplete) {

    // main logic

    if (this.bInit) {
      return;
    }

    // 이 처리는 한번만 타야 한다.
    this.bInit = true;

    // 잠시 대기 (대기 안하면 React 에러 난다)
    // await Util.WaitTimeAsync(10);

    // Firebase 초기화
    await this.pFbCtrl.Init();

    // Firestore 앱 설정 Document 로드
    await this.LoadAppSetting();

    // 초기화 완료
    this.bInitComplete = true;
    if (cbOnComplete) {
      cbOnComplete();
    }
  }

  async LoadAppSetting() {
    let szCollection = GameUtil.GetDBAppSettingCollection();
    let arrDocs = await this.pFbCtrl.GetAllDocs(szCollection);
    if (!arrDocs) {
      console.error(`Maind Load App Setting Failed : ${szCollection}`);
      return;
    }

    for (let i = 0; i < arrDocs.length; ++i) {
      let pDoc = arrDocs[i];
      if (!pDoc) {
        continue;
      }

      switch (pDoc.id) {
        case "appsetting" : {
          this.CardTotalCount = pDoc.CardTotalCount;
          break;
        }

        case "enumtable" : {
          pSheetEnum.LoadFromDB(pDoc);
          pSheetColumn.LoadFromDB(pDoc);
          break;
        }
      }
    }
  }


}

export default Maind;