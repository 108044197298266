import { Children, useEffect, useRef, useState } from "react";

// bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Card, Container, Modal } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

// 
import { PageAccountNeedLogin } from './PageAccount';
import { ComCardList } from "../mycom/ComCardList";
import { ComCardDetailModal, CCardDetailModalButtonParam } from '../mycom/ComCardDetailModal';
import { CONST, pSheetEnum } from "../mod/CardClientSheetData";
import { Timestamp } from "firebase/firestore";
import GameUtil from "../mod/GameUtil";
import ComUserDeckSummary from "../mycom/ComUserDeckSummary";

const EnumUIEventID = {
  ON_CLICK_SUBMIT: 101,
  ON_CLICK_GOTO_BOARD: 102,

  // CARDLIST

  // DECK
  DECK_ONCLICK_DETAIL_MODAL: 301,
  DECK_ONCLICK_CHANGE_COUNT: 302,
  DECK_ONCLICK_SET_KEY_CARD: 303,

  // MODAL
  MODAL_ONCLICK_ADD_DECK: 401,

  // 
  EX_EDIT_MODE_FIRST_LOAD: 1001,
};
Object.freeze(EnumUIEventID);

function PageUserDeckWrite({ pMaind }) {

  const [pSearchParams, funcSetSearchParams] = useSearchParams();
  const [pCurUser, funcSetCurUserPointer] = useState(pMaind.pFbCtrl.GetAuthCurrentUser());
  const pNavigate = useNavigate();
  useEffect(() => {
    (async function () {
      if (!pCurUser) {
        pNavigate("/account/login");
        return;
      }

      if (!pSearchParams) {
        return;
      }

      let szID = pSearchParams.get("id");
      if (!szID) {
        return;
      }

      let szCollection = GameUtil.GetDBUserDeckCollection();
      const pDBResult = await pMaind.pFbCtrl.GetDocSimple(szCollection, szID);
      if (!pDBResult) {
        return;
      }

      // 수정 모드 돌입. 기본 데이터 세팅.

      // pSubmitData 수정 : 덱 구성 등
      pSubmitData.DocID = szID;
      pSubmitData.CreateTime = pDBResult.CreateTime;

      pSubmitData.MapDeck.clear();
      pDBResult.Deck.forEach((pCardData) => {
        pSubmitData.AddFromEditFirst(pCardData);
      });
      funcSetSubmitData({ ...pSubmitData });


      // 부가정보 : 제목, 내용 등 
      // EventListener 로 간접적으로 전달
      OnDispatchEvent(EnumUIEventID.EX_EDIT_MODE_FIRST_LOAD, { pDBResult: pDBResult });

    })();

  }, []);

  // state
  // 
  let [pSubmitData, funcSetSubmitData] = useState({

    DocID: "", // 수정 모드로 들어왔으면 여기에 있어야 한다.
    CreateTime: null, // 수정 모드로 들어왔으면 여기에 있어야 한다.

    MapDeck: new Map(), // Map<Category, Map<NumID, {pCardData, Count, KeyCard, }>>

    Find: function (nCategoryKIND, nNumID) {
      let pRetVal = null;

      let mapDeckCategory;
      if (!this.MapDeck.has(nCategoryKIND)) {
        // 말이 안된다.
        return pRetVal;
      }
      mapDeckCategory = this.MapDeck.get(nCategoryKIND);
      if (!mapDeckCategory) {
        // 말이 안된다.
        return pRetVal;
      }

      if (!mapDeckCategory.has(nNumID)) {
        // 말이 안된다.
        return pRetVal;
      }

      pRetVal = mapDeckCategory.get(nNumID);
      return pRetVal;
    },

    AddFromEditFirst(pTargetCardData) {
      return this.AddInternal(pTargetCardData, pTargetCardData.Count, pTargetCardData.KeyCard);
    },

    Add(pTargetCardData) {
      return this.AddInternal(pTargetCardData, 1, 0);
    },

    AddInternal: function (pTargetCardData, nCount, nKeyCard) {
      let mapDeckCategory;
      if (!mapDeckCategory) {
        if (this.MapDeck.has(pTargetCardData.Category)) {
          mapDeckCategory = this.MapDeck.get(pTargetCardData.Category);
        }
        else {
          mapDeckCategory = new Map();
          this.MapDeck.set(pTargetCardData.Category, mapDeckCategory);
        }

      }

      if (!mapDeckCategory) {
        return false;
      }

      if (mapDeckCategory.has(pTargetCardData.NumID)) {
        // 갯수만 증가
        let pDataInMap = mapDeckCategory.get(pTargetCardData.NumID);
        pDataInMap.Count += nCount;
      }
      else {
        // 맵에 새로 넣기

        let pDataInMap = {
          NumID: pTargetCardData.NumID,
          Name: pTargetCardData.Name,
          Category: pTargetCardData.Category,
          RareType: pTargetCardData.RareType,
          SoulType: pTargetCardData.SoulType,
          LV: pTargetCardData.LV,
          Count: nCount,
          KeyCard: nKeyCard,
        };
        mapDeckCategory.set(pTargetCardData.NumID, pDataInMap);
      }

      return true;
    },
  });

  let [pCardDataCache, funcSetCardDataCache] = useState({
    pMap: new Map(),
  });

  let [pEventListeners, funcSetEventListener] = useState({
    pMap: new Map(),
    IDCount: 1,

    Register: function (cb) {
      let nID = this.IDCount;
      this.IDCount++;

      if (!this.pMap.has(nID)) {
        this.pMap.set(nID, cb);
        console.log(`Register Listener : ${nID}`);
      }
      return nID;
    },
    UnRegister: function (nID) {
      if (this.pMap.has(nID)) {
        this.pMap.delete(nID);
        console.log(`!!UnRegister Listener : ${nID}`);
      }
    },
  });

  // Event Binding
  async function OnDispatchEvent(nEventID, pEventParam) {
    // main
    switch (nEventID) {
      case EnumUIEventID.ON_CLICK_GOTO_BOARD: {
        // 유저덱 메인으로 이동
        pNavigate("/userdeck/board");
        break;
      }

      case EnumUIEventID.ON_CLICK_SUBMIT: {
        let szDocID = pSubmitData.DocID;
        let pCreateTime = (szDocID) ? pSubmitData.CreateTime : Timestamp.now();

        let pDoc = {
          Title: pEventParam.szTitle,
          Content: pEventParam.szContent,
          UID: pCurUser.uid,
          UserName: pCurUser.displayName,
          CreateTime: pCreateTime,
          UpdateTime: Timestamp.now(),
          Deck: [],
          DeckIDs: [],
          SoulType: pEventParam.nSelectSoulType,
        };

        // 현재 설정 순회하면서 집계
        let pMapSoulType = new Map(); // key : SoulType / value : Count
        let nTotalCount = 0;
        pSubmitData.MapDeck.forEach((mapDeckCategory, CategoryKIND) => {
          mapDeckCategory.forEach((pDeckData, k) => {

            // DB Doc에 추가
            // Deck
            pDoc.Deck.push({
              NumID: pDeckData.NumID,
              Count: pDeckData.Count,
              KeyCard: pDeckData.KeyCard,

              Name: pDeckData.Name,
              Category: pDeckData.Category,
              RareType: pDeckData.RareType,
              SoulType: pDeckData.SoulType,
              LV: pDeckData.LV,
            });

            // DeckIDs
            pDoc.DeckIDs.push(pDeckData.NumID);

            // TotalCount
            nTotalCount += pDeckData.Count;

            // MapSoulType
            let nSoulTypeCount = 0;
            if (pMapSoulType.has(pDeckData.SoulType)) {
              // Count 불러오기
              nSoulTypeCount = pMapSoulType.get(pDeckData.SoulType);
            }
            // Count 증가 후 갱신(혹은 추가)
            nSoulTypeCount += 1;
            pMapSoulType.set(pDeckData.SoulType, nSoulTypeCount);
          })
        });

        // 만약 유저가 직접 지정한 소울 타입이 없거나, 유효하지 않을 경우, 갯수가 가장 많은 것을 기준으로 세팅한다.
        // (듀얼이라서 갯수가 동일할 경우 소울 타입 기준으로 정렬)

        if (!pSheetEnum["UserDeckSoulType"].mapKIND.has(pDoc.SoulType)) {
          let arrTempSoulType = [];
          pMapSoulType.forEach((nCount, nSoulType) => {
            arrTempSoulType.push({ nSoulType: nSoulType, nCount: nCount });
          });

          if (arrTempSoulType.length > 0) {
            arrTempSoulType = arrTempSoulType.sort((a, b) => {
              if (a.Count === b.Count) {
                // SoulType 이 작은 순으로 정렬 (오름차순)
                return a.nSoulType - b.SoulType;
              }
              // Count 가 큰 순으로 정렬 (내림차순)
              return -(a.Count - b.Count);
            });

            // 가장 앞에 있는게 가장 갯수가 많은 SoulType 이다.
            pDoc.SoulType = arrTempSoulType[0].nSoulType;
          }
          else {
            // 이럴리는 없지만...그냥 기본값으로 넣자.
            pDoc.SoulType = pSheetEnum["UserDeckSoulType"].arr[0].KIND;
          }
        }

        // 유효성 체크
        if (!pDoc.Title || pDoc.Title.length > CONST._INT.USERDECK_LIMIT_TITLE_LENGTH) {
          alert(`제목은 최소 1글자, 최대 ${CONST._INT.USERDECK_LIMIT_TITLE_LENGTH}자까지 작성할 수 있습니다.(현재 ${pDoc.Title.length}자)`);
          break;
        }
        if (!GameUtil.IsValidContent(pDoc.Title)) {
          alert(`제목에 사용 불가능한 특수문자가 있습니다.(${GameUtil.InvalidCharInContent})`);
          break;
        }

        if (!pDoc.Content || pDoc.Content.length > CONST._INT.USERDECK_LIMIT_CONTENT_LENGTH) {
          alert(`내용은 최소 1글자, 최대 ${CONST._INT.USERDECK_LIMIT_CONTENT_LENGTH}자까지 작성할 수 있습니다.(현재 ${pDoc.Content.length}자)`);
          break;
        }
        if (!GameUtil.IsValidContent(pDoc.Content)) {
          alert(`내용에 사용 불가능한 특수문자가 있습니다.(${GameUtil.InvalidCharInContent})`);
          break;
        }

        if (pDoc.DeckIDs.length > CONST._INT.USERDECK_LIMIT_DECK_ID_COUNT) {
          alert(`덱에는 최대 ${CONST._INT.USERDECK_LIMIT_DECK_ID_COUNT}종류까지의 카드를 등록할 수 있습니다.(현재 ${pDoc.DeckIDs.length}종류)`);
          break;
        }

        if (nTotalCount > CONST._INT.USERDECK_LIMIT_DECK_CARD_COUNT) {
          alert(`덱에는 최대 ${CONST._INT.USERDECK_LIMIT_DECK_CARD_COUNT}장의 카드를 등록할 수 있습니다.(현재 카드합계 : ${nTotalCount}장)`);
          break;
        }

        // 전송
        try {
          let szCollection = GameUtil.GetDBUserDeckCollection();
          let [bResult, szResultDocID] = await pMaind.pFbCtrl.WriteDocToDB(szCollection, szDocID, pDoc);
          if (!bResult) {
            throw new Error("write-to-userdb");
          }

          alert("유저덱 등록을 완료했습니다.");

          // 유저덱 메인으로 이동
          pNavigate("/userdeck/board");
        }
        catch (error) {
          alert(`유저덱 등록 실패했습니다.(${error.message})`);
        }
        break;
      }

      // 
      case EnumUIEventID.DECK_ONCLICK_DETAIL_MODAL: {
        console.log(pEventParam.NumID);
        let pCardData;
        if (!pCardData) {
          // 이미 DB에서 한번 받아왔었으면 그걸로 반환.
          if (pCardDataCache.pMap.has(pEventParam.NumID)) {
            pCardData = pCardDataCache.pMap.get(pEventParam.NumID);
          }
        }

        if (!pCardData) {
          // 다른 유저의 유저덱일 경우 
          let szCollection = GameUtil.GetDBCardListCollection();
          let szDocID = String(pEventParam.NumID);
          pCardData = await pMaind.pFbCtrl.GetDocSimple(szCollection, szDocID);
        }

        if (!pCardData) {
          // 여기까지 와서도 없으면 진짜 없는거다.
          // 아래서부터는 데이터가 있다는 가정 하에 진행.
          pEventParam.cb(null);
          break;
        }

        // 캐싱
        if (!pCardDataCache.pMap.has(pCardData.NumID)) {
          pCardDataCache.pMap.set(pCardData.NumID, pCardData);
          funcSetCardDataCache({ ...pCardDataCache });
        }

        // 콜백 처리
        pEventParam.cb(pCardData);
        break;
      }

      case EnumUIEventID.DECK_ONCLICK_CHANGE_COUNT: {
        if (!pSubmitData.MapDeck.has(pEventParam.CategoryKIND)) {
          // 말이 안된다.
          break;
        }
        let mapDeckCategory = pSubmitData.MapDeck.get(pEventParam.CategoryKIND);
        if (!mapDeckCategory) {
          // 말이 안된다.
          break;
        }

        let pTargetDeckData = pSubmitData.Find(pEventParam.CategoryKIND, pEventParam.NumID);
        if (!pTargetDeckData) {
          // 말이 안된다.
          break;
        }

        // 갯수 증감 처리 실행
        // TODO : 지금은 최대치 계산하지 않는다.
        pTargetDeckData.Count += pEventParam.nFactor;

        // 0 이하로 떨어지면 데이터 자체를 덱에서 제외한다.
        if (pTargetDeckData.Count <= 0) {
          mapDeckCategory.delete(pTargetDeckData.NumID);
        }

        // state 갱신 처리 (신규 객체 생성 후 복사)
        funcSetSubmitData({ ...pSubmitData });
        break;
      }

      case EnumUIEventID.DECK_ONCLICK_SET_KEY_CARD: {
        let pTargetDeckData = pSubmitData.Find(pEventParam.CategoryKIND, pEventParam.NumID);
        if (!pTargetDeckData) {
          // 말이 안된다.
          break;
        }

        // 갯수 증감 처리 실행
        pTargetDeckData.KeyCard = (pTargetDeckData.KeyCard != 0) ? 0 : 1;

        // state 갱신 처리 (신규 객체 생성 후 복사)
        funcSetSubmitData({ ...pSubmitData });
        break;
      }

      // 
      case EnumUIEventID.MODAL_ONCLICK_ADD_DECK: {
        let pTargetCardData = pEventParam.pCardData;
        if (!pTargetCardData) {
          break;
        }

        // 캐싱
        if (!pCardDataCache.pMap.has(pTargetCardData.NumID)) {
          pCardDataCache.pMap.set(pTargetCardData.NumID, pTargetCardData);
          funcSetCardDataCache({ ...pCardDataCache });
        }

        // 덱에 추가
        const bResult = pSubmitData.Add(pTargetCardData);
        if (!bResult) {
          break;
        }

        // state 갱신 처리 (신규 객체 생성 후 복사)
        funcSetSubmitData({ ...pSubmitData });
        break;
      }

      default: {
        break;
      }
    }

    pEventListeners.pMap.forEach((pListener, _k) => {
      if (pListener) {
        pListener(nEventID, pEventParam);
      }
    });
  }

  // Render
  if (!pCurUser) {
    return (
      <PageAccountNeedLogin></PageAccountNeedLogin>
    );
  }

  return (
    <>
      <Row className="my-3">
        <Col>
          <h2 className="text-center">유저덱 작성</h2>
        </Col>
      </Row>

      {/* main */}
      <CardSearchList pMaind={pMaind} funcDispatch={OnDispatchEvent}></CardSearchList>
      <div className="mb-2"></div>
      <CardWriteDeck pMaind={pMaind} pSubmitData={pSubmitData} funcDispatch={OnDispatchEvent} pEventListeners={pEventListeners}></CardWriteDeck>
      <CardDeckSummary pMaind={pMaind} pSubmitData={pSubmitData}  funcDispatch={OnDispatchEvent}></CardDeckSummary>
      <CardWriteContent pMaind={pMaind} funcDispatch={OnDispatchEvent} pEventListeners={pEventListeners}></CardWriteContent>
    </>
  );
}

function CardSearchList({
  pMaind,
  funcDispatch,
}) {

  // state
  // 카드 상세정보 모달창 용 
  let [pDetailData, funcSetDetailData] = useState({
    show: false,
    pData: null,
    arrButtonParams: [
      new CCardDetailModalButtonParam("유저덱 추가", "outline-primary", _on_click_modal_add_deck_),
      new CCardDetailModalButtonParam("닫기", "danger", _on_click_modal_close_),
    ]
  });

  let [arrSearchCondition, funcSetSearchCondition] = useState([]);

  // event binding
  function _on_click_modal_close_() {
    pDetailData.show = false;
    funcSetDetailData({ ...pDetailData });
  }

  function _on_click_modal_add_deck_(pCardData) {
    if (!pCardData) {
      return;
    }

    // 닫기
    pDetailData.show = false;
    funcSetDetailData({ ...pDetailData });

    funcDispatch(EnumUIEventID.MODAL_ONCLICK_ADD_DECK, { pCardData: pCardData });
  }

  function _on_click_cardlist_item_(pCardData) {
    pDetailData.show = true;
    pDetailData.pData = pCardData;
    funcSetDetailData({ ...pDetailData });
  }

  function _on_click_cardlist_search(arrInputSearch) {
    funcSetSearchCondition(arrInputSearch);
  }

  return (
    <>
      <ComCardList
        pMaind={pMaind}
        bIsLimitView={true}
        cbOnClickItem={_on_click_cardlist_item_}
        cbOnClickSearch={_on_click_cardlist_search}
        arrSearchCondition={arrSearchCondition}
      >
      </ComCardList>

      <ComCardDetailModal
        pMaind={pMaind}
        pDetailData={pDetailData}
        arrButtonParams={pDetailData.arrButtonParams}
        funcOnHide={_on_click_modal_close_}
      >
      </ComCardDetailModal>
    </>
  );
}

function CardWriteDeck({
  pMaind,
  pSubmitData,
  funcDispatch,
  pEventListeners,
}) {

  // state
  useEffect(() => {

    console.log(`mounted CardWriteDeck`);
    const nID = pEventListeners.Register((nEventID, pEventParam) => {
      switch (nEventID) {
        case EnumUIEventID.MODAL_ONCLICK_ADD_DECK: {
          let pTargetCardData = pEventParam.pCardData;
          if (!pTargetCardData) {
            break;
          }

          pUIData.SelectedCategoryKIND = pTargetCardData.Category;
          funcSetUIDataDeck({ ...pUIData });

          break;
        }

        default: {
          break;
        }
      }
    });

    return () => {
      console.log(`!!unmounted CardWriteDeck`);
      pEventListeners.UnRegister(nID);
    };
  }, []);

  // 카드 상세정보 모달창 용 
  let [pDetailData, funcSetDetailData] = useState({
    show: false,
    pData: null,
    arrButtonParams: [
      new CCardDetailModalButtonParam("닫기", "danger", _on_click_modal_close_),
    ]
  });

  // 도움말 모달창 용
  let [pDataModalHelp, funcSetDataModalHelp] = useState({
    show: false,
  });

  // 
  let [pUIData, funcSetUIDataDeck] = useState({
    SelectedCategoryKIND: pSheetEnum.Category.mapTYPE.get("UNIT").KIND, // 기본 선택 : 유닛
  });


  // event binding
  function OnHideModalHelp() {
    pDataModalHelp.show = false;
    funcSetDataModalHelp({ ...pDataModalHelp });
  }

  function OnClickOpenModalHelp() {
    pDataModalHelp.show = true;
    funcSetDataModalHelp({ ...pDataModalHelp });
  }

  function _on_click_modal_close_() {
    pDetailData.show = false;
    funcSetDetailData({ ...pDetailData });
  }

  function _on_click_tab_(CategoryKIND) {
    pUIData.SelectedCategoryKIND = CategoryKIND;
    funcSetUIDataDeck({ ...pUIData });
  }

  function RefreshUI_Tabs() {
    let arrRetVal = [];
    pSheetEnum.Category.arr.forEach((e) => {
      let szVariant = "outline-primary";
      if (e.KIND === pUIData.SelectedCategoryKIND) {
        szVariant = "primary";
      }

      arrRetVal.push(
        <Button variant={szVariant} onClick={() => _on_click_tab_(e.KIND)}>{e.UIName}</Button>
      );
    });

    return (
      <ButtonGroup>
        {Children.toArray(arrRetVal)}
      </ButtonGroup>
    );
  }

  function RefreshUI_DeckList() {
    let arrRetVal = [];

    let arrDeck = [];
    if (arrDeck.length <= 0) {
      let mapDeckCategory;
      if (pSubmitData.MapDeck.has(pUIData.SelectedCategoryKIND)) {
        mapDeckCategory = pSubmitData.MapDeck.get(pUIData.SelectedCategoryKIND);
      }

      if (mapDeckCategory) {
        mapDeckCategory.forEach((_v, _k) => arrDeck.push(_v));
        arrDeck = arrDeck.sort(GameUtil.SortUIUserDeckCard);
      }
    }

    if (arrDeck.length <= 0) {
      return (
        <p>비어있습니다.</p>
      );
    }

    arrDeck.forEach((e) => {
      function _on_click_deck_item_info_() {

        function _cb_(pCardData) {
          if (!pCardData) {
            alert("카드 데이터를 찾을 수 없습니다.");
            return;
          }

          pDetailData.show = true;
          pDetailData.pData = pCardData;
          funcSetDetailData({ ...pDetailData });
        }

        funcDispatch(EnumUIEventID.DECK_ONCLICK_DETAIL_MODAL, { NumID: e.NumID, cb: _cb_ });
      }

      function _on_click_deck_change_count_(nFactor) {
        funcDispatch(EnumUIEventID.DECK_ONCLICK_CHANGE_COUNT, { CategoryKIND: e.Category, NumID: e.NumID, nFactor: nFactor });
      }

      function _on_click_deck_set_keycard_() {
        funcDispatch(EnumUIEventID.DECK_ONCLICK_SET_KEY_CARD, { CategoryKIND: e.Category, NumID: e.NumID });
      }

      let nNumID = e.NumID;
      let nCategory = GameUtil.NumIDHelper.GetCategory(nNumID);
      let nKIND = GameUtil.NumIDHelper.GetKIND(nNumID);

      let szImagePathSmall = GameUtil.GetImagePathSmall(nCategory, nKIND);
      szImagePathSmall = pMaind.pFbCtrl.GetStorageURL(szImagePathSmall);

      arrRetVal.push(
        <Card className="mb-2 px-0" style={{ maxWidth: "120px" }}>
          <div className="mx-auto">
            <Card.Img onClick={_on_click_deck_item_info_} variant="top" className="mx-0 my-0" style={{ width: "100px", cursor: "pointer" }} src={szImagePathSmall} />
          </div>
          <div className="mx-auto">
            <Card.Text style={{ fontSize: "0.8rem", fontWeight: "bold" }}>{`${e.Name}(${e.Count})`}</Card.Text>
          </div>
          <div className="mx-auto">
            <Button className="py-1" style={{ fontWeight: "bold" }} variant='outline-primary' onClick={() => _on_click_deck_change_count_(-1)}>{`-`}</Button>
            <Button className="py-1" style={{ fontWeight: "bold" }} variant='outline-primary' onClick={() => _on_click_deck_change_count_(1)}>{`+`}</Button>
            <Button className="py-1 px-1" variant='outline-primary' onClick={_on_click_deck_set_keycard_}>{e.KeyCard != 0 ? "🟡" : "🔘"}</Button>
          </div>
        </Card>
      );
    });


    return (
      <div className="overflow-scroll" style={{ maxHeight: "20rem" }}>
        <Row className="mx-0">
          {Children.toArray(arrRetVal)}

        </Row>
      </div>
    );
  }


  // main render
  return (
    <>
      <Card className="mb-1">
        <Card.Header>

          <Card.Text>
            덱 구성 {' '}
            <Button variant="outline-primary px-2 py-0" onClick={OnClickOpenModalHelp}>?</Button>
          </Card.Text>

          {RefreshUI_Tabs()}
        </Card.Header>
        <Card.Body>
          {RefreshUI_DeckList()}
        </Card.Body>
      </Card>

      <ComCardDetailModal
        pMaind={pMaind}
        pDetailData={pDetailData}
        arrButtonParams={pDetailData.arrButtonParams}
        funcOnHide={_on_click_modal_close_}
      >
      </ComCardDetailModal>

      <UICreateHelpModal
        bShow={pDataModalHelp.show}
        funcOnHide={OnHideModalHelp}
      >
      </UICreateHelpModal>
    </>
  );
}

function UICreateHelpModal({
  bShow,
  funcOnHide,
}) {
  function OnHide() {
    funcOnHide();
  }

  function OnClickClose() {
    funcOnHide();
  }

  let szFontSize = "0.9rem";

  let szContent =
    `1. 총 100장의 카드를 넣을 수 있습니다.(카드 종류별로 100종류를 넣든, 한 카드를 100장을 넣든 자유)

2. 각 카드별 덱 제한(1U, 2U, 3U, 4U)은 적용하지 않았습니다.

3. 유저덱 메인 목록에 보이는 카드들은 키 카드(Key Card)로,
유저덱 작성시 덱 구성의 수량 조절 UI 옆의 
동그란 표시(🔘)를 체크(🟡)해서 설정할 수 있습니다. 

4. 기타 불편, 건의사항은 [계정]의 [문의사항]을 이용해주시기 바랍니다.`;

  return (
    <Modal
      show={bShow}
      animation={false}
      onHide={OnHide}
    >
      <Modal.Header closeButton className="px-2 py-2">
        <Modal.Title>유저덱 작성 도움말</Modal.Title>
      </Modal.Header>

      <Modal.Body className="px-2 py-2">
        <Card className="mb-3">
          <Card.Header>내용</Card.Header>
          <Card.Body>
            <Form>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Control
                  style={{ fontSize: szFontSize, height: "20rem", resize: "none" }}
                  readOnly={true}
                  as="textarea"
                  value={szContent} />
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>

        <Button variant="danger" className='w-100 mb-1' onClick={OnClickClose}>닫기</Button>
      </Modal.Body>
    </Modal>
  );
}

function CardDeckSummary({
  pMaind,
  pSubmitData,
  funcDispatch
}) {

  // 카드 상세정보 모달창 용 
  let [pDetailData, funcSetDetailData] = useState({
    show: false,
    pData: null,
    arrButtonParams: [
      new CCardDetailModalButtonParam("닫기", "danger", _on_click_modal_close_),
    ]
  });

  // event binding
  function _on_click_modal_close_() {
    pDetailData.show = false;
    funcSetDetailData({ ...pDetailData });
  }

  function _on_click_deck_item_info_(NumID) {

    function _cb_(pCardData) {
      if (!pCardData) {
        alert("카드 데이터를 찾을 수 없습니다.");
        return;
      }

      pDetailData.show = true;
      pDetailData.pData = pCardData;
      funcSetDetailData({ ...pDetailData });
    }

    funcDispatch(EnumUIEventID.DECK_ONCLICK_DETAIL_MODAL, { NumID: NumID, cb: _cb_ });
  }


  let pMapDeck = pSubmitData.MapDeck;
  let arrDeck = [];
  pMapDeck.forEach((pMapNumID, _k) => {
    pMapNumID.forEach((pData, __k) => {
      arrDeck.push(pData);
    })
  });

  return (
    <>
      <Card className="mb-1">
        <Card.Header>
          <Card.Text className="mb-0">덱 분석</Card.Text>
        </Card.Header>
        <Card.Body>
          <ComUserDeckSummary
            arrDeck={arrDeck}
            bShowDeckList={true}
            bShowLV={true}
            cbOnClickListItem={_on_click_deck_item_info_}
          >
          </ComUserDeckSummary>
        </Card.Body>
      </Card>


      <ComCardDetailModal
        pMaind={pMaind}
        pDetailData={pDetailData}
        arrButtonParams={pDetailData.arrButtonParams}
        funcOnHide={_on_click_modal_close_}
      >
      </ComCardDetailModal>
    </>
  );
}

function CardWriteContent({
  funcDispatch,
  pEventListeners
}) {

  useEffect(() => {
    // register listener
    pEventListeners.Register((nEventID, pEventParam) => {

      switch (nEventID) {
        case EnumUIEventID.EX_EDIT_MODE_FIRST_LOAD: {
          const pDBResult = pEventParam.pDBResult;
          funcSetSoulType(pDBResult.SoulType);
          funcSetTitle(pDBResult.Title);
          funcSetContent(pDBResult.Content);
          break;
        }

        default: {
          break;
        }
      }
    });

    // unregister listener
    return () => {
      pEventListeners.UnRegister((nEventID, pEventParam) => {

      });
    };
  }, []);

  const [nSelectSoulType, funcSetSoulType] = useState(-1);
  const [szTitle, funcSetTitle] = useState("");
  const [szContent, funcSetContent] = useState("");

  // event binding
  function OnClickSubmit() {
    funcDispatch(EnumUIEventID.ON_CLICK_SUBMIT, { nSelectSoulType: nSelectSoulType, szTitle: szTitle, szContent: szContent });
  }

  function OnClickGotoBoard() {
    let isConfirm = window.confirm("유저덱 작성에서 나가시겠습니까?");
    if (!isConfirm) {
      return;
    }

    funcDispatch(EnumUIEventID.ON_CLICK_GOTO_BOARD);
  }

  function RefreshUI_SoulTypeSelect() {

    let szEnumName = "UserDeckSoulType";
    let szMainTitle = "속성";
    if (pSheetEnum[szEnumName].mapKIND.has(nSelectSoulType)) {
      szMainTitle = pSheetEnum[szEnumName].mapKIND.get(nSelectSoulType).UIName;
    }
    let bIsSelected = nSelectSoulType !== -1;

    function __create_dropdown_items__() {
      let arrEnumTable = pSheetEnum[szEnumName].arr;
      let arrEnums = [...arrEnumTable];
      arrEnums.sort((a, b) => a.UIPriority - b.UIPriority);

      let arr = [];
      arr.push(<Dropdown.Item eventKey="-1">{szMainTitle}</Dropdown.Item>);
      arr.push(<Dropdown.Divider />);
      arrEnums.forEach((_e) => {
        arr.push(<Dropdown.Item eventKey={_e.KIND}>{_e.UIName}</Dropdown.Item>);
      });

      return Children.toArray(arr);
    }

    function __on_select_soultype__(eventKey) {
      // eventKey = Enum KIND
      console.log(`eventKey : ${eventKey}`);

      let nEnumKIND = parseInt(eventKey);
      if (nEnumKIND !== nSelectSoulType) {
        funcSetSoulType(nEnumKIND);
      }
      else {

      }
    }

    return (
      <DropdownButton
        className='w-50 mb-3'
        as={ButtonGroup}
        id={`dropdown-button-drop`}
        size="sm"
        variant={bIsSelected ? "primary" : "outline-primary"}
        title={szMainTitle}
        onSelect={__on_select_soultype__}
      >
        {__create_dropdown_items__()}
      </DropdownButton>
    );
  }

  return (
    <Card className="mb-3">
      <Card.Header>설명글</Card.Header>
      <Card.Body>
        {RefreshUI_SoulTypeSelect()}
        <Form>
          <Form.Group className="mb-3" controlId="deckwriteform.ControlInput1">
            <Form.Label>제목</Form.Label>
            <Form.Control value={szTitle} onChange={(e) => funcSetTitle(e.target.value)} type="text" placeholder="제목을 입력해주세요." />
          </Form.Group>
          <Form.Group className="mb-3" controlId="deckwriteform.ControlTextarea1">
            <Form.Label>내용</Form.Label>
            <Form.Control value={szContent} onChange={(e) => funcSetContent(e.target.value)} as="textarea" placeholder="내용을 입력해주세요." rows={7} />
          </Form.Group>
        </Form>

        <div className="d-flex justify-content-end">
          <Button variant="outline-primary" className="me-1" onClick={OnClickSubmit}>작성</Button>
          <Button variant="outline-primary" className="me-1" onClick={OnClickGotoBoard}>목록</Button>
        </div>
      </Card.Body>
    </Card>
  );
}

export default PageUserDeckWrite;

