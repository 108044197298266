import { Timestamp } from "firebase/firestore";
import { Children, useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, Card, Col, Container, Dropdown, DropdownButton, Form, Modal, ProgressBar, Row } from "react-bootstrap";
import ReactImageUploading from "react-images-uploading";
import { useNavigate, useSearchParams } from "react-router-dom";
import { pSheetEnum } from "../mod/CardClientSheetData";
import GameUtil from "../mod/GameUtil";
import Util from "../mod/Util";

const EnumUIEventID = {
  ON_CLICK_SUBMIT: 101,
  ON_CLICK_GOTO_BOARD: 102,

  // IMAGE

  // IMAGE UPLOADER
  IMG_UPLOADER_ON_CLICK_ADD: 501,
  IMG_UPLOADER_ON_CLICK_UPDATE: 502,
  IMG_UPLOADER_ON_CLICK_REMOVE_ONE: 503,
  IMG_UPLOADER_ON_CLICK_CHANGE_ORIGINAL_REMOVE_STATE: 504,
  IMG_UPLOADER_ON_CLICK_REMOVE_ALL: 505,
  IMG_UPLOADER_ON_CLICK_MOVE_PREV: 506,
  IMG_UPLOADER_ON_CLICK_MOVE_NEXT: 507,

  EX_EDIT_MODE_FIRST_LOAD: 1001,
};
Object.freeze(EnumUIEventID);

const EnumImageType = {
  ORIGINAL: 1,
  NEW: 2,
};
Object.freeze(EnumUIEventID);

const NEWFILE_URL_VAR_NAME = 'data_url';

function GetLibraryImageStoragePath(szDocID, szFileName) {
  return `${GameUtil.GetDBLibraryCollection()}/${szDocID}/${szFileName}`;
}

function PageLibraryWrite({
  pMaind
}) {

  // 
  const pNavigate = useNavigate();
  const [pSearchParams, funcSetSearchParams] = useSearchParams();
  const [pCurUser, funcSetCurUserPointer] = useState(pMaind.pFbCtrl.GetAuthCurrentUser());
  let rDBResultSave = useRef({});
  let [pDataUploadModal, funcSetUploadModal] = useState({
    show: false,
    szMessage: "",
    nCur: 0,
    nMax: 0,
    nError: 0,
  });

  // 
  const [pContainerLibImage, funcSetContainerLibImage] = useState({
    arrDatas: [],

    // NewFile 관리
    UpdateNewFile: function (pFileData) {
      // 없으면 추가한다, 있으면 수정한다
      if (!pFileData) {
        return;
      }

      let pData = {
        eImageType: EnumImageType.NEW,
        szUrl: pFileData[NEWFILE_URL_VAR_NAME],
        pFileData: pFileData,
      };

      let nIndex = this.arrDatas.findIndex((e) => e.eImageType === pData.eImageType && e.pFileData.ImageID === pData.pFileData.ImageID);
      if (nIndex < 0) {
        // 추가
        this.arrDatas.push(pData);
      }
      else {
        // 수정
        this.arrDatas[nIndex] = pData;
      }
    },

    RemoveNewFile: function (ImageID) {
      // 없으면 안한다, 있으면 삭제한다
      if (!ImageID) {
        return;
      }

      let nIndex = this.arrDatas.findIndex((e) => e.eImageType === EnumImageType.NEW && e.pFileData.ImageID === ImageID);
      if (nIndex < 0) {
        return;
      }

      this.arrDatas.splice(nIndex, 1);
    },

    UpdateOriginal: function (szUrl, szFileName) {
      if (!szUrl) {
        return;
      }

      let pData = {
        eImageType: EnumImageType.ORIGINAL,
        szUrl: szUrl,
        szFileName: szFileName,
        isRemove: false,
      };

      let nIndex = this.arrDatas.findIndex((e) => e.eImageType === pData.eImageType && e.szUrl === pData.szUrl);
      if (nIndex >= 0) {
        return;
      }

      // 추가
      this.arrDatas.push(pData);
    },

    SetOriginalRemoveState: function (szUrl, isRemove) {
      if (!szUrl) {
        return;
      }

      let nIndex = this.arrDatas.findIndex((e) => e.eImageType === EnumImageType.ORIGINAL && e.szUrl === szUrl);
      if (nIndex < 0) {
        return;
      }

      // 수정
      let pData = this.arrDatas[nIndex];
      pData.isRemove = isRemove;
    },
  });

  // 
  useEffect(() => {
    (async function () {
      if (!pCurUser) {
        pNavigate("/account/login");
        return;
      }

      // 관리자 권한이 없으면 홈 화면으로 튕겨낸다.
      let pUserIDToken = await pMaind.pFbCtrl.GetAuthUserIDToken();
      if (!pUserIDToken || !pUserIDToken.claims.role || pUserIDToken.claims.role !== "admin") {
        pNavigate("/");
        return;
      }

      if (!pSearchParams) {
        return;
      }

      let szID = pSearchParams.get("id");
      if (!szID) {
        return;
      }

      let szCollection = GameUtil.GetDBLibraryCollection();
      const pDBResult = await pMaind.pFbCtrl.GetDocSimple(szCollection, szID);
      if (!pDBResult) {
        return;
      }

      // 수정 모드 돌입. 기본 데이터 세팅.
      rDBResultSave.current = pDBResult;

      // ORIGINAL 이미지 세팅
      pDBResult.Images.forEach((pImage) => {
        let szUrl = GameUtil.GetLibraryImagePath(pDBResult.id, pImage.FileName);
        szUrl = pMaind.pFbCtrl.GetStorageURL(szUrl);
        pContainerLibImage.UpdateOriginal(szUrl, pImage.FileName);
      });
      funcSetContainerLibImage({ ...pContainerLibImage });

      // 부가정보 : 제목, 내용 등 
      // EventListener 로 간접적으로 전달
      OnDispatchEvent(EnumUIEventID.EX_EDIT_MODE_FIRST_LOAD, { pDBResult: rDBResultSave.current });

    })();

  }, []);

  // 
  let [pEventListeners, funcSetEventListener] = useState({
    pMap: new Map(),
    IDCount: 1,

    Register: function (cb) {
      let nID = this.IDCount;
      this.IDCount++;

      if (!this.pMap.has(nID)) {
        this.pMap.set(nID, cb);
        console.log(`Register Listener : ${nID}`);
      }
      return nID;
    },
    UnRegister: function (nID) {
      if (this.pMap.has(nID)) {
        this.pMap.delete(nID);
        console.log(`!!UnRegister Listener : ${nID}`);
      }
    },
  });


  // Event Binding
  async function OnDispatchEvent(nEventID, pEventParam) {
    // main
    switch (nEventID) {
      case EnumUIEventID.ON_CLICK_GOTO_BOARD: {
        // 라이브러리 메인으로 이동
        pNavigate("/library/board");
        break;
      }

      case EnumUIEventID.ON_CLICK_SUBMIT: {

        let szDocID = rDBResultSave.current.id ? rDBResultSave.current.id : "";

        let pDoc = {
          Category: pEventParam.nSelectCategory,
          Title: pEventParam.szTitle,
          Content: pEventParam.szContent,
          ReferenceLink: pEventParam.szReferenceLink,
          UID: pCurUser.uid,
          UserName: pCurUser.displayName,
          CreateTime: rDBResultSave.current.CreateTime ? rDBResultSave.current.CreateTime : Timestamp.now(),
          UpdateTime: Timestamp.now(),
          Images: rDBResultSave.current.Images ? rDBResultSave.current.Images : [],
          Cards: rDBResultSave.current.Cards ? rDBResultSave.current.Cards : [],
          Tags: rDBResultSave.current.Tags ? rDBResultSave.current.Tags : [],
        };

        if (pDoc.Category <= 0) {
          alert("카테고리를 선택하지 않았습니다.");
          return;
        }

        // 전송
        try {
          // 최초 DB 등록 (혹은 수정)
          console.log(`Library Write Start`);

          let szCollection = GameUtil.GetDBLibraryCollection();
          let [bResult, szResultDocID] = await pMaind.pFbCtrl.WriteDocToDB(szCollection, szDocID, pDoc);
          if (!bResult) {
            throw new Error("write-to-db-1");
          }

          // 새로 등록됐든, 수정했든 szDocID 갱신. 아래에서 또 써야 되는데 갱신 안하면 또 새로 등록한다,
          szDocID = szResultDocID;


          // 기존 이미지 삭제
          console.log(`Processing remove Library Origin Images if want`);

          let arrTargetDelete = [];
          for (let i = 0; i < pContainerLibImage.arrDatas.length; ++i) {
            let pLibImage = pContainerLibImage.arrDatas[i];
            if (!pLibImage) {
              continue;
            }

            if (pLibImage.eImageType !== EnumImageType.ORIGINAL) {
              continue;
            }

            if (!pLibImage.isRemove) {
              continue;
            }

            // 일단 배열에 추가
            arrTargetDelete.push(pLibImage);
          }

          // 진행 바 모달창 활성화
          pDataUploadModal.show = true;
          pDataUploadModal.szMessage = "기존 이미지 삭제 중";
          pDataUploadModal.nCur = 0; pDataUploadModal.nMax = arrTargetDelete.length; pDataUploadModal.nError = 0;
          funcSetUploadModal({ ...pDataUploadModal });

          for (let i = 0; i < arrTargetDelete.length; ++i) {
            let pLibImage = arrTargetDelete[i];
            let szPath = GameUtil.GetLibraryImagePath(szDocID, pLibImage.szFileName);
            let [bResult2, szMessage] = await pMaind.pFbCtrl.DeleteFileInStorage(szPath);
            if (!bResult2) {
              throw new Error(szMessage);
            }

            // 진행 바 갱신
            pDataUploadModal.nCur += 1;
            funcSetUploadModal({ ...pDataUploadModal });
          }

          // 새 이미지 추가 + Images 배열 재구축
          console.log(`Add New Library Origin Images if want`);

          let arrTargetUpload = [];
          for (let i = 0; i < pContainerLibImage.arrDatas.length; ++i) {
            let pLibImage = pContainerLibImage.arrDatas[i];
            if (pLibImage.eImageType === EnumImageType.ORIGINAL && pLibImage.isRemove) {
              // 이미 지운거는 제외
              continue;
            }
            arrTargetUpload.push(pLibImage);
          }

          // 진행 바 모달창 활성화
          pDataUploadModal.show = true;
          pDataUploadModal.szMessage = "이미지 업로드 중";
          pDataUploadModal.nCur = 0; pDataUploadModal.nMax = arrTargetUpload.length; pDataUploadModal.nError = 0;
          funcSetUploadModal({ ...pDataUploadModal });

          // 순회하면서 구성 
          let ImagesNew = [];
          let szNewFilePrefix = Util.GetCurrentUTCTimeString();
          for (let i = 0; i < arrTargetUpload.length; ++i) {
            let pLibImage = arrTargetUpload[i];

            if (pLibImage.eImageType === EnumImageType.NEW) {
              let pFile = pLibImage.pFileData.file;
              let szFileExt = pFile.name.split('.').pop();
              let szNewFileName = `${szNewFilePrefix}_${i}.${szFileExt}`;
              let szStoragePath = GetLibraryImageStoragePath(szResultDocID, szNewFileName);

              let [bResult2, szMessage] = await pMaind.pFbCtrl.UploadToStorage(szStoragePath, pFile);
              if (!bResult2) {
                throw new Error(szMessage);
              }

              ImagesNew.push({
                FileName: szNewFileName
              });
            }
            else if (pLibImage.eImageType === EnumImageType.ORIGINAL) {
              // 앞서 과정에서 이미 제외했다
              // if (pLibImage.isRemove) {
              //   continue;
              // }

              ImagesNew.push({
                FileName: pLibImage.szFileName
              });
            }

            // 진행 바 갱신
            pDataUploadModal.nCur += 1;
            funcSetUploadModal({ ...pDataUploadModal });
          }

          // 새로 구성된 Images 적용
          pDoc.Images = ImagesNew;
          [bResult, szResultDocID] = await pMaind.pFbCtrl.WriteDocToDB(szCollection, szDocID, pDoc);
          if (!bResult) {
            throw new Error("write-to-db-2");
          }

          alert("라이브러리 등록을 완료했습니다.");

          // 진행 바 모달창 비활성화
          pDataUploadModal.show = false;
          funcSetUploadModal({ ...pDataUploadModal });

          // 라이브러리 메인으로 이동
          pNavigate("/library/board");
        }
        catch (error) {
          alert(`라이브러리 등록 실패했습니다.(${error.message})`);
          console.log(error.message);
          console.log(error);

          // 진행 바 모달창 비활성화 (실패했어도 어쨌든 꺼줘야 함)
          pDataUploadModal.show = false;
          funcSetUploadModal({ ...pDataUploadModal });
        }

        break;
      }

      // 이미지 관련
      case EnumUIEventID.IMG_UPLOADER_ON_CLICK_ADD: {
        pContainerLibImage.UpdateNewFile(pEventParam.pFileData);
        funcSetContainerLibImage({ ...pContainerLibImage });
        break;
      }

      case EnumUIEventID.IMG_UPLOADER_ON_CLICK_UPDATE: {
        pContainerLibImage.UpdateNewFile(pEventParam.pFileData);
        funcSetContainerLibImage({ ...pContainerLibImage });
        break;
      }

      case EnumUIEventID.IMG_UPLOADER_ON_CLICK_REMOVE_ONE: {
        pContainerLibImage.RemoveNewFile(pEventParam.pData.pFileData.ImageID);
        funcSetContainerLibImage({ ...pContainerLibImage });
        break;
      }

      case EnumUIEventID.IMG_UPLOADER_ON_CLICK_REMOVE_ALL: {
        // funcSetNewImages 은 IMG_UPLOADER_ON_CHANGE_LIST 에서 처리함
        pContainerLibImage.Clear();
        funcSetContainerLibImage({ ...pContainerLibImage });
        break;
      }

      case EnumUIEventID.IMG_UPLOADER_ON_CLICK_CHANGE_ORIGINAL_REMOVE_STATE: {
        pContainerLibImage.SetOriginalRemoveState(pEventParam.pData.szUrl, !pEventParam.pData.isRemove);
        funcSetContainerLibImage({ ...pContainerLibImage });
        break;
      }

      case EnumUIEventID.IMG_UPLOADER_ON_CLICK_MOVE_PREV: {
        let pData = pEventParam.pData;
        if (!pData) {
          break;
        }
        let arrDatas = pContainerLibImage.arrDatas;
        let nIndex = arrDatas.indexOf(pData);
        if (nIndex < 0) {
          break;
        }
        if (nIndex - 1 < 0) {
          break;
        }

        // 구조분해 할당
        [arrDatas[nIndex - 1], arrDatas[nIndex]] = [arrDatas[nIndex], arrDatas[nIndex - 1]];
        funcSetContainerLibImage({ ...pContainerLibImage });
        break;
      }

      case EnumUIEventID.IMG_UPLOADER_ON_CLICK_MOVE_NEXT: {
        let pData = pEventParam.pData;
        if (!pData) {
          break;
        }
        let arrDatas = pContainerLibImage.arrDatas;
        let nIndex = arrDatas.indexOf(pData);
        if (nIndex < 0) {
          break;
        }
        if (nIndex + 1 >= arrDatas.length) {
          break;
        }

        // 구조분해 할당
        [arrDatas[nIndex + 1], arrDatas[nIndex]] = [arrDatas[nIndex], arrDatas[nIndex + 1]];
        funcSetContainerLibImage({ ...pContainerLibImage });
        break;
      }

      default: {
        break;
      }
    }

    pEventListeners.pMap.forEach((pListener, _k) => {
      if (pListener) {
        pListener(nEventID, pEventParam);
      }
    });
  }



  // async function OnClickUploadStorage() {

  //   let [bResult, szMessage] = await pMaind.pFbCtrl.UploadToStorage("library/hahaha/", arrNewImages);
  //   if (!bResult) {
  //     alert(`업로드 실패 : ${szMessage}`);
  //     return;
  //   }

  //   alert("업로드 성공.");
  // }


  return (
    <>
      <UIImageUploader
        pContainerLibImage={pContainerLibImage}
        funcDispatch={OnDispatchEvent}
        pEventListeners={pEventListeners}
      >
      </UIImageUploader>

      <UIWriteContent
        funcDispatch={OnDispatchEvent}
        pEventListeners={pEventListeners}
      >
      </UIWriteContent>

      {/* modal */}
      <UIFileUploadModal
        pData={pDataUploadModal}
      >
      </UIFileUploadModal>
    </>
  );
}

function UIFileUploadModal({
  pData,
}) {

  let fProgressPercent = pData.nCur / pData.nMax * 100;
  let szProgress = `${pData.nCur} / ${pData.nMax}`;

  function RefreshUI_Error() {
    if (pData.nError <= 0) {
      return (<></>);
    }

    return (
      <p>{`에러 발생 : ${pData.nError}개`}</p>
    );
  }

  return (
    <Modal
      show={pData.show}
      animation={false}
      onHide={() => { /** 업로드 성공이든 실패든 거기서 안닫히면 버그다. 유저 스스로는 못닫게 처리함. */ }}
    >
      <Modal.Header>
        <Modal.Title>업로드 중</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{`${pData.szMessage} : ${szProgress}`}</p>
        <ProgressBar now={fProgressPercent} label={szProgress}/>
        {RefreshUI_Error()}
      </Modal.Body>
    </Modal>
  );
}

function UIImageUploader({
  pContainerLibImage,
  funcDispatch,
  pEventListeners,
}) {

  // state
  useEffect(() => {

    console.log(`mounted UIImageUploader`);
    const nID = pEventListeners.Register((nEventID, pEventParam) => {
      switch (nEventID) {
        // case EnumUIEventID.MODAL_ONCLICK_ADD_DECK: {
        //   let pTargetCardData = pEventParam.pCardData;
        //   if (!pTargetCardData) {
        //     break;
        //   }

        //   pUIData.SelectedCategoryKIND = pTargetCardData.Category;
        //   funcSetUIDataDeck({ ...pUIData });

        //   break;
        // }

        default: {
          break;
        }
      }
    });

    return () => {
      console.log(`!!unmounted UIImageUploader`);
      pEventListeners.UnRegister(nID);
    };
  }, []);

  // 
  const [arrNewImages, funcSetNewImages] = useState([]);
  let rNewImageID = useRef(0);

  function OnChangeImageUploading(imageList, arrIndexAddOrChanged) {
    console.log(imageList, arrIndexAddOrChanged);

    if (arrIndexAddOrChanged) {
      // RemoveOne, RemoveAll 이 아닌 상황
      // 추가, 수정 상황

      let nIndexAddOrChanged = arrIndexAddOrChanged[0];
      let pFileData = imageList[nIndexAddOrChanged];

      if (arrNewImages.length !== imageList.length) {
        // 추가 상황 : ID를 새로 발급
        rNewImageID.current += 1;
        pFileData.ImageID = rNewImageID.current;
        funcDispatch(EnumUIEventID.IMG_UPLOADER_ON_CLICK_ADD, { pFileData: imageList[nIndexAddOrChanged] });
      }
      else {
        // 수정 상황 : 기존 꺼에서 ID를 가져와서 적용
        let nOldImageID = arrNewImages[nIndexAddOrChanged].ImageID;
        pFileData.ImageID = nOldImageID;
        funcDispatch(EnumUIEventID.IMG_UPLOADER_ON_CLICK_UPDATE, { pFileData: imageList[nIndexAddOrChanged] });
      }
    }

    // 최종적으로 원래 arrNewImages 를 수정
    funcSetNewImages(imageList);
  }

  function OnClickRemoveOne(pData) {
    funcDispatch(EnumUIEventID.IMG_UPLOADER_ON_CLICK_REMOVE_ONE, { pData: pData });
  }

  function OnClickChangeOriginalRemoveState(pData) {
    console.log(pData);
    funcDispatch(EnumUIEventID.IMG_UPLOADER_ON_CLICK_CHANGE_ORIGINAL_REMOVE_STATE, { pData: pData });
  }

  function OnClickPrevImage(pData) {
    funcDispatch(EnumUIEventID.IMG_UPLOADER_ON_CLICK_MOVE_PREV, { pData: pData });
  }

  function OnClickNextImage(pData) {
    funcDispatch(EnumUIEventID.IMG_UPLOADER_ON_CLICK_MOVE_NEXT, { pData: pData });
  }

  function RefreshUI_Images(onImageUpdate, onImageRemove) {
    let arr = [];

    let szWidth = "12rem";
    let szHeight = "12rem";

    pContainerLibImage.arrDatas.forEach((pData, idx) => {
      function ___show_left_button__() {
        if (idx - 1 < 0) {
          return (<></>);
        }

        return (
          <Button variant="primary" style={{ position: "absolute", left: 0, top: "50%", transform: "translateY(-50%)", opacity: 0.5 }} onClick={() => OnClickPrevImage(pData)}>
            {`◀`}
          </Button>
        );
      }

      function ___show_right_button__() {
        if (idx + 1 >= pContainerLibImage.arrDatas.length) {
          return (<></>);
        }

        return (
          <Button variant="primary" style={{ position: "absolute", right: 0, top: "50%", transform: "translateY(-50%)", opacity: 0.5 }} onClick={() => OnClickNextImage(pData)}>
            {`▶`}
          </Button>
        );
      }

      switch (pData.eImageType) {
        case EnumImageType.ORIGINAL: {

          let szRemoveState = pData.isRemove ? "삭제함" : "";
          let szRemoveButtonText = pData.isRemove ? "삭제 해제" : "삭제";

          arr.push(
            <Card className="px-0 my-1" style={{ maxWidth: szWidth }}>
              <div style={{ position: "relative" }}>
                {___show_left_button__()}
                {___show_right_button__()}
                <Card.Img src={pData.szUrl} style={{ maxWidth: szWidth, height: szHeight, objectFit: "contain", backgroundColor: "black" }} />
              </div>
              <Card.Body className="px-1 py-1">
                <Container>
                  <Row>
                    <Col className="px-1">
                      <Card.Text>{szRemoveState}</Card.Text>
                    </Col>
                    <Col className="px-1">
                      <Button variant="danger" className="w-100" onClick={() => { OnClickChangeOriginalRemoveState(pData); }}>{szRemoveButtonText}</Button>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          );
          break;
        }

        case EnumImageType.NEW: {
          let nNewFileIndex = -1;
          arrNewImages.forEach((e, idx) => {
            if (e.ImageID === pData.pFileData.ImageID) {
              nNewFileIndex = idx;
            }
          });

          arr.push(
            <Card className="px-0 my-1" style={{ maxWidth: szWidth }}>
              <div style={{ position: "relative" }}>
                {___show_left_button__()}
                {___show_right_button__()}
                <Card.Img src={pData.szUrl} style={{ maxWidth: szWidth, height: szHeight, objectFit: "contain", backgroundColor: "black" }} />
              </div>
              <Card.Body className="px-1 py-1">
                <Container>
                  <Row>
                    <Col className="px-1">
                      <Button variant="primary" className="w-100" onClick={() => onImageUpdate(nNewFileIndex)}>수정</Button>
                    </Col>
                    <Col className="px-1">
                      <Button variant="danger" className="w-100" onClick={() => {
                        onImageRemove(nNewFileIndex); OnClickRemoveOne(pData);
                      }}>삭제</Button>
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          );
          break;
        }

        default: {
          break;
        }
      }
    });

    return (Children.toArray(arr));
  }

  return (
    <ReactImageUploading
      multiple
      value={arrNewImages}
      onChange={OnChangeImageUploading}
      maxNumber={99}
      dataURLKey={NEWFILE_URL_VAR_NAME}
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps,
      }) => (
        // write your building UI
        <Card>
          <Card.Header>이미지</Card.Header>
          <Card.Body>
            <Card
              style={{ color: isDragging ? 'red' : undefined, height: "200px" }}
              onClick={onImageUpload}
              {...dragProps}
            >
              <Card.Body>
                <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                  <Card.Text style={{ fontSize: "1.5rem" }}>
                    여기를 클릭 <br />아니면 파일 드래그
                  </Card.Text>
                </div>
              </Card.Body>
            </Card>

            <Container>
              <Row>
                {RefreshUI_Images(onImageUpdate, onImageRemove)}
              </Row>
            </Container>
          </Card.Body>
        </Card>
      )}
    </ReactImageUploading>
  );
}

function UIWriteContent({
  funcDispatch,
  pEventListeners
}) {

  useEffect(() => {
    // register listener
    pEventListeners.Register((nEventID, pEventParam) => {

      switch (nEventID) {
        case EnumUIEventID.EX_EDIT_MODE_FIRST_LOAD: {
          const pDBResult = pEventParam.pDBResult;
          funcSetCategory(pDBResult.Category);
          funcSetTitle(pDBResult.Title);
          funcSetContent(pDBResult.Content);
          funcSetReferenceLink(pDBResult.ReferenceLink);
          break;
        }

        default: {
          break;
        }
      }
    });

    // unregister listener
    return () => {
      pEventListeners.UnRegister((nEventID, pEventParam) => {

      });
    };
  }, []);

  const [nSelectCategory, funcSetCategory] = useState(-1);
  const [szTitle, funcSetTitle] = useState("");
  const [szContent, funcSetContent] = useState("");
  const [szReferenceLink, funcSetReferenceLink] = useState("");

  // event binding
  function OnClickSubmit() {
    funcDispatch(EnumUIEventID.ON_CLICK_SUBMIT, {
      nSelectCategory: nSelectCategory,
      szTitle: szTitle,
      szContent: szContent,
      szReferenceLink: szReferenceLink
    });
  }

  function OnClickGotoBoard() {
    let isConfirm = window.confirm("라이브러리 작성에서 나가시겠습니까?");
    if (!isConfirm) {
      return;
    }

    funcDispatch(EnumUIEventID.ON_CLICK_GOTO_BOARD);
  }

  function RefreshUI_CategorySelect() {

    let szEnumName = "LibraryCategory";
    let szMainTitle = "카테고리";
    if (pSheetEnum[szEnumName].mapKIND.has(nSelectCategory)) {
      szMainTitle = pSheetEnum[szEnumName].mapKIND.get(nSelectCategory).UIName;
    }

    let bIsSelected = nSelectCategory !== -1;

    function __create_dropdown_items__() {
      let arrEnumTable = pSheetEnum[szEnumName].arr;
      let arrEnums = [...arrEnumTable];
      arrEnums.sort((a, b) => a.UIPriority - b.UIPriority);

      let arr = [];
      arr.push(<Dropdown.Item eventKey="-1">{szMainTitle}</Dropdown.Item>);
      arr.push(<Dropdown.Divider />);
      arrEnums.forEach((_e) => {
        arr.push(<Dropdown.Item eventKey={_e.KIND}>{_e.UIName}</Dropdown.Item>);
      });

      return Children.toArray(arr);
    }

    function __on_select_category__(eventKey) {
      // eventKey = Enum KIND
      console.log(`eventKey : ${eventKey}`);

      let nEnumKIND = parseInt(eventKey);
      if (nEnumKIND !== nSelectCategory) {
        funcSetCategory(nEnumKIND);
      }
      else {

      }
    }

    return (
      <DropdownButton
        className='w-50 mb-3'
        as={ButtonGroup}
        id={`dropdown-button-drop`}
        size="sm"
        variant={bIsSelected ? "primary" : "outline-primary"}
        title={szMainTitle}
        onSelect={__on_select_category__}
      >
        {__create_dropdown_items__()}
      </DropdownButton>
    );
  }

  return (
    <Card className="mb-3">
      <Card.Header>설명글</Card.Header>
      <Card.Body>
        {RefreshUI_CategorySelect()}
        <Form>
          <Form.Group className="mb-3" controlId="deckwriteform.ControlInput1">
            <Form.Label>제목</Form.Label>
            <Form.Control value={szTitle} onChange={(e) => funcSetTitle(e.target.value)} type="text" placeholder="제목을 입력해주세요." />
          </Form.Group>
          <Form.Group className="mb-3" controlId="deckwriteform.ControlTextarea1">
            <Form.Label>내용</Form.Label>
            <Form.Control value={szContent} onChange={(e) => funcSetContent(e.target.value)} as="textarea" placeholder="내용을 입력해주세요." rows={7} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="deckwriteform.ControlInput2">
            <Form.Label>참조 링크</Form.Label>
            <Form.Control value={szReferenceLink} onChange={(e) => funcSetReferenceLink(e.target.value)} type="text" placeholder="링크" />
          </Form.Group>
        </Form>

        <div className="d-flex justify-content-end">
          <Button variant="outline-primary" className="me-1" onClick={OnClickSubmit}>작성</Button>
          <Button variant="outline-primary" className="me-1" onClick={OnClickGotoBoard}>목록</Button>
        </div>
      </Card.Body>
    </Card>
  );
}

export default PageLibraryWrite;