// firebase
import { signInWithEmailAndPassword, signOut, onAuthStateChanged, createUserWithEmailAndPassword, updateProfile, deleteUser, sendSignInLinkToEmail, sendEmailVerification, sendPasswordResetEmail, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { Timestamp } from "firebase/firestore";

// React
import { Children, useEffect, useRef, useState } from "react";

// bootstrap
import { Card, Col, Container, Modal, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Outlet, useNavigate } from "react-router-dom";

// personal
import { CONST, pSheetColumn } from "../mod/CardClientSheetData";
import GameUtil from "../mod/GameUtil";
import Util from "../mod/Util";
import { ComWriteUserReportModal } from "../mycom/ComWriteUserReportModal";

function PageAccountLayout() {
  return (
    <Outlet></Outlet>
  );
}

function PageAccountLogin({ pMaind }) {

  // state
  // pNavigate 는 useEffect 내부에서만 사용해야 한다는듯하다 (경고)
  // 그래서 bIsLogined 라는 state를 할당, 컴포넌트 첫 렌더 시 + bIsLogined 변경 시에만 확인 후 pNavigate 가 호출되도록 했다.
  const [bIsLogined, funcSetLogined] = useState(Boolean(pMaind.pFbCtrl.GetAuthCurrentUser()));
  const pNavigate = useNavigate();
  useEffect(() => {
    if (!bIsLogined) {
      return;
    }

    pNavigate("/account/main");
  }, [bIsLogined]);

  // auth 상태 변경 시 처리
  onAuthStateChanged(pMaind.pFbCtrl.auth,
    (user) => {
      if (user) {
        // 로그인 완료
        funcSetLogined(true);
      }
      else {

      }
    },
    (error) => {

    });

  // event binding

  function OnClickAccountLogin() {
    let szEmail = pRefEmail.current.value;
    let szPW = pRefPW.current.value;

    signInWithEmailAndPassword(pMaind.pFbCtrl.auth, szEmail, szPW)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        // ...
        alert("로그인 완료");
        console.log("Signed in success");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;

        let szFeedback = GameUtil.GetAuthErrorFeedback("로그인 에러", errorCode);
        alert(szFeedback);

        console.log(`!!!Signed in error : ${errorCode} ${errorMessage}`);
        console.log(error);
      });
  }

  function OnClickAccountCreate() {
    console.log(`OnClickAccountCreate`);

    pNavigate("/account/create");
  }

  function OnClickAccountRecover() {
    console.log(`OnClickAccountRecover`);

    pNavigate("/account/recovermail");
  }

  // render
  let pRefEmail = useRef();
  let pRefPW = useRef();

  if (bIsLogined) {
    return (
      <>
        <Card className="mb-3">
          <Card.Header>회원</Card.Header>
          <Card.Body>
            <Card.Text>로그인 완료. 계정 메인 페이지로 이동합니다.</Card.Text>
          </Card.Body>
        </Card>
      </>
    );
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header>로그인</Card.Header>
        <Card.Body>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>이메일 주소</Form.Label>
            <Form.Control type="email" placeholder="example@address.com" ref={pRefEmail} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>비밀번호</Form.Label>
            <Form.Control type="password" placeholder="password" ref={pRefPW} />
          </Form.Group>

          <div className="d-flex justify-content-end">
            <Button variant="link" onClick={OnClickAccountRecover}>비밀번호 찾기</Button>

            <Button variant="outline-primary" className="me-1" onClick={OnClickAccountLogin}>
              로그인
            </Button>
            <Button variant="outline-primary" className="me-1" onClick={OnClickAccountCreate}>
              회원가입
            </Button>
          </div>

        </Card.Body>
      </Card>
    </>
  );
}

function PageAccountCreate({ pMaind }) {

  // pNavigate 는 useEffect 내부에서만 사용해야 한다는듯하다 (경고)
  // 그래서 bIsLogined 라는 state를 할당, 컴포넌트 첫 렌더 시 + bIsLogined 변경 시에만 확인 후 pNavigate 가 호출되도록 했다.
  const [bIsLogined, funcSetLogined] = useState(Boolean(pMaind.pFbCtrl.GetAuthCurrentUser()));
  const pNavigate = useNavigate();
  useEffect(() => {
    if (!bIsLogined) {
      return;
    }

    pNavigate("/");
  }, [bIsLogined]);

  // auth 상태 변경 시 처리
  onAuthStateChanged(pMaind.pFbCtrl.auth,
    (user) => {
      if (user) {
        console.log("user in onAuthStateChanged");
        // 로그인
        funcSetLogined(true);
      }
      else {

      }
    },
    (error) => {

    });

  async function OnClickCreate() {
    console.log(`OnClickCreate`);

    let szEmail = pRefEmail.current.value;
    if (!szEmail || szEmail.length > CONST._INT.ACCOUNT_LIMIT_EMAIL_LENGTH) {
      alert(`이메일은 최소 1자, 최대 ${CONST._INT.ACCOUNT_LIMIT_EMAIL_LENGTH}자까지 입력할 수 있습니다.(현재 ${szEmail.length}자)`);
      return;
    }

    if (!Util.IsValidEmail(szEmail)) {
      alert(`유효하지 않은 이메일 형식입니다.(example@address.com 형식인지 확인해주세요.)`);
      return;
    }

    let szPW = pRefPW.current.value;
    if (!szPW) {
      alert(`암호를 입력해주세요.`);
      return;
    }

    let szNick = pRefNick.current.value;
    if (!szNick || szNick.length > CONST._INT.ACCOUNT_LIMIT_NICK_LENGTH) {
      alert(`닉네임은 최소 1자, 최대 ${CONST._INT.ACCOUNT_LIMIT_NICK_LENGTH}자까지 입력할 수 있습니다.(현재 ${szNick.length}자)`);
      return;
    }

    if (!GameUtil.IsValidCharacters(szNick)) {
      alert(`닉네임에 띄어쓰기 혹은 유효하지 않은 특수문자가 있습니다.(${GameUtil.InvalidCharInChars})`);
      return;
    }

    try {
      const pUserCredential = await createUserWithEmailAndPassword(pMaind.pFbCtrl.auth, szEmail, szPW);
      if (!pUserCredential) {
        throw new Error("pUserCredential is empty.");
      }
    }
    catch (error) {
      alert(`회원가입 에러 : ${error.message}`);
      return;
    }

    try {
      // 닉네임 등 프로필 업데이트
      const pCurUser = pMaind.pFbCtrl.auth.currentUser;
      await updateProfile(pCurUser, { displayName: szNick });

      // 최종적으로 인증 메일 전송
      await sendEmailVerification(pCurUser);

      alert(`회원 가입이 완료되었습니다. ${pCurUser.email}로 회원 인증 메일을 전송했습니다. 인증 완료 후 다시 로그인 해주세요.`);

      // 강제 로그아웃 진행 
      // (미인증 상태에서 로그인 => 이후 인증 완료 절차 밟았을때 사이트 이용 불가 이슈 있음. 아예 강제로 로그아웃)
      await signOut(pMaind.pFbCtrl.auth);
    }
    catch (error) {
      // 회원가입 자체는 성공했지만 프로필 업데이트 에러
      alert(`프로필 업데이트 에러 : ${error.message}`);

      const pCurUser = pMaind.pFbCtrl.auth.currentUser;

      // 로그아웃부터 진행 (아마 로그인 처리까지는 진행됐을 것이므로)
      await signOut(pMaind.pFbCtrl.auth);

      // 계정 삭제
      await deleteUser(pCurUser);
    }
  }

  let pRefEmail = useRef();
  let pRefPW = useRef();
  let pRefNick = useRef();

  return (
    <>
      <Card className="mb-3">
        <Card.Header>회원 가입</Card.Header>
        <Card.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label>이메일 주소</Form.Label>
              <Form.Control type="email" placeholder="example@address.com" ref={pRefEmail} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupPassword">
              <Form.Label>비밀번호</Form.Label>
              <Form.Control type="password" placeholder="password" ref={pRefPW} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupNickName">
              <Form.Label>닉네임</Form.Label>
              <Form.Control type="text" placeholder={`1~${CONST._INT.ACCOUNT_LIMIT_NICK_LENGTH}자 (띄어쓰기, 특수문자 금지)`} ref={pRefNick} />
            </Form.Group>
          </Form>

          <Button variant="primary" type="button" onClick={OnClickCreate}>가입</Button>
        </Card.Body>
      </Card>
    </>
  );
}

function PageAccountRecoverMail({ pMaind }) {
  const pNavigate = useNavigate();

  async function OnClickExec() {
    console.log(`OnClickExec`);

    let szEmail = pRefEmail.current.value;
    if (!szEmail) {
      return;
    }

    try {
      await sendPasswordResetEmail(pMaind.pFbCtrl.auth, szEmail);

      alert(`${szEmail}로 안내 메일을 보내드렸습니다.`);
      pNavigate("/account/login");
    }
    catch (error) {
      let szFeedback = GameUtil.GetAuthErrorFeedback("안내 메일 전송 에러", error.code);
      alert(szFeedback);

      console.log(error);
      console.log(error.code);
    }
  }

  let pRefEmail = useRef();

  return (
    <>
      <Card className="mb-3">
        <Card.Header>비밀번호 찾기</Card.Header>
        <Card.Body>
          <Card.Text>가입한 이메일 주소를 입력하시면 안내 메일을 보내드립니다.</Card.Text>
          <Form>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label>이메일 주소</Form.Label>
              <Form.Control type="email" placeholder="example@address.com" ref={pRefEmail} />
            </Form.Group>
          </Form>
          <Button variant="primary" type="button" onClick={OnClickExec}>전송</Button>
        </Card.Body>
      </Card>
    </>
  );
}


function PageAccountEditProfile({ pMaind }) {

  // pNavigate 는 useEffect 내부에서만 사용해야 한다는듯하다 (경고)
  // 그래서 bIsLogined 라는 state를 할당, 컴포넌트 첫 렌더 시 + bIsLogined 변경 시에만 확인 후 pNavigate 가 호출되도록 했다.
  let [pCurUser, funcSetCurUserPointer] = useState(pMaind.pFbCtrl.GetAuthCurrentUser());
  const pNavigate = useNavigate();
  useEffect(() => {
    if (pCurUser) {
      return;
    }

    pNavigate("/account/login");
  }, [pCurUser]);

  // auth 상태 변경 시 처리
  onAuthStateChanged(pMaind.pFbCtrl.auth,
    (user) => {
      if (user) {

      }
      else {
        // 로그아웃
        funcSetCurUserPointer(null);
      }
    },
    (error) => {

    });

  // event binding
  async function OnClickEdit() {

    let szNick = pRefNick.current.value;
    if (!szNick || szNick.length > CONST._INT.ACCOUNT_LIMIT_NICK_LENGTH) {
      alert(`닉네임은 최소 1자, 최대 ${CONST._INT.ACCOUNT_LIMIT_NICK_LENGTH}자까지 입력할 수 있습니다.(현재 ${szNick.length}자)`);
      return;
    }

    if (!GameUtil.IsValidCharacters(szNick)) {
      alert(`닉네임에 띄어쓰기 혹은 유효하지 않은 특수문자가 있습니다.(${GameUtil.InvalidCharInChars})`);
      return;
    }

    try {
      await updateProfile(pCurUser, { displayName: szNick });
      alert("회원정보 수정 완료했습니다.");

      pNavigate("/account/main");
    }
    catch (error) {
      alert("회원정보 수정");
    }
  }

  let pRefNick = useRef();

  return (
    <>
      <Card className="mb-3">
        <Card.Header>회원 정보 수정</Card.Header>
        <Card.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label>이메일 주소</Form.Label>
              <Form.Control type="email" value={pCurUser.email} disabled />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupNickName">
              <Form.Label>닉네임</Form.Label>
              <Form.Control type="text" defaultValue={pCurUser.displayName} placeholder="4~12자 한글 혹은 영어 (특수문자 금지)" ref={pRefNick} />
            </Form.Group>
          </Form>
          <Button variant="primary" type="button" onClick={OnClickEdit}>수정</Button>
        </Card.Body>
      </Card>

    </>
  );
}

function PageAccountMain({
  pMaind
}) {

  // state

  // pNavigate 는 useEffect 내부에서만 사용해야 한다는듯하다 (경고)
  // 그래서 bIsLogined 라는 state를 할당, 컴포넌트 첫 렌더 시 + bIsLogined 변경 시에만 확인 후 pNavigate 가 호출되도록 했다.
  let [pCurUser, funcSetCurUserPointer] = useState(pMaind.pFbCtrl.GetAuthCurrentUser());
  const pNavigate = useNavigate();
  useEffect(() => {
    if (pCurUser) {
      return;
    }

    pNavigate("/account/login");
  }, [pCurUser]);

  // auth 상태 변경 시 처리
  onAuthStateChanged(pMaind.pFbCtrl.auth,
    (user) => {
      if (user) {

      }
      else {
        // 로그아웃
        funcSetCurUserPointer(null);
      }
    },
    (error) => {

    });

  if (!pCurUser) {
    return (
      <PageAccountNeedLogin></PageAccountNeedLogin>
    );
  }

  return (
    <>
      <UIAccountMainBasicInfo
        pMaind={pMaind}
        pCurUser={pCurUser}
      >
      </UIAccountMainBasicInfo>

      <UIAccountMainUseful
        pMaind={pMaind}
        pCurUser={pCurUser}
      >
      </UIAccountMainUseful>

      <UIAccountMainUserReport
        pMaind={pMaind}
        pCurUser={pCurUser}
      >
      </UIAccountMainUserReport>
    </>
  )
}

function UIAccountMainBasicInfo({
  pMaind,
  pCurUser
}) {

  const pNavigate = useNavigate();

  // event binding
  async function OnClickSendEmailVerification() {
    console.log(`OnClickSendEmailVerification`);

    try {
      await sendEmailVerification(pCurUser);
      alert("등록된 이메일 주소로 인증용 이메일을 전송하였습니다.");
    }
    catch (error) {
      let szFeedback = GameUtil.GetAuthErrorFeedback("인증 이메일 전송 에러", error.code);
      alert(szFeedback);
    }
  }

  async function OnClickSendPasswordChangeEmail() {
    console.log(`OnClickSendPasswordChangeEmail`);

    try {
      await sendPasswordResetEmail(pMaind.pFbCtrl.auth, pCurUser.email);
      alert("등록된 이메일 주소로 비밀번호 변경용 이메일을 전송하였습니다.");
    }
    catch (error) {
      let szFeedback = GameUtil.GetAuthErrorFeedback("비밀번호 변경 이메일 전송 에러", error.code);
      alert(szFeedback);
    }
  }

  function OnClickAccountEdit() {
    console.log(`OnClickAccountEdit`);
    pNavigate("/account/editprofile");
  }

  async function OnClickAccountDelete() {
    console.log(`OnClickAccountDelete`);
    pNavigate("/account/deleteconfirm");
  }

  async function OnClickLogout() {
    let isConfirm = window.confirm("로그아웃 하시겠습니까?");
    if (!isConfirm) {
      return;
    }

    try {
      await signOut(pMaind.pFbCtrl.auth)

      // Sign-out successful.
      alert("로그아웃 완료.");
      console.log("Sign-out successful.");
    }
    catch (error) {
      // An error happened.
      const errorCode = error.code;
      const errorMessage = error.message;

      alert(`!!!로그아웃 에러!!! : [${errorCode}] ${errorMessage}`);
      console.log("Sign-out Error.");
      console.log(error);
    }
  }

  function RefreshUI_EmailButtons() {
    if (pCurUser.emailVerified) {
      return (<></>);
    }

    return (
      <div className="d-flex justify-content-end">
        <Button variant="outline-primary" className="me-1 mb-1" onClick={OnClickSendEmailVerification}>인증 메일 전송</Button>
      </div>
    );
  }

  return (
    <Card className="mb-3">
      <Card.Header>회원 기본 정보</Card.Header>
      <Card.Body>
        <Card.Text className="mb-1" style={{ fontSize: "1.5rem", fontWeight: "bold", textAlign: "center" }}>{`${pCurUser.displayName}`}</Card.Text>
        <Card.Text className="mb-1" style={{ textAlign: "center" }}>{`${pCurUser.email} (${pCurUser.emailVerified ? "인증 완료" : "미인증"})`}</Card.Text>
        {RefreshUI_EmailButtons()}

        <div className="d-flex justify-content-end">
          <Button variant="outline-primary" className="me-1 mb-1" onClick={OnClickAccountEdit}>정보수정</Button>
          <Button variant="outline-primary" className="me-1 mb-1" onClick={OnClickSendPasswordChangeEmail}>비밀번호 변경</Button>
        </div>
        <div className="d-flex justify-content-end">
          <Button variant="outline-danger" className="me-1 mb-1" onClick={OnClickLogout}>로그아웃</Button>
          <Button variant="danger" className="me-1 mb-1" type="button" onClick={OnClickAccountDelete}>회원탈퇴</Button>
        </div>
      </Card.Body>
    </Card>
  );
}

function UIAccountMainUseful({
  pMaind,
  pCurUser
}) {

  const pNavigate = useNavigate();

  function OnClickGotoUserDeck() {
    let pDataDBColumn = pSheetColumn.DBColumnUserDeck.mapKIND.get(CONST._INT.USERDECK_DBCOLUMN_KIND_UID);
    if (!pDataDBColumn) {
      return;
    }

    let pNewParams = new URLSearchParams();
    pNewParams.set(pDataDBColumn.Query, pCurUser.uid);
    pNavigate(`/userdeck/board?${pNewParams.toString()}`);
  }

  return (
    <Card className="mb-3">
      <Card.Header>편의기능</Card.Header>
      <Card.Body>
        <div className="d-flex justify-content-end">
          <Button variant="outline-primary" className="me-1 mb-1" onClick={OnClickGotoUserDeck}>작성한 유저덱 검색</Button>
        </div>
      </Card.Body>
    </Card>
  );
}

function UIAccountMainUserReport({
  pMaind,
  pCurUser
}) {

  let ONEPAGE_DATA_LIMIT = 5;

  let [pDBResult, funcDBResult] = useState({
    TotalCount: 0,
    Datas: [],
    pOrderValueEnd: null,
    bLastFlag: false,

    Clear: function () {
      this.TotalCount = 0;
      this.Datas = [];
      this.pOrderValueEnd = null;
      this.bLastFlag = false;
    },
  });

  // DB 요청 처리 번호표
  let rDBRequestControl = useRef({
    pMap: new Map(),
    IDCount: 1,

    Register: function () {
      let nID = this.IDCount;
      this.IDCount++;

      if (!this.pMap.has(nID)) {
        this.pMap.set(nID, nID);
      }
      return nID;
    },
    UnRegister: function (nID) {
      if (this.pMap.has(nID)) {
        this.pMap.delete(nID);
      }
    },
    Clear: function () {
      this.pMap.clear();
    }
  });


  let [pDataModal, funcSetDataModal] = useState({
    show: false,
  });
  function OnHideModal() {
    pDataModal.show = false;
    funcSetDataModal({ ...pDataModal });
  }

  useEffect(() => {
    (async function () {
      // 기존 DBResult 초기화
      pDBResult.Clear();

      // 검색 다시 실행
      await ProcDBSearch();
    })();

    return () => {
      // Unmount 시 처리
      // 처리 번호표 전부 삭제
      rDBRequestControl.current.Clear();
    };
  }, []);

  async function ProcDBSearch() {
    // arrSearchCondition 을 기반으로 검색을 진행하고, 결과를 "추가"한다
    // 이 전단계에서 arrSearchCondition 의 세팅이 끝났어야 한다.

    let arrQueries = [];

    // 반드시 NumID 정렬을 맨앞에 둘것. 순서 꼬이면 색인 다시해야 할수도 있다.
    let szColumnNameOrder = "UpdateTime";
    arrQueries.push(pMaind.pFbCtrl.CreateSearchQuery(szColumnNameOrder, CONST._DB_PROCTYPE.ORDER_BY, "desc"));

    // DB 요청 전에 미리 결과 처리 번호표 등록
    let nReqID = rDBRequestControl.current.Register();

    // DB 요청 (비동기)
    let szDBName = GameUtil.GetDBReportCollection(pCurUser.uid);
    let pSearchResult = await pMaind.pFbCtrl.GetSearchedDocs(szDBName,
      arrQueries,
      ONEPAGE_DATA_LIMIT,
      pDBResult.TotalCount,
      szColumnNameOrder,
      pDBResult.pOrderValueEnd);

    // DB 요청 결과 이후에도 요청 관리에 ID가 있으면 실행
    if (!rDBRequestControl.current.pMap.has(nReqID)) {
      return;
    }

    // 처리 번호표 삭제
    rDBRequestControl.current.UnRegister(nReqID);

    // 결과 처리
    pDBResult.Datas.push(...pSearchResult.arrResult);
    pDBResult.TotalCount = pSearchResult.TotalCount;
    pDBResult.pOrderValueEnd = pSearchResult.pOrderValueEnd;
    pDBResult.bLastFlag = pSearchResult.arrResult.length <= 0;
    funcDBResult({ ...pDBResult });
  }

  function OnClickLoadMore() {
    ProcDBSearch();
  }

  function OnClickWriteReport() {
    pDataModal.show = true;
    funcSetDataModal({ ...pDataModal });
  }

  async function OnSendReport(szTitle, szContent) {

    let pReport = {
      ReportType: 2,
      State: 1,
      UID: pCurUser.uid,
      UserName: pCurUser.displayName,
      CreateTime: Timestamp.now(),
      UpdateTime: Timestamp.now(),
      Title: szTitle,
      Content: szContent,
    };

    // 유효성 검사
    if (!pReport.UID) {
      alert(`유저 정보가 유효하지 않습니다. 재로그인 후 다시 시도해주세요.`);
      return;
    }

    if (!pReport.Title || pReport.Title.length > CONST._INT.REPORT_LIMIT_TITLE_LENGTH) {
      alert(`문의사항 제목은 최소 1자 최대 ${CONST._INT.REPORT_LIMIT_TITLE_LENGTH}자까지 입니다.(현재 ${pReport.Title.length}자)`);
      return;
    }
    if (!GameUtil.IsValidContent(pReport.Title)) {
      alert(`문의사항 제목에 유효하지 않은 특수문자가 있습니다.(${GameUtil.InvalidCharInContent})`);
      return;
    }

    if (!pReport.Content || pReport.Content.length > CONST._INT.REPORT_LIMIT_CONTENT_LENGTH) {
      alert(`문의사항 내용은 최소 1자 최대 ${CONST._INT.REPORT_LIMIT_CONTENT_LENGTH}자까지 입니다.(현재 ${pReport.Content.length}자)`);
      return;
    }
    if (!GameUtil.IsValidContent(pReport.Content)) {
      alert(`문의사항 내용에 유효하지 않은 특수문자가 있습니다.(${GameUtil.InvalidCharInContent})`);
      return;
    }

    try {
      let szDBName = GameUtil.GetDBReportCollection(pReport.UID);
      let [bResult, szDocID] = await pMaind.pFbCtrl.WriteDocToDB(szDBName, "", pReport);
      if (!bResult) {
        throw new Error("write-to-userdb");
      }

      // GMDB에 저장할때는 TargetDocID까지 저장
      pReport.TargetCollection = szDBName;
      pReport.TargetDocID = szDocID;

      szDBName = GameUtil.GetDBGMReportCollection();
      [bResult, szDocID] = await pMaind.pFbCtrl.WriteDocToDB(szDBName, "", pReport);
      if (!bResult) {
        throw new Error("write-to-gmdb");
      }

      alert("문의사항 접수 완료되었습니다.");

      // 문의사항 리스트 갱신용 새로고침
      window.location.reload();

      pDataModal.show = false;
      funcSetDataModal({ ...pDataModal });
    }
    catch (error) {
      alert(`문의사항 접수 실패했습니다. (${error.message})`);
    }
  }

  async function OnDeleteReport(szDocID) {
    let isConfirm = window.confirm("문의사항을 삭제하시겠습니까?");
    if (!isConfirm) {
      return;
    }

    try {
      let szCollection = GameUtil.GetDBReportCollection(pCurUser.uid);

      await pMaind.pFbCtrl.DeleteDocSimple(szCollection, szDocID);

      alert(`삭제 완료했습니다.`);

      // 문의사항 리스트 갱신용 새로고침
      window.location.reload();
    }
    catch (error) {
      // TODO : 삭제에 실패
      alert(`문의사항 접수 실패했습니다. (${error.message})`);
    }
  }

  if (!pCurUser || !pCurUser.emailVerified) {
    return (<></>);
  }

  function RefreshUI_Header() {
    return (
      <Container>
        <Row>
          <Col className="px-0">
            <Card.Text>문의사항</Card.Text>
          </Col>
          <Col className="px-0">
            <div className="d-flex justify-content-end">
              <Button variant="outline-primary" className="me-1 px-2 py-1" style={{ fontSize: "0.9rem" }} onClick={OnClickWriteReport}>
                작성
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  function RefreshUI_Reports() {
    if (pDBResult.Datas.length <= 0) {
      return (
        <p>문의사항이 없습니다.</p>
      );
    }

    let arr = [];

    pDBResult.Datas.forEach((pData) => {
      let pCreateTime = pData.CreateTime.toDate();
      let pUpdateTime = pData.UpdateTime.toDate();

      let szVariant = "";
      let szText = "";
      switch (pData.State) {
        case 1: {
          szVariant = "outline-secondary";
          szText = "접수대기";
          break;
        }
        case 2: {
          szVariant = "outline-primary";
          szText = "확인중";
          break;
        }
        case 3: {
          szVariant = "success";
          szText = "답변완료";
          break;
        }
        default: {
          szVariant = "outline-secondary";
          szText = `상태(${pData.State})`;
          break;
        }
      }

      function _refresh_ui_content_() {
        return (
          <Card className="mb-1">
            <Card.Header style={{ fontSize: "0.8rem" }}>
              문의내용
            </Card.Header>
            <Card.Body className="px-1 py-1">
              <Form>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Control
                    style={{ fontSize: "0.8rem", resize: "none" }}
                    readOnly={true}
                    as="textarea"
                    value={pData.Content} 
                    rows={5}
                    />
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        );
      }

      function _refresh_ui_response_() {
        if (!pData.Response) {
          return (
            <></>
          );
        }

        return (
          <Card className="mb-1" bg="success" text="light">
            <Card.Header style={{ fontSize: "0.8rem" }}>
              답변
            </Card.Header>
            <Card.Body className="px-1 py-1">
              <Form>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Control
                    style={{ fontSize: "0.8rem", resize: "none"}}
                    readOnly={true}
                    as="textarea"
                    value={pData.Response} 
                    rows={5}
                    />
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        );
      }

      arr.push(
        <Card className="mb-1">
          <Card.Header className="px-2">
            <Container>
              <Row className="mb-2">
                <Col xs={4} sm={2} className="px-0">
                  <Button variant={szVariant} className="px-0 py-1 w-100" style={{ fontSize: "0.7rem" }}>{szText}</Button>
                </Col>
                <Col xs={8} sm={10}>
                  <Card.Text className="mb-1">{`${pData.Title}`}</Card.Text>
                </Col>
              </Row>
              <Row>
                <Col className="px-0">
                  <Card.Text className="mb-0" style={{ fontSize: "0.7rem" }}>{`갱신시각 : ${Util.GetTimeDiffString(pUpdateTime)}`}</Card.Text>
                  <Card.Text className="mb-0" style={{ fontSize: "0.7rem" }}>{`생성시각 : ${Util.GetTimeDiffString(pCreateTime)}`}</Card.Text>
                </Col>
                <Col className="px-0">
                  <div className="d-flex justify-content-end">
                    <Button variant="danger" className="me-1 px-2 py-1" style={{ fontSize: "0.9rem" }} onClick={() => OnDeleteReport(pData.id)}>
                      삭제
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          </Card.Header>

          <Card.Body className="px-2 py-2">
            {_refresh_ui_content_()}
            {_refresh_ui_response_()}
          </Card.Body>

        </Card>
      );
    });

    return (Children.toArray(arr));
  }

  function RefreshUI_ButtonMore() {
    if (pDBResult.bLastFlag) {
      return (
        <div className="mx-auto">
          <Button variant='secondary my-3' className='w-100'>{`게시물을 모두 로드하였습니다.`}</Button>
        </div>
      );
    }

    return (
      <div className="mx-auto">
        <Button variant='outline-primary my-3' className='w-100' onClick={OnClickLoadMore}>{`게시물 더 불러오기`}</Button>
      </div>
    );
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header>
          {RefreshUI_Header()}
        </Card.Header>
        <Card.Body>
          {RefreshUI_Reports()}
          {RefreshUI_ButtonMore()}
        </Card.Body>
      </Card>

      {/* Modal */}
      <ComWriteUserReportModal
        bShow={pDataModal.show}
        OnHide={OnHideModal}
        OnSend={OnSendReport}
      >
      </ComWriteUserReportModal>
    </>
  );
}

function PageAccountDeleteConfirm({ pMaind }) {

  // pNavigate 는 useEffect 내부에서만 사용해야 한다는듯하다 (경고)
  // 그래서 bIsLogined 라는 state를 할당, 컴포넌트 첫 렌더 시 + bIsLogined 변경 시에만 확인 후 pNavigate 가 호출되도록 했다.
  let [pCurUser, funcSetCurUserPointer] = useState(pMaind.pFbCtrl.GetAuthCurrentUser());
  const pNavigate = useNavigate();
  useEffect(() => {
    if (pCurUser) {
      return;
    }

    pNavigate("/account/login");
  }, [pCurUser]);

  // auth 상태 변경 시 처리
  onAuthStateChanged(pMaind.pFbCtrl.auth,
    (user) => {
      if (user) {

      }
      else {
        // 로그아웃
        funcSetCurUserPointer(null);
      }
    },
    (error) => {

    });

  // event binding
  async function OnClickExec() {
    let szEmail = pRefEmail.current.value;
    let szPW = pRefPW.current.value;
    if (!szEmail || !szPW) {
      alert("양식을 채워주세요.");
      return;
    }

    if (pCurUser.email !== szEmail) {
      alert("이메일을 잘못 입력하였습니다.");
      return;
    }

    try {

      const pCurUser = pMaind.pFbCtrl.auth.currentUser;
      const pCredential = EmailAuthProvider.credential(
        pCurUser.email,
        szPW
      );

      // 입력 받은걸로 재인증 진행
      await reauthenticateWithCredential(pCurUser, pCredential);

      // 로그아웃 진행
      await signOut(pMaind.pFbCtrl.auth);

      // 계정 삭제
      await deleteUser(pCurUser);

      alert("회원 탈퇴 처리 진행을 완료했습니다.");

    }
    catch (error) {
      let szFeedback = GameUtil.GetAuthErrorFeedback("회원 탈퇴 에러", error.code);
      alert(szFeedback);

      console.log(error);
    }
  }

  // render
  let pRefEmail = useRef();
  let pRefPW = useRef();

  return (
    <Card className="mb-3">
      <Card.Header>회원 탈퇴 확인</Card.Header>
      <Card.Body>
        <Card.Text>회원 탈퇴 처리를 진행합니다. 탈퇴 시 계정은 즉시 삭제되고, 계정이 소유한 작성글은 삭제되지 않습니다. 그래도 진행한다면, 회원 계정의 이메일 주소와 비밀번호를 입력해주세요.</Card.Text>
        <Form>
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label>이메일 주소</Form.Label>
            <Form.Control type="email" placeholder="example@address.com" ref={pRefEmail} />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGroupPassword">
            <Form.Label>비밀번호</Form.Label>
            <Form.Control type="password" placeholder="password" ref={pRefPW} />
          </Form.Group>
        </Form>

        <Button variant="danger" type="button" onClick={OnClickExec}>탈퇴</Button>
      </Card.Body>
    </Card>
  );
}

function PageAccountNeedLogin() {
  return (
    <>
      <p>로그인 필요. 로그인 페이지로 이동합니다.</p>
    </>
  );
}

export { PageAccountLayout, PageAccountMain, PageAccountLogin, PageAccountCreate, PageAccountEditProfile, PageAccountRecoverMail, PageAccountDeleteConfirm, PageAccountNeedLogin };
