import { CONST, pSheetEnum } from "./CardClientSheetData";
import GameEnv from "./GameEnv";

// Util.js 보다는 좀더 게임에 더 가까운 함수들.
// Enum 등 여러 모듈 import

let GameUtil = {};

GameUtil.ConvertImagePath = function (szRoot, szPrefix, nNum, szSuffix, szExt) {
  // caredlist/item/small/02/I02802.webp
  return `${szRoot}${parseInt(nNum / 1000).toString().padStart(2, '0')}/${szPrefix}${nNum.toString().padStart(5, '0')}${szSuffix}.${szExt}`
};

GameUtil.GetImageDefaultPathSmall = function () {
  return this.GetPublicPath("img/card_back_old_small.webp");
}

GameUtil.GetImageDefaultPathLarge = function () {
  return this.GetPublicPath("img/card_back_old_large.webp");
}

GameUtil.GetImagePathSmall = function (nCategory, nKIND) {
  if (!pSheetEnum.Category.mapKIND.has(nCategory)) {
    return "";
  }

  let pCategory = pSheetEnum.Category.mapKIND.get(nCategory);
  if (!pCategory) {
    return "";
  }

  return this.ConvertImagePath(pCategory.ImageSmall, pCategory.ImagePrefix, nKIND, "S", "webp");
};

GameUtil.GetImagePathLarge = function (nCategory, nKIND) {
  if (!pSheetEnum.Category.mapKIND.has(nCategory)) {
    return "";
  }

  let pCategory = pSheetEnum.Category.mapKIND.get(nCategory);
  if (!pCategory) {
    return "";
  }

  return this.ConvertImagePath(pCategory.ImageLarge, pCategory.ImagePrefix, nKIND, "L", "webp");
};

GameUtil.GetLibraryImagePath = function (szDocID, szFileName) {
  return `${this.GetDBLibraryCollection()}/${szDocID}/${szFileName}`;
}

GameUtil.GetEnumUIName = function (szEnumName, nKIND) {
  let pEnumInfo = pSheetEnum[`${szEnumName}`].mapKIND.get(nKIND);
  if (!pEnumInfo) {
    return `NOT_EXIST_${szEnumName}_${nKIND}`;
  }

  return pEnumInfo.UIName;
}

GameUtil.InvalidCharInChars = ` !@#$%^&*;:<>'"`;
GameUtil.IsValidCharacters = function (szText) {
  for (let i = 0; i < this.InvalidCharInChars.length; i++) {
    if (szText.includes(this.InvalidCharInChars[i])) {
      return false;
    }
  }
  return true;
}

GameUtil.InvalidCharInContent = `<>`;
GameUtil.IsValidContent = function (szText) {
  for (let i = 0; i < this.InvalidCharInContent.length; i++) {
    if (szText.includes(this.InvalidCharInContent[i])) {
      return false;
    }
  }
  return true;
}

/**
 * Get "./public/" + path string
 * @param {String} szPath file path
 * @returns "./public/" + path string
 */
GameUtil.GetPublicPath = function (szPath) {
  return `${GameEnv.GetPublicURL()}/${szPath}`;
};

GameUtil.CalcUserDeck = function (arrDeck) {
  // 
  let nTotalCount = 0;
  // key : soul / value : count
  let mapSoulType = new Map();
  // key : LV / value : count
  let mapLV = new Map();
  // key : Category / value : array(sorted)
  let mapCategory = new Map();

  arrDeck.forEach((pData) => {
    if (!pData) {
      return;
    }
    // 
    nTotalCount += pData.Count;
   
    // 
    if (mapSoulType.has(pData.SoulType)) {
      let pSoul = mapSoulType.get(pData.SoulType);
      pSoul.Count = pSoul.Count + pData.Count;
    }
    else {
      let pEnum = pSheetEnum[`SoulType`].mapKIND.get(pData.SoulType);
      mapSoulType.set(pData.SoulType, { KIND : pData.SoulType, Count: pData.Count, UIPriority: pEnum.UIPriority, UIName: pEnum.UIName });
    }

    //
    let nCategory = GameUtil.NumIDHelper.GetCategory(pData.NumID);       
    if (nCategory !== 1) { // 지형은 집계 제외
      if (mapLV.has(pData.LV)) {
        let nCategory = GameUtil.NumIDHelper.GetCategory(pData.NumID);      
  
        let pLV = mapLV.get(pData.LV);
        pLV.Count = pLV.Count + pData.Count;
      }
      else {
        let pEnum = pSheetEnum[`LV`].mapKIND.get(pData.LV);
        mapLV.set(pData.LV, { KIND : pData.LV, Count: pData.Count, UIPriority: pEnum.UIPriority, UIName: pEnum.UIName });
      }
    }

    // 
    if (mapCategory.has(pData.Category)) {
      let pCategory = mapCategory.get(pData.Category);
      pCategory.TotalCount += pData.Count;
      pCategory.Datas.push(pData);
    }
    else {
      mapCategory.set(pData.Category, { TotalCount : pData.Count, Datas : [pData]});
    }
  });

  mapCategory.forEach((pCategory, _k)=> {
    pCategory.Datas.sort(this.SortUIUserDeckCard);
  });

  return [nTotalCount, mapSoulType, mapLV, mapCategory];
};


// DB Collection Name
// Env + CONST
GameUtil.GetAppMode = function () {
  return GameEnv.GetAppMode();
};

GameUtil.GetDBAppSettingCollection = function () {
  switch (this.GetAppMode()) {
    case CONST._APP_MODE.LIVE: {
      return GameEnv.GetDBAppSettingCollectionLIVE();
    }

    default: {
      return GameEnv.GetDBAppSettingCollectionDEV();
    }
  }
};

GameUtil.GetDBCardListCollection = function () {
  switch (this.GetAppMode()) {
    case CONST._APP_MODE.LIVE: {
      return GameEnv.GetDBCardListCollectionLIVE();
    }

    default: {
      return GameEnv.GetDBCardListCollectionDEV();
    }
  }
};

GameUtil.GetDBUserDeckCollection = function () {
  switch (this.GetAppMode()) {
    case CONST._APP_MODE.LIVE: {
      return GameEnv.GetDBUserDeckCollectionLIVE();
    }

    default: {
      return GameEnv.GetDBUserDeckCollectionDEV();
    }
  }
};

GameUtil.GetDBReportCollection = function (UID) {
  switch (this.GetAppMode()) {
    case CONST._APP_MODE.LIVE: {
      return `${GameEnv.GetDBReportCollectionLIVE()}/${UID}/${GameEnv.GetDBSubReportCollectionLIVE()}`;
    }

    default: {
      return `${GameEnv.GetDBReportCollectionDEV()}/${UID}/${GameEnv.GetDBSubReportCollectionDEV()}`;
    }
  }
};

GameUtil.GetDBGMReportCollection = function () {
  switch (this.GetAppMode()) {
    case CONST._APP_MODE.LIVE: {
      return GameEnv.GetDBGMReportCollectionLIVE();
    }

    default: {
      return GameEnv.GetDBGMReportCollectionDEV();
    }
  }
};

GameUtil.GetDBGMNoticeCollection = function () {
  switch (this.GetAppMode()) {
    case CONST._APP_MODE.LIVE: {
      return GameEnv.GetDBGMNoticeCollectionLIVE();
    }

    default: {
      return GameEnv.GetDBGMNoticeCollectionDEV();
    }
  }
};

GameUtil.GetDBLibraryCollection = function () {
  switch (this.GetAppMode()) {
    case CONST._APP_MODE.LIVE: {
      return GameEnv.GetDBLibraryCollectionLIVE();
    }

    default: {
      return GameEnv.GetDBLibraryCollectionDEV();
    }
  }
};

GameUtil.SortUIUserDeckCard = function (a, b) {
  let nNumID_A = a.NumID;
  let nNumID_B = b.NumID;

  let nCategory_A = GameUtil.NumIDHelper.GetCategory(nNumID_A);
  let nCategory_B = GameUtil.NumIDHelper.GetCategory(nNumID_B);
  if (nCategory_A === nCategory_B) {
    let nLV_A = a.LV;
    let nLV_B = b.LV;
    if (nLV_A === nLV_B) {
      if (a.Name === b.Name) {
        // 4순위 : NumID 오름차순 (사실상 KIND) 
        return nNumID_A - nNumID_B;
      }

      // 3순위 : 이름 오름차순
      return a.Name.localeCompare(b.Name);
    }

    // 2순위 : LV 오름차순
    return nLV_A - nLV_B;
  }

  // 1순위 : 카테고리 오름차순
  return nCategory_A - nCategory_B;
}

// Auth Error
GameUtil.GetAuthErrorFeedback = function (szTitle, errorCode) {

  if (errorCode.search("invalid-email") >= 0) {
    return `${szTitle} : 입력하신 이메일 값이 이메일 형식(example@address.com)이 맞는지 확인해주세요.`;
  }
  else if (errorCode.search("user-not-found") >= 0) {
    return `${szTitle} : 입력하신 이메일에 맞는 계정을 찾을 수 없습니다.`;
  }
  else if (errorCode.search("auth/wrong-password") >= 0) {
    return `${szTitle} : 입력하신 이메일의 비밀번호에 맞는 계정을 찾을 수 없습니다.`;
  }
  else if (errorCode.search("auth/email-already-in-use") >= 0) {
    return `${szTitle} : 입력하신 이메일로 가입한 계정이 이미 존재합니다.`;
  }
  else {
    return `${szTitle} : 인증 에러 : 관리자에게 문의해 주세요. ${errorCode}`;
  }
};

// NumIDHelper
GameUtil.NumIDHelper = {};
GameUtil.NumIDHelper.GetKIND = function (nNumID) {
  return nNumID % 10000;
};
GameUtil.NumIDHelper.GetCategory = function (nNumID) {
  return (nNumID % 100000 - nNumID % 10000) / 10000;
};
Object.freeze(GameUtil.NumIDHelper);

Object.freeze(GameUtil);
export default GameUtil;


