import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// react-bootstrap
import Container from 'react-bootstrap/Container';

// personal
import { useState } from 'react';
import Maind from './mod/Maind';
import { CONST } from './mod/CardClientSheetData';

// mycom (my component)
import { Route, Routes } from 'react-router-dom';
import MainLayout from './MainLayout';
import PageHome from './page/PageHome';
import { PageAccountCreate, PageAccountDeleteConfirm, PageAccountEditProfile, PageAccountLayout, PageAccountLogin, PageAccountMain, PageAccountRecoverMail } from './page/PageAccount';
import PageCardListLayout from './page/PageCardListLayout';
import PageUserDeckLayout from './page/PageUserDeckLayout';
import { PageUserDeckBoard } from './page/PageUserDeckBoard';
import PageUserDeckWrite from './page/PageUserDeckWrite';
import { PageUserDeckPost } from './page/PageUserDeckPost';
import { Row, Spinner } from 'react-bootstrap';
import PageCardErrorCheck from './page/PageCardErrorCheck';
import GameUtil from './mod/GameUtil';
import { PageCardData } from './page/PageCardData';
import PageLibraryLayout from './page/PageLibraryLayout';
import PageLibraryBoard from './page/PageLibraryBoard';
import PageLibraryPost from './page/PageLibraryPost';
import PageLibraryWrite from './page/PageLibraryWrite';
import PageTestLayout from './page/PageTestLayout';
import PageTestHome from './page/PageTestHome';


// 
const pMaind = new Maind();

function App() {

  // non-state variables

  // state
  const [bIsComplete, funcSetComplete] = useState(false);

  return (
    <AppRoot bIsComplete={bIsComplete} funcSetComplete={funcSetComplete}></AppRoot>
  );
}

function AppRoot({
  bIsComplete,
  funcSetComplete
}) {

  if (!bIsComplete) {
    pMaind.Init(() => {
      funcSetComplete(true);
    });

    return (
      <PageLoading></PageLoading>
    );
  }

  return (
    <MainUI>
    </MainUI>
  );
}

function PageLoading() {
  return (
    <div style={{ width: "100vw", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
}

function MainUI() {

  function SetRoute_Basic() {
    return (
      <>
        <Route index element={<PageHome pMaind={pMaind}></PageHome>}></Route>
        <Route path="/cardlist" element={<PageCardListLayout pMaind={pMaind}></PageCardListLayout>}></Route>
        <Route path="/card/:id" element={<PageCardData pMaind={pMaind}></PageCardData>}></Route>

        <Route path="/userdeck" element={<PageUserDeckLayout></PageUserDeckLayout>}>
          <Route index element={<PageUserDeckBoard pMaind={pMaind}></PageUserDeckBoard>}></Route>
          <Route path="board" element={<PageUserDeckBoard pMaind={pMaind}></PageUserDeckBoard>}></Route>
          <Route path="post/" element={<PageUserDeckPost pMaind={pMaind}></PageUserDeckPost>}></Route>
          <Route path="post/:id" element={<PageUserDeckPost pMaind={pMaind}></PageUserDeckPost>}></Route>
          <Route path="write" element={<PageUserDeckWrite pMaind={pMaind}></PageUserDeckWrite>}></Route>
        </Route>

        <Route path="/library" element={<PageLibraryLayout></PageLibraryLayout>}>
          <Route index element={<PageLibraryBoard pMaind={pMaind}></PageLibraryBoard>}></Route>
          <Route path="board" element={<PageLibraryBoard pMaind={pMaind}></PageLibraryBoard>}></Route>
          <Route path="post/" element={<PageLibraryPost pMaind={pMaind}></PageLibraryPost>}></Route>
          <Route path="post/:id" element={<PageLibraryPost pMaind={pMaind}></PageLibraryPost>}></Route>
          <Route path="write" element={<PageLibraryWrite pMaind={pMaind}></PageLibraryWrite>}></Route>
        </Route>

        <Route path="/account" element={<PageAccountLayout></PageAccountLayout>}>
          <Route index element={<PageAccountMain pMaind={pMaind}></PageAccountMain>}></Route>
          <Route path="main" element={<PageAccountMain pMaind={pMaind}></PageAccountMain>}></Route>
          <Route path="login" element={<PageAccountLogin pMaind={pMaind}></PageAccountLogin>}></Route>
          <Route path="create" element={<PageAccountCreate pMaind={pMaind}></PageAccountCreate>}></Route>
          <Route path="editprofile" element={<PageAccountEditProfile pMaind={pMaind}></PageAccountEditProfile>}></Route>
          <Route path="recovermail" element={<PageAccountRecoverMail pMaind={pMaind}></PageAccountRecoverMail>}></Route>
          <Route path="deleteconfirm" element={<PageAccountDeleteConfirm pMaind={pMaind}></PageAccountDeleteConfirm>}></Route>
        </Route>
      </>
    );
  }

  function SetRoute_DEV() {
    switch (GameUtil.GetAppMode()) {
      // DEV 모드에서만 사용
      case CONST._APP_MODE.DEV_LIVE:
      case CONST._APP_MODE.DEV_EMU: {
        return (
          <>
            <Route path="/test" element={<PageTestLayout></PageTestLayout>}>
              <Route index element={<PageTestHome pMaind={pMaind}></PageTestHome>}></Route>
              <Route path="home" element={<PageTestHome pMaind={pMaind}></PageTestHome>}></Route>
            </Route>

            <Route path="/dev/carderrorcheck" element={<PageCardErrorCheck pMaind={pMaind}></PageCardErrorCheck>}></Route>
          </>
        );
      }

      default: {
        return (
          <></>
        );
      }
    }
  }

  return (
    <Routes>
      <Route element={<MainLayout pMaind={pMaind}></MainLayout>}>
        {SetRoute_Basic()}
        {SetRoute_DEV()}
      </Route>
    </Routes>
  );
}


export default App;

