const GameEnv = {};

GameEnv.GetAppMode = function () {
  return parseInt(process.env.REACT_APP_MODE);
};

GameEnv.GetDBAppSettingCollectionDEV = function () {
  return String(process.env.REACT_APP_FIRESTORE_APPSETTING_COLLECT_DEV);
};

GameEnv.GetDBAppSettingCollectionLIVE = function () {
  return String(process.env.REACT_APP_FIRESTORE_APPSETTING_COLLECT_LIVE);
};

GameEnv.GetDBCardListCollectionDEV = function () {
  return String(process.env.REACT_APP_FIRESTORE_CARDLIST_COLLECT_DEV);
};

GameEnv.GetDBCardListCollectionLIVE = function () {
  return String(process.env.REACT_APP_FIRESTORE_CARDLIST_COLLECT_LIVE);
};

GameEnv.GetDBUserDeckCollectionDEV = function () {
  return String(process.env.REACT_APP_FIRESTORE_USERDECK_COLLECT_DEV);
};

GameEnv.GetDBUserDeckCollectionLIVE = function () {
  return String(process.env.REACT_APP_FIRESTORE_USERDECK_COLLECT_LIVE);
};

GameEnv.GetDBReportCollectionDEV = function () {
  return String(process.env.REACT_APP_FIRESTORE_REPORT_COLLECT_DEV);
};

GameEnv.GetDBReportCollectionLIVE = function () {
  return String(process.env.REACT_APP_FIRESTORE_REPORT_COLLECT_LIVE);
};

GameEnv.GetDBSubReportCollectionDEV = function () {
  return String(process.env.REACT_APP_FIRESTORE_SUBREPORT_COLLECT_DEV);
};

GameEnv.GetDBSubReportCollectionLIVE = function () {
  return String(process.env.REACT_APP_FIRESTORE_SUBREPORT_COLLECT_LIVE);
};

GameEnv.GetDBGMReportCollectionDEV = function () {
  return String(process.env.REACT_APP_FIRESTORE_GMREPORT_COLLECT_DEV);
};

GameEnv.GetDBGMReportCollectionLIVE = function () {
  return String(process.env.REACT_APP_FIRESTORE_GMREPORT_COLLECT_LIVE);
};

GameEnv.GetDBGMNoticeCollectionDEV = function () {
  return String(process.env.REACT_APP_FIRESTORE_GMNOTICE_COLLECT_DEV);
};

GameEnv.GetDBGMNoticeCollectionLIVE = function () {
  return String(process.env.REACT_APP_FIRESTORE_GMNOTICE_COLLECT_LIVE);
};

GameEnv.GetDBLibraryCollectionDEV = function () {
  return String(process.env.REACT_APP_FIRESTORE_LIBRARY_COLLECT_DEV);
};

GameEnv.GetDBLibraryCollectionLIVE = function () {
  return String(process.env.REACT_APP_FIRESTORE_LIBRARY_COLLECT_LIVE);
};

GameEnv.GetPublicURL = function () {
  return String(process.env.PUBLIC_URL);
};

GameEnv.GetFirebaseAPIKey = function() { return String(process.env.REACT_APP_FIREBASE_API_KEY); };
GameEnv.GetFirebaseAuthDomain = function() { return String(process.env.REACT_APP_FIREBASE_AUTH_DOMAIN); };
GameEnv.GetFirebaseDatabaseURL = function() { return String(process.env.REACT_APP_FIREBASE_DATABASE_URL); };
GameEnv.GetFirebaseProjectID = function() { return String(process.env.REACT_APP_FIREBASE_PROJECT_ID); };
GameEnv.GetFirebaseStorageBucketName = function () { return String(process.env.REACT_APP_FIREBASE_STORAGE_BUCKET);};
GameEnv.GetFirebaseMessagingSenderID = function() { return String(process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID); };
GameEnv.GetFirebaseAppID = function() { return String(process.env.REACT_APP_FIREBASE_APP_ID); };


Object.freeze(GameEnv);
export default GameEnv;
