import { FieldPath, Timestamp } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CONST } from "../mod/CardClientSheetData";
import GameUtil from "../mod/GameUtil";
import { CCardDetailModalButtonParam, ComCardDetailModal } from "../mycom/ComCardDetailModal";
import { ComUserDeckPostView } from "../mycom/ComUserDeckPostView";

function PageUserDeckPost({
  pMaind
}) {
  const pParams = useParams();
  const [pUIData, funcSetUIData] = useState({
    szID: pParams.id,
    pPostData: null,
  });

  const pNavigate = useNavigate();

  // key : szNumID / value : pCardData
  let pMapCardDataCache = useRef(new Map());

  // 카드 상세정보 모달창 용 
  let [pDetailData, funcSetDetailData] = useState({
    show: false,
    pData: null,
    arrButtonParams: [
      new CCardDetailModalButtonParam("관련 유저덱 검색", "outline-primary", OnClickSearchUserDeck),
      new CCardDetailModalButtonParam("닫기", "danger", OnClickModalClose),
    ]
  });

  function OnClickSearchUserDeck(pCardData) {
    let pSearchParams = new URLSearchParams();
    pSearchParams.append("card", pCardData.NumID);
    pNavigate(`/userdeck/board?${pSearchParams.toString()}`);
  }

  function OnClickModalClose(pCardData) {
    // pCardData 실제로 쓰진 않는다.

    // 닫기
    pDetailData.show = false;
    funcSetDetailData({ ...pDetailData });
  }


  useEffect(() => {
    (async function () {
      if (!pUIData.szID) {
        pNavigate("/userdeck/board");
        return;
      }

      // 다른 유저의 유저덱일 경우 
      let szCollection = GameUtil.GetDBUserDeckCollection();
      const pSearchResult = await pMaind.pFbCtrl.GetDocSimple(szCollection, pUIData.szID);
      if (!pSearchResult) {
        alert("유효하지 않은 게시물 ID입니다. 유저덱 메인 페이지로 이동합니다.");
        pNavigate("/userdeck/board");
        return;
      }

      pUIData.pPostData = pSearchResult;
      funcSetUIData({ ...pUIData });
    })();
  }, []);


  async function OnClickDelete(pPostData) {
    let szCollection = GameUtil.GetDBUserDeckCollection();
    let szDocID = pPostData.id;
    let szTitle = pPostData.Title;

    let isConfirm = window.confirm(`[${szTitle}] 유저덱을 삭제하시겠습니까?`);
    if (!isConfirm) {
      return;
    }

    try {
      await pMaind.pFbCtrl.DeleteDocSimple(szCollection, szDocID);

      alert(`삭제 완료했습니다.`);

      // 삭제 완료. 게시판으로.
      pNavigate("/userdeck/board");
    }
    catch (error) {
      // TODO : 삭제에 실패
    }
  }

  function OnClickList() {
    // 게시판으로.
    pNavigate("/userdeck/board");
  }

  function OnClickCardImg(nNumID) {
    let szNumID = String(nNumID);
    if (!szNumID) {
      return;
    }

    (async function () {
      let szCollection = GameUtil.GetDBCardListCollection();
      let szDocID = szNumID;

      try {
        let pCardData;
        if (pMapCardDataCache.current.has(szDocID)) {
          pCardData = pMapCardDataCache.current.get(szDocID);
        }
        else {
          pCardData = await pMaind.pFbCtrl.GetDocSimple(szCollection, szDocID);
        }

        if (!pCardData) {
          return;
        }

        if (!pMapCardDataCache.current.has(szDocID)) {
          pMapCardDataCache.current.set(szDocID, pCardData);
        }

        pDetailData.show = true;
        pDetailData.pData = pCardData;
        funcSetDetailData({ ...pDetailData });
      }
      catch (error) {
        // TODO : 실패
        console.error(error);
      }
    })();
  }

  if (!pUIData.szID) {
    return (
      <p>유효하지 않은 게시물 ID입니다. 유저덱 메인 페이지로 이동합니다.</p>
    );
  }

  if (!pUIData.pPostData) {
    return (
      <p>로드 중입니다.</p>
    );
  }

  return (
    <>
      <ComUserDeckPostView
        pMaind={pMaind}
        pPostData={pUIData.pPostData}
        bIsLimitView={false}
        cbOnDelete={OnClickDelete}
        cbOnList={OnClickList}
        cbOnClickCardImg={OnClickCardImg}
        cbOnPostSearchByUID={() => { }}
      >
      </ComUserDeckPostView>

      {/* modal */}
      <ComCardDetailModal
        pMaind={pMaind}
        pDetailData={pDetailData}
        funcSetDetailData={funcSetDetailData}
        arrButtonParams={pDetailData.arrButtonParams}
        funcOnHide={OnClickModalClose}
      >
      </ComCardDetailModal>

    </>
  );
}

export { PageUserDeckPost };