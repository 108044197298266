import { FieldPath, Timestamp } from "firebase/firestore";
import { Children, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { CONST } from "../mod/CardClientSheetData";
import GameUtil from "../mod/GameUtil";
import ComCardDataView from "../mycom/ComCardDataView";
import { CCardDetailModalButtonParam, ComCardDetailModal } from "../mycom/ComCardDetailModal";
import { ComUserDeckPostView } from "../mycom/ComUserDeckPostView";

function PageCardData({
  pMaind
}) {
  const pParams = useParams();
  const [pUIData, funcSetUIData] = useState({
    szID: pParams.id,
    pCardData: null,
  });

  let pCurUser = pMaind.pFbCtrl.GetAuthCurrentUser();

  const pNavigate = useNavigate();

  function OnClickSearchUserDeck() {
    if (!pUIData.pCardData) {
      return;
    }

    let pSearchParams = new URLSearchParams();
    pSearchParams.append("card", pUIData.pCardData.NumID);
    pNavigate(`/userdeck/board?${pSearchParams.toString()}`);
  }

  function OnClickGotoList() {
    pNavigate("/cardlist");
  }

  useEffect(() => {
    (async function () {
      if (!pUIData.szID) {
        pNavigate("/cardlist");
        return;
      }

      let szCollection = GameUtil.GetDBCardListCollection();
      const pSearchResult = await pMaind.pFbCtrl.GetDocSimple(szCollection, pUIData.szID);
      if (!pSearchResult) {
        alert("유효하지 않은 카드 ID입니다. 카드목록 메인 페이지로 이동합니다.");
        pNavigate("/cardlist");
        return;
      }

      pUIData.pCardData = pSearchResult;
      funcSetUIData({ ...pUIData });
    })();
  }, []);


  function OnClickList() {
    pNavigate("/cardlist");
  }

  if (!pUIData.szID) {
    return (
      <p>유효하지 않은 게시물 ID입니다. 유저덱 메인 페이지로 이동합니다.</p>
    );
  }

  if (!pUIData.pCardData) {
    return (
      <p>로드 중입니다.</p>
    );
  }

  function RefreshUI_Buttons() {
    let arrRetVal = [];

    arrRetVal.push(
      <Button variant="outline-primary" className='w-100 mb-1' onClick={OnClickSearchUserDeck}>관련 유저덱 검색</Button>
    );

    arrRetVal.push(
      <Button variant="outline-primary" className='w-100 mb-1' onClick={OnClickList}>카드 목록으로 이동</Button>
    );

    return (
      Children.toArray(arrRetVal)
    );
  }

  return (
    <>
      <ComCardDataView
        pMaind={pMaind}
        pCurUser={pCurUser}
        pCardData={pUIData.pCardData}
        bIsLimitView={false}
      >
      </ComCardDataView>

      {RefreshUI_Buttons()}
    </>
  );
}

export { PageCardData };